import { jwtDecode } from 'jwt-decode';
import { dayjs } from '../../common/utils/dates';

export interface TokenPayload {
  sub: string;
  iat: number;
  exp: number;
}

export const checkTokenValidity = (providedToken?: string) => {
  if (providedToken && typeof providedToken === 'string') {
    const { exp = 0 } = jwtDecode<TokenPayload>(providedToken);
    // Check if the token is expired (will be considered expired if `exp` is within 3 minutes of the current time)
    return dayjs(Math.floor(Date.now() / 1000)).isBefore(
      dayjs(new Date(exp - 60 * 3))
    );
  }
  return false;
};
