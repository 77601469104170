import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PageTitle from '../../common/components/PageTitle';
import { useAuthContext } from '../../common/contexts/auth';
import { useEnterprises } from '../../common/contexts/enterprises';
import { useProducts } from '../../common/contexts/products';
import useV2EnterpriseMembers from '../../common/hooks/useV2EnterpriseMembers';
import useV2ListLicenses from '../../common/hooks/useV2ListLicenses';
import { Enterprises } from '../../common/utils/billing-client/client-v2';
import EditUser from '../containers/EditUser';
import { getHiddenProductSlugsFromSubs } from '../utils/get-hidden-product-slugs-from-subs';
import { ProductSlugs } from '../../common/constants';
import styles from './EditUser.css';

interface RouteParams {
  id: string;
}

export default function EditUserPage(): JSX.Element {
  const { t } = useTranslation();
  const { id: userId } = useParams<RouteParams>();
  const { user, billingUser } = useAuthContext();
  const { activeEnterprise } = useEnterprises();
  // There will always be a user and with a token.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const token = user!.token;
  // TODO: When multi-enterprise is supported, we should fetch the appropriate enterprise_id
  // There should always be an active enterprise because of <Protected isEnterpriseAdmin>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const enterpriseId = activeEnterprise!.id;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const activeUser = billingUser!;
  const {
    loading: enterpriseMembersLoading,
    error: enterpriseMembersError,
    pendingOrAcceptedMembers: enterpriseMembers
  } = useV2EnterpriseMembers({ token, enterpriseId });
  const {
    loading: productsLoading,
    error: productsError,
    products,
    getProductBySlug,
    getProductById
  } = useProducts();
  const {
    loading: licensesLoading,
    error: licensesError,
    licenses
  } = useV2ListLicenses(token, { enterpriseId });

  const hiddenProductSlugs = activeEnterprise
    ? getHiddenProductSlugsFromSubs(
        activeEnterprise.subscriptions,
        getProductBySlug
      )
    : [];

  const visibleLicenses =
    licenses?.reduce((acc, license) => {
      const product = getProductById(license.product_id);
      if (
        product &&
        !hiddenProductSlugs.includes(product.slug as ProductSlugs)
      ) {
        acc.push(license);
      }
      return acc;
    }, [] as Enterprises.License[]) || [];

  return (
    <div className={styles.wrap}>
      <PageTitle title={t('Edit User Access')} />
      <EditUser
        userId={userId}
        token={token}
        enterpriseId={enterpriseId}
        members={enterpriseMembers || []}
        products={products}
        licenses={visibleLicenses}
        activeUser={activeUser}
        loading={enterpriseMembersLoading || productsLoading || licensesLoading}
        error={enterpriseMembersError || productsError || licensesError}
      />
    </div>
  );
}
