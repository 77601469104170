import type { v2 } from '@deque/billing-service-client';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { HttpError } from '../../../common/api-client';
import StepperModal, {
  Step as StepType
} from '../../../common/components/StepperModal';
import ChooseLicenseCountStep from './steps/ChooseLicenseCountStep';
import CompanyInformationStep from './steps/CompanyInformationStep';
import ReviewStep from './steps/ReviewStep';
import { AnalyticsInstanceId } from '../../../common/analyticsInstances';
import { useAnalyticsInstance } from '../../../common/contexts/analytics';

export interface ChangePlanModalProps {
  productSlug: AnalyticsInstanceId;
  currentLicenseCount: number;
  currentUnallocatedLicenses: number;
  onAssignLicenses: (
    n: number | null,
    enterprise: v2.Enterprise
  ) => Promise<void>;
  onAssignEnterpriseName: (n: string) => Promise<v2.Enterprise | null>;
  term: v2.SubscriptionPricing['interval'];
  price: number;
  isBillingAdmin: boolean;
  productName: string;
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
  activeEnterprise: v2.Enterprise | null;
  companyText: string;
  setError: (error: string) => void;
}

const ChangePlanModal = ({
  productSlug,
  currentLicenseCount,
  currentUnallocatedLicenses,
  onAssignLicenses,
  onAssignEnterpriseName,
  term,
  price,
  isBillingAdmin,
  productName,
  dialogOpen,
  setDialogOpen,
  activeEnterprise,
  companyText,
  setError
}: ChangePlanModalProps): ReactElement => {
  const { t } = useTranslation();
  const analytics = useAnalyticsInstance(productSlug);
  const minLicenseCount = isBillingAdmin
    ? currentLicenseCount - currentUnallocatedLicenses
    : 1;

  const [totalLicensesValue, setTotalLicensesValue] = useState<number | null>(
    Math.max(currentLicenseCount, minLicenseCount)
  );
  const [stepOverride, setStepOverride] = useState<number | null>(null);
  const [enterpriseName, setEnterpriseName] = useState(companyText);
  const [totalLicensesError, setTotalLicensesError] = useState('');
  const [enterpriseNameError, setEnterpriseNameError] = useState('');
  const licenseChangeCount = totalLicensesValue
    ? totalLicensesValue - currentLicenseCount
    : 0;

  const steps: StepType[] = [
    {
      heading: t('Choose Licenses'),
      content: (
        <ChooseLicenseCountStep
          currentLicenseCount={currentLicenseCount}
          currentUnallocatedLicenses={currentUnallocatedLicenses}
          setTotalLicenseValue={setTotalLicensesValue}
          totalLicensesValue={totalLicensesValue}
          totalLicensesError={totalLicensesError}
          licenseChangeCount={licenseChangeCount}
          term={term}
          price={price}
          productName={productName}
          isBillingAdmin={isBillingAdmin}
          minLicenseCount={minLicenseCount}
        />
      ),
      isValid: () => {
        if (totalLicensesValue === null) {
          setTotalLicensesError(t('Total licenses is required'));
          return false;
        }

        if (!Number.isInteger(totalLicensesValue)) {
          setTotalLicensesError(t('Total licenses must be a whole number'));
          return false;
        }

        if (totalLicensesValue <= 0) {
          setTotalLicensesError(t('Total licenses must be at least 1'));
          return false;
        }

        if (activeEnterprise && currentLicenseCount === totalLicensesValue) {
          setTotalLicensesError(
            t(
              'Your total number of licenses is already {{totalLicensesValue}}',
              {
                totalLicensesValue
              }
            )
          );
          return false;
        }

        if (totalLicensesValue < minLicenseCount) {
          setTotalLicensesError(
            t(
              'Total licenses must be greater than or equal to the number of allocated licenses ({{minLicenseCount}})',
              {
                minLicenseCount
              }
            )
          );
          return false;
        }

        setTotalLicensesError('');
        return true;
      }
    }
  ];

  const companyInformationStep = {
    heading: t('Company Information'),
    content: (
      <CompanyInformationStep
        enterpriseName={enterpriseName}
        enterpriseNameError={enterpriseNameError}
        setEnterpriseName={setEnterpriseName}
        setEnterpriseNameError={setEnterpriseNameError}
      />
    ),
    isValid: () => {
      if (!enterpriseName) {
        setEnterpriseNameError(t('Company name is required'));
        return false;
      }

      if (enterpriseName.length > 256) {
        setEnterpriseNameError(
          t('Company name must be less than 256 characters')
        );
        return false;
      }

      setEnterpriseNameError('');
      return true;
    }
  };

  if (!isBillingAdmin) {
    steps.push(companyInformationStep);
  }
  steps.push({
    heading: t('Review Changes'),
    content: (
      <ReviewStep
        currentLicenseCount={currentLicenseCount}
        licenseChangeCount={licenseChangeCount}
        term={term}
        price={price}
        totalLicensesValue={totalLicensesValue ?? 0}
        enterpriseName={!activeEnterprise ? enterpriseName : undefined}
      />
    )
  });

  const onSubmit = async () => {
    analytics?.submitLicenseChange({
      licenseCount: totalLicensesValue ?? 0,
      prevLicenseCount: currentLicenseCount
    });

    let enterprise: v2.Enterprise | null = activeEnterprise || null;
    if (!isBillingAdmin) {
      try {
        enterprise = await onAssignEnterpriseName(enterpriseName);
      } catch (err) {
        const error = err as HttpError;
        if (error.error_code && error.error_code === 'ENTERPRISE_NAME_EXISTS') {
          setEnterpriseNameError(
            t('Company name is already taken. Please try a different name.')
          );
          setStepOverride(steps.indexOf(companyInformationStep));
          setStepOverride(null);
          return;
        }
        setError(
          t(
            'There was a problem creating your company. Please try again later.'
          )
        );
        setDialogOpen(false);
        return;
      }
    }
    if (enterprise) {
      await onAssignLicenses(totalLicensesValue, enterprise);
    }
    setDialogOpen(false);
  };

  return (
    <StepperModal
      heading={isBillingAdmin ? t('CHANGE PLAN') : t('ADD USERS')}
      steps={steps}
      onClose={() => setDialogOpen(false)}
      onSubmit={onSubmit}
      dialogOpen={dialogOpen}
      stepOverride={stepOverride}
    />
  );
};

export default ChangePlanModal;
