import { Button, Icon } from '@deque/cauldron-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './StepReview.css';

export type StepReviewProps = {
  title: string;
  fields: {
    name: string;
    value: string;
  }[];
  onEdit: () => void;
};

const StepReview: FC<StepReviewProps> = ({ title, fields, onEdit }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.reviewSection}>
      <div className={styles.sectionHeader}>
        <h2 className={styles.title}>{title}</h2>
        <Button onClick={onEdit} variant="secondary" thin>
          <Icon type="pencil" className={styles.buttonIcon} />
          {t('Edit')}
        </Button>
      </div>
      <div className={styles.content}>
        {fields.map(({ name, value }, index) => (
          <div className={styles.fieldSection} key={index}>
            <p className={styles.fieldName}>{name}</p>
            <p className={styles.fieldValue}>{value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepReview;
