import type {
  IssueDescriptionData,
  Sections as RuleHelpSections
} from '@deque/igt-rule-help';
import type { ApiKey, DeleteApiKeyByIdResponse } from '@deque/api-key-client';
import type {
  BranchOverview,
  CommitOverview,
  Session,
  SessionComparisonMetadata,
  SessionOverview
} from '@deque/jazzband-client';
import {
  Headers as PaginationHeaders,
  setPagination,
  Paginated
} from '@deque/pagination-utils';
import type { SafeVNode } from '@deque/groot';
import type * as jazzbandUtils from '@deque/jazzband-utils';
import removeTrailingSlash from 'remove-trailing-slash';
import type axe from 'axe-core';
import type { FeatureFlag } from './contexts/featureFlags';
import parsePasswordRequirements, {
  PasswordRequirements
} from './utils/parsePasswordRequirements';
import { ToastType } from './contexts/globalToast';
import qs from 'qs';
import type { GuidedTool } from './utils/get-guides';
import { type AxeResults } from 'axe-core';
import { encodeURI } from '@deque/jazzband-utils';
import type {
  Configuration,
  ConstrainingPath,
  Scan,
  Project,
  ScanRuntimeDetails,
  GlobalConfiguration as TromboneGlobalConfiguration
} from '@deque/trombone-api-client';
import {
  AuditorTest,
  AuditorTestParams
} from '../axe-auditor/types/AuditorTestList';
import type { TestDetail } from '../axe-auditor/types/TestDetail';
import type {
  AuditorTestRunUnitParams,
  RunUnit
} from '../axe-auditor/types/TestRunUnit';
import type { TestRecordIssue } from '@deque/axe-devtools-issues-utils';
import { SupportedIntegrationProductSlugs } from './constants';
import type { UniversalIssue } from '@deque/axe-devtools-issues-utils';
import { Impacts } from '../app/types';

export type CreateApiKeyBody = Omit<
  ApiKey,
  | 'id'
  | 'api_key'
  | 'user_id'
  | 'enterprise_id'
  | 'created_at'
  | 'updated_at'
  | 'enterprise_name'
  | 'user_email'
  | 'platform'
> & { platform?: string };

export type UpdateApiKeyBody = Partial<
  Omit<ApiKey, 'id' | 'user_id' | 'enterprise_id' | 'created_at' | 'updated_at'>
>;

export interface PaginationOptions {
  page?: number;
  per_page?: number;
}

interface RequestParams extends PaginationOptions {
  method?: string;
  endpoint: string;
  token?: string;
  raw?: boolean;
  headers?: Record<string, string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
}

export interface SSOConfig {
  url: string;
  realm: string;
  publicClientId: string;
}

interface RawRealmInfo {
  passwordPolicy: string[];
  identityProviders: string[];
}

export interface RealmInfo {
  passwordPolicy: PasswordRequirements;
  identityProviders: string[];
}

export interface User {
  id: string;
  username: string;
  firstName?: string;
  lastName?: string;
  email: string;
  data_gather: boolean;
  hasPublicClientRole: boolean;
  hasAccountEntry: boolean;
  token: string;
  company?: string;
  roles: string[];
  groups: string[];
}
export interface SignedUpUser {
  id: string;
  username: string;
  firstName?: string;
  lastName?: string;
  email: string;
  company?: string;
}

export interface Account {
  user_id: string;
  email: string | null;
  agreed_at: string | null;
}

interface SignUpUser {
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  isMobileReadOnly?: boolean;
  isMobileFreeTrial?: boolean;
  redirectURI?: string;
}

interface CreateAccountParams {
  firstName: string;
  lastName: string;
  company: string;
}

export interface Team {
  id?: string;
  name: string;
  user_ids: string[];

  user_ids_to_add: string[];
  user_ids_to_remove: string[];
}

export interface RuleHelp extends RuleHelpSections {
  issueDescriptionData: IssueDescriptionData;
}

export interface BoundingBox {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface Issue {
  // This allows us to make assumptions about the shape of the issue for issue mapping.
  [key: string]: unknown;
  id: string;
  test_id: string;
  test_name: string;
  test_url: string | null;
  user_id: string;
  manifest_id: string | null;
  manifest_scope: string | null;
  manifest_key: string | null;
  manifest_index: number | null;
  summary: string;
  description: string | null;
  help_url: string;
  rule: string;
  source: string | null;
  created_at: string;
  updated_at: Date | null;
  impact: 'blocker' | 'critical' | 'minor' | 'moderate' | 'serious';
  help: string;
  is_manual: boolean;
  selector: string[] | null;
  pseudo: string | null;
  manifest_guide: string | null;
  variant: 'best-practice' | 'pass' | 'violation' | null;
  needs_review: boolean | null;
  remediation: Record<string, unknown> | null;
  related_nodes: string[] | null;
  shared_with: string | null;
  tags: string[] | null;
  screenshot_id: string | null;
  bounding_box: BoundingBox | null;
  integration_url: string | null;
}

type TestMetadata = Record<string, unknown> & {
  scope?: SafeVNode;
};

export interface Test {
  id: string;
  creator_id: string;
  created_at: string;
  updated_at: string | null;
  auto_tests_at: string | null;
  manual_tests_completed_at: Date | null;
  name: string;
  url: string;
  skipped_guides: string[] | null;
  metadata: TestMetadata | null;
  shared_with: string | null;
}

type Step = {
  text?: string;
  status?: string;
  [key: string]: unknown;
};

export interface UserFlow {
  id: string;
  user_id: string;
  name: string;
  created_at: string;
  updated_at: string | null;
  user_email?: string | null;
  axe_core_version?: string;
  data_source_version?: string | null;
  shared_with: string | null;
}

export type UserFlowResults = AxeWatcherSessionComparisonResponse;

export interface Manifest {
  id: string;
  body: {
    metadata?: {
      username?: string;
      duration?: number;
      issues?: Record<string, unknown>[];
      progress?: Step[];
      [key: string]: unknown;
    };
    [key: string]: unknown;
  };
  search: unknown | null;
  created_at: Date | null;
  test_id: string;
  guide: GuidedTool | null;
  updated_at: Date | null;
  name: string | null;
}

export type Manifests = Record<GuidedTool, Manifest[]>;

export interface ScreenshotMetadata {
  width: number;
  height: number;
  format?: string;
  channels?: number;
  premultiplied?: boolean;
  size?: number;
}

export interface IntegrationTemplate {
  id: string;
  enterprise_id: string;
  product_slug: string;
  project_id: string | null;
  issue_type_id: string | null;
  name: string;
  field_mappings: Record<string, string> | null;
  impact_mappings: Partial<Record<Impacts, string>> | null;
  custom_labels: string[];
  created_by: string | null;
  created_by_first_name: string;
  created_by_last_name: string;
  created_at: string;
  updated_by: string | null;
  updated_by_first_name: string | null;
  updated_by_last_name: string | null;
  updated_at: string | null;
}

export interface ServerInfo {
  billingServiceEnabled: boolean;
  mailerEnabled: boolean;
  isJazzbandEnabled: boolean;
  isOnPrem: boolean;
  isSelfProvisioningEnabled: boolean;
}
/**
 * Feature flag coming from the database (not the user's cookies).
 *
 * NOTE: This type should _only_ be used by /internal/features/admin.
 */

export interface AdminFeatureFlag {
  id: string;
  default_state: boolean;
  description: string;
  product_name: string;
  created_at: Date;
  created_by: string;
  updated_at: Date | null;
  updated_by: string | null;
}

export interface Subscription {
  email: string;
  status: 'email-sent' | 'active';
  lastUpdated: string;
}

export type WatcherNode = Omit<
  axe.NodeResult,
  'xpath' | 'ancestry' | 'any' | 'all' | 'none' | 'element'
>;

export type WatcherViolation = Omit<axe.Result, 'nodes'> & {
  nodes: WatcherNode[];
};

export interface WatcherResult {
  id: string;
  created_at: Date;
  page_state: string | null;
  url: string;
  session_id: string;
  browser_id: string;
  violations: WatcherViolation[];
}

interface Broadcast {
  message?: string;
  level?: ToastType;
  isInMaintenanceMode: boolean;
}

interface InvoiceRequestBody {
  product_name: string;
  quantity: number;
  special_request?: string;
  first_name: string;
  last_name: string;
  email: string;
  org_name: string;
  org_email: string;
  address_1: string;
  address_2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  po_number?: string;
}

interface InvoiceRequestResponse {
  sent: boolean;
}

type CreateProjectRequestBody = Omit<
  Project,
  | 'id'
  | 'configuration_id'
  | 'created_at'
  | 'updated_at'
  | 'user_id'
  | 'enterprise_id'
  | 'deleted_at'
  | 'is_deleted'
> & {
  configuration: Omit<
    Configuration,
    'id' | 'maximum_browsers' | 'created_at' | 'updated_at'
  > & {
    constraining_path: Omit<ConstrainingPath, 'id' | 'configuration_id'>[];
  };
};

type UpdateProjectConfigurationBody = Pick<Project, 'name'> & {
  configuration: Omit<
    Configuration,
    'id' | 'maximum_browsers' | 'created_at' | 'updated_at'
  > & {
    constraining_path: Omit<ConstrainingPath, 'id' | 'configuration_id'>[];
  };
};

type CreateProjectResponseBody = Project;

type ProjectConfiguration = Project & {
  configuration: Configuration & {
    constraining_path: Omit<ConstrainingPath, 'id' | 'configuration_id'>[];
  };
};
export type ProjectScan = Project & { scan: Scan | null };
export type ProjectScanDetails = Scan & { session: Session };
export type ProjectScanSessionComparisonMetadata = SessionComparisonMetadata & {
  scan_details_new: Scan;
  scan_details_old: Scan;
};

export interface DquTransactionNotificationParams {
  newSubscription: boolean;
  priceId?: string;
  quantity: number;
}

interface AxeWatcherSessionComparisonResponse {
  overview: jazzbandUtils.DedupedOverview;
  results: jazzbandUtils.DedupedResult[];
  pageStateResults: jazzbandUtils.DedupedPageStateResult[];
}

export type MinimalFeatureFlag = Pick<FeatureFlag, 'id' | 'state'>;

export type HttpError = Error & { status?: number; error_code?: string };

/**
 * Small utility to replace certain encoding from query parameters to make the strings more readable.
 *
 * @param query - a stringified query parameter
 * @returns a stringified query parameter with %5B and %5D replaced with [ and ]
 * @example
 * ```js
 * 'customer=foo%5Bbar%5D' becomes 'customer=foo[bar]'
 * ```
 * source: {@link https://github.com/stripe/stripe-node/blob/master/src/utils.ts#L40-L52 stripe-node's utils}

 */

export const encodeQueryParams = (data: Record<string, unknown>): string => {
  return qs.stringify(data).replace(/%5B/g, '[').replace(/%5D/g, ']');
};

/**
 * Note: This method should not be called directly outside of the api client libraries.
 * Instead, you should use the methods provided by the api client libraries.
 */
export const apiRequest = async <T>(
  options: RequestParams
): Promise<T | Paginated<T>> => {
  const {
    method = 'GET',
    raw,
    endpoint,
    token,
    body,
    headers = {},
    page,
    per_page
  } = options;

  // Ensure the endpoint starts with `/`.
  // This allows us to easily join URLs when `$API_SERVER_URL` is set.
  /* istanbul ignore if */
  if (process.env.NODE_ENV === 'development') {
    if (endpoint[0] !== '/') {
      throw new Error('Endpoint must begin with "/"');
    }
  }

  if (page !== undefined) {
    headers[PaginationHeaders.Page] = page.toString();
  }

  if (per_page !== undefined) {
    headers[PaginationHeaders.PerPage] = per_page.toString();
  }

  if (!headers['Content-Type']) {
    headers['Content-Type'] = 'application/json';
  }

  if (!headers.Accept) {
    headers.Accept = 'application/json';
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const host = removeTrailingSlash(process.env.API_SERVER_URL || '');
  const res = await fetch(`${host}${endpoint}`, {
    method,
    headers,
    body: raw ? body : JSON.stringify(body),
    // We need to include cookies in all requests (for feature flags).
    credentials: 'include'
  });

  if (raw) {
    if (res.ok) {
      return res as unknown as T;
    } else {
      throw new Error(`unexpected server error ${res.status}`);
    }
  }

  const type = res.headers.get('Content-Type');
  if (!type || !type.includes('json')) {
    // For whatever reason, the API didn't return JSON. We don't know what to do here, so log (and throw) an error.
    /* istanbul ignore next */
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.warn('API (%s) returned non-JSON response:', endpoint, res);
    }

    throw new Error('Unexpected result from API');
  }

  const responseBody = await res.json();

  if (res.ok) {
    const rawPageHeader = res.headers.get(PaginationHeaders.Page);
    const rawPerPageHeader = res.headers.get(PaginationHeaders.PerPage);
    const rawTotalHeader = res.headers.get(PaginationHeaders.Total);
    const pageHeader = rawPageHeader ? parseInt(rawPageHeader, 10) : undefined;
    const perPageHeader = rawPerPageHeader
      ? parseInt(rawPerPageHeader, 10)
      : undefined;
    const totalHeader = rawTotalHeader
      ? parseInt(rawTotalHeader, 10)
      : undefined;

    if (
      pageHeader !== undefined &&
      perPageHeader !== undefined &&
      totalHeader !== undefined
    ) {
      return setPagination(responseBody, {
        page: pageHeader,
        per_page: perPageHeader,
        total: totalHeader
      });
    }

    return responseBody;
  }

  let errorMessage: string;
  if (responseBody.validationErrors) {
    // The signup API (POST /api/users) returns `.validationErrors` in addition to `.error`.
    // Take the first validation error.
    // TODO: use something like npmjs.org/aggregate-error and display all validation errors.
    errorMessage = Object.values(responseBody.validationErrors)[0] as string;
  } else if (responseBody.error) {
    // The API error handler returns the shape `{ error: string }`.
    errorMessage = responseBody.error;
  } else {
    // Throw a generic error if there was an issue with the error handler.
    errorMessage = 'Unexpected server error';
  }

  const error = new Error(errorMessage);
  (error as HttpError).status = res.status;
  (error as HttpError).error_code = responseBody.error_code;

  throw error;
};

export const getUser = (token: string): Promise<User> => {
  return apiRequest({
    endpoint: '/api/logged-in',
    token
  });
};

export const getSSOConfig = (): Promise<SSOConfig> => {
  return apiRequest({
    endpoint: '/api/sso-config'
  });
};

export const getRealmInfo = (): Promise<RealmInfo> => {
  return apiRequest<RawRealmInfo>({
    endpoint: '/api/realm-info'
  }).then(realmInfo => {
    // Convert the password policy into a useable data structure.
    const strings = realmInfo.passwordPolicy;
    const requirements = parsePasswordRequirements(strings);
    return {
      ...realmInfo,
      passwordPolicy: requirements
    };
  });
};

export const signUp = (body: SignUpUser): Promise<SignedUpUser> => {
  return apiRequest({
    method: 'POST',
    endpoint: '/api/users',
    body
  });
};

export const createAccount = (
  userId: string,
  body: CreateAccountParams,
  token: string
): Promise<Account> => {
  return apiRequest({
    method: 'POST',
    endpoint: `/api/users/${userId}/accounts`,
    body,
    token
  });
};

export const getRuleHelp = (
  language: string,
  ruleId: string
): Promise<RuleHelp> => {
  return apiRequest({
    method: 'GET',
    endpoint: `/api/rule-help/${language}/${ruleId}`
  });
};

export const getServerInfo = (): Promise<ServerInfo> => {
  return apiRequest({
    endpoint: '/api/internal/server-info'
  });
};

/**
 * Get the active user's feature flag states.
 */

export const getFeatureFlags = async (
  token?: string
): Promise<FeatureFlag[]> => {
  const result = await apiRequest<{ features: FeatureFlag[] }>({
    endpoint: '/api/internal/features',
    token
  });
  return result.features;
};

/**
 * Update the active user's feature flag states.
 */

export const updateFeatureFlags = (
  token: string,
  features: MinimalFeatureFlag[]
): Promise<void> => {
  return apiRequest({
    endpoint: '/api/internal/features',
    method: 'POST',
    body: { features },
    token
  });
};

/**
 * Send an analytics event for server consumption. This can be used for
 * HubSpot events, or events that shouldn't be sent from a browser for
 * security purposes.
 */

type UpdateBehavior = 'NONE' | 'REPLACE' | 'INCREMENT';
export const analyticsEvent = (
  token: string,
  name: string,
  value?: string | boolean,
  updateBehavior?: UpdateBehavior
): Promise<unknown> =>
  apiRequest({
    method: 'POST',
    endpoint: '/api/analytics',
    token,
    body: {
      event_name: name,
      event_value: value,
      update_behavior: updateBehavior
    }
  }).catch(() => {
    // Noop, user shouldn't be blocked if this request fails
  });

/**
 * Get an issue by its `id`.
 *
 * @param id
 * @param token
 */

export const getIssueById = (
  id: string,
  token?: string
): Promise<TestRecordIssue> => {
  return apiRequest<TestRecordIssue>({
    method: 'GET',
    endpoint: `/api/issues/${id}`,
    token
  });
};

/**
 * Get a test by its `id`.
 */
export const getTest = (id: string, token?: string): Promise<Test> => {
  return apiRequest<Test>({
    method: 'GET',
    endpoint: `/api/tests/${id}`,
    token
  });
};

/**
 * Get a user flow by its `id`.
 */
export const getUserFlow = (id: string, token?: string): Promise<UserFlow> => {
  return apiRequest<UserFlow>({
    method: 'GET',
    endpoint: `/api/axe-devtools-pro/user-flows/${id}`,
    token
  });
};

/**
 * Update a user flow by its `id`.
 */
export const updateUserFlow = (
  id: string,
  token: string,
  updates: Partial<Pick<UserFlow, 'name' | 'shared_with'>>
): Promise<UserFlow> => {
  return apiRequest<UserFlow>({
    method: 'PUT',
    endpoint: `/api/axe-devtools-pro/user-flows/${id}`,
    token,
    body: updates
  });
};

/**
 * Delete a user flow by its `id`.
 */
export const deleteUserFlow = (id: string, token?: string): Promise<void> => {
  return apiRequest({
    method: 'DELETE',
    endpoint: `/api/axe-devtools-pro/user-flows/${id}`,
    token
  });
};

/**
 * Get user flow results by its `id`.
 */
export const getUserFlowResults = (
  id: string,
  token?: string
): Promise<UserFlowResults> => {
  return apiRequest<UserFlowResults>({
    method: 'GET',
    endpoint: `/api/axe-devtools-pro/user-flows/${id}/results`,
    token
  });
};

/**
 * Gets the issues for a test.
 */
export const getTestIssues = (
  id: string,
  token?: string
): Promise<TestRecordIssue[]> => {
  return apiRequest<TestRecordIssue[]>({
    method: 'GET',
    endpoint: `/api/tests/${id}/issues`,
    token
  });
};

/**
 * Gets the manifests for a test.
 */
export const getTestManifests = (
  id: string,
  token?: string
): Promise<Manifests> =>
  apiRequest<Manifests>({
    method: 'GET',
    endpoint: `/api/tests/${id}/manifests`,
    token
  });

/**
 * Get a screenshot's metadata by the screenshot `id`.
 *
 * @param id
 */

export const getScreenshotMetadataById = (
  id: string,
  token?: string
): Promise<ScreenshotMetadata> => {
  return apiRequest<ScreenshotMetadata>({
    method: 'GET',
    endpoint: `/api/screenshots/${id}/metadata`,
    token
  });
};

/**
 * Get a screenshot by the screenshot `id`.
 *
 * @param id
 */

export const getScreenshotById = async (
  id: string,
  token?: string
): Promise<Blob> => {
  const response = await apiRequest<Response>({
    method: 'GET',
    raw: true,
    headers: {
      Accept: 'image/png'
    },
    endpoint: `/api/screenshots/${id}`,
    token
  });
  return response.blob();
};

/**
 * Get the list of _admin_ feature flags.
 */

export const getAdminFeatureFlags = (
  token: string
): Promise<AdminFeatureFlag[]> => {
  return apiRequest({
    method: 'GET',
    endpoint: '/api/internal/admin/features',
    token
  });
};

/**
 * Update an _admin_ feature flag's `default_state` by its `id`.
 */

type UpdateAdminFeatureFlagParamType = {
  token: string;
  featureId: string;
  defaultState: boolean;
  description: string;
  productName: string;
};

export const updateAdminFeatureFlag = (
  params: UpdateAdminFeatureFlagParamType
): Promise<AdminFeatureFlag> => {
  const { token, featureId, defaultState, description, productName } = params;
  return apiRequest({
    method: 'PUT',
    endpoint: `/api/internal/admin/features/${featureId}`,
    token,
    body: {
      default_state: defaultState,
      description,
      product_name: productName
    }
  });
};

/**
 * Create an _admin_ feature flag.
 */

type CreateAdminFeatureFlagParams = UpdateAdminFeatureFlagParamType;

export const createAdminFeatureFlag = (
  params: CreateAdminFeatureFlagParams
): Promise<AdminFeatureFlag> => {
  const { token, featureId, defaultState, description, productName } = params;

  return apiRequest({
    method: 'POST',
    endpoint: '/api/internal/admin/features',
    token,
    body: {
      id: featureId,
      default_state: defaultState,
      description,
      product_name: productName
    }
  });
};

// api-client for API Keys
export const createApiKey = async (
  token: string,
  params: CreateApiKeyBody
): Promise<ApiKey> => {
  const data: ApiKey = await apiRequest({
    method: 'POST',
    endpoint: '/api/api-keys',
    token,
    body: params
  });
  return {
    ...data,
    created_at: new Date(data.created_at),
    updated_at: data.updated_at ? new Date(data.updated_at) : null
  };
};

export const getApiKeyById = async (
  token: string,
  id: string
): Promise<ApiKey> => {
  const data: ApiKey = await apiRequest({
    endpoint: `/api/api-keys/${id}`,
    token
  });
  return {
    ...data,
    created_at: new Date(data.created_at),
    updated_at: data.updated_at ? new Date(data.updated_at) : null
  };
};
export const getApiKeysByUserToken = async (
  token: string
): Promise<ApiKey[]> => {
  const data: ApiKey[] = await apiRequest({
    endpoint: `/api/api-keys`,
    token
  });
  return data.map(apiKey => ({
    ...apiKey,
    created_at: new Date(apiKey.created_at),
    updated_at: apiKey.updated_at ? new Date(apiKey.updated_at) : null
  }));
};

export const updateApiKeyById = async (
  token: string,
  id: string,
  updates: UpdateApiKeyBody
): Promise<ApiKey> => {
  const data: ApiKey = await apiRequest({
    method: 'PUT',
    endpoint: `/api/api-keys/${id}`,
    token,
    body: updates
  });
  return {
    ...data,
    created_at: new Date(data.created_at),
    updated_at: data.updated_at ? new Date(data.updated_at) : null
  };
};

export const deleteApiKeyById = (
  token: string,
  id: string
): Promise<DeleteApiKeyByIdResponse> =>
  apiRequest({
    method: 'DELETE',
    endpoint: `/api/api-keys/${id}`,
    token
  });

interface GetWatcherSessionOverviewsParams extends PaginationOptions {
  apiKeyId: string;
  token: string;
}

export const getWatcherSessionOverviews = async ({
  apiKeyId,
  token,
  ...options
}: GetWatcherSessionOverviewsParams): Promise<Paginated<SessionOverview>> => {
  const results = await apiRequest({
    method: 'GET',
    token,
    endpoint: `/api/axe-watcher/${apiKeyId}/sessions`,
    ...options
  });

  return results as Paginated<SessionOverview>;
};

export const getSessionListWithOverviews = async ({
  project_id,
  token,
  ...options
}: Omit<GetWatcherSessionOverviewsParams, 'apiKeyId'> & {
  project_id: string;
}): Promise<SessionOverview[]> => {
  const results = await apiRequest({
    method: 'GET',
    token,
    endpoint: `/api/axe-spider/${project_id}/sessions`,
    ...options
  });

  return results as SessionOverview[];
};

export interface TrialMetadata {
  duration: number;
}

export const getProductTrialMetadata = async (
  productSlug: string
): Promise<TrialMetadata> => {
  const trialMetadata: TrialMetadata = await apiRequest({
    endpoint: `/api/${productSlug}/trial-metadata`
  });

  return trialMetadata;
};

export const getBroadcast = (): Promise<Broadcast> =>
  apiRequest({
    endpoint: '/api/broadcast'
  });

export interface AxeWatcherProject {
  /* API Key name */
  name: string;
  /* API Key ID */
  project_id: string;
  /* API key - null when `is_owner` is false */
  api_key: string | null;
  /* API Key creation date */
  created_at: string; // Date
  /* Jazzband session creation date */
  last_session_created_at: string | null; // Date
  /* Does the session have git information? */
  has_git_information: boolean;
  /* Jazzband session git URL */
  git_url: string | null;
  /**
   * The state of a project:
   * - `active` means the project can receive data and they have a paid or trial subscription.
   * - `read-only` means the project cannot receive data and they have a free subscription.
   */
  project_state: 'active' | 'read-only';
  /* Is the user the owner of the API Key? */
  is_owner: boolean;
  /* Was the API key created when the user was part of an enterprise? */
  is_enterprise_project: boolean;
}

export const getAxeWatcherProjects = (
  token: string,
  options?: PaginationOptions
): Promise<AxeWatcherProject[]> =>
  apiRequest({
    endpoint: '/api/axe-watcher/projects',
    token,
    ...options
  });

export const getAxeWatcherProjectBranches = async (
  token: string,
  api_key_id: string,
  options?: PaginationOptions
): Promise<Paginated<BranchOverview>> => {
  const data = await apiRequest({
    endpoint: `/api/axe-watcher/${api_key_id}/branches`,
    token,
    ...options
  });
  return data as unknown as Paginated<BranchOverview>;
};

export interface AxeWatcherSessionComparison {
  session_created_at: string; // Date
  session_branch_name: string | null;
  /** null if non-Git. */
  session_commit_sha: string | null;
  session_commit_tag: string | null;
  session_commit_message: string | null;

  previous_session_created_at: string; // Date
  previous_session_branch_name: string | null;
  /** null if non-Git. */
  previous_session_commit_sha: string | null;
  previous_session_commit_tag: string | null;
  previous_session_commit_message: string | null;

  results: jazzbandUtils.DedupedResult[];
  page_states: jazzbandUtils.DedupedPageStateResult[];
  summary: jazzbandUtils.DedupedOverview;
}

interface GetAxeWatcherBranchCommitsParams extends PaginationOptions {
  token: string;
  api_key_id: string;
  branch_name: string;
}

export const getAxeWatcherBranchCommits = async ({
  token,
  api_key_id,
  branch_name,
  ...options
}: GetAxeWatcherBranchCommitsParams): Promise<Paginated<CommitOverview>> => {
  const encodedBranchName = encodeURI(branch_name);
  const data = await apiRequest({
    endpoint: `/api/axe-watcher/${api_key_id}/branches/${encodedBranchName}/commits`,
    method: 'GET',
    token,
    ...options
  });

  return data as Paginated<CommitOverview>;
};

export interface AxeWatcherResult {
  id: string;
  session_id: string;
  browser_id: string;
  page_state: string;
  results: AxeResults;
}

export const getAxeWatcherDefaultBranch = async (
  api_key_id: string,
  token: string
): Promise<BranchOverview | null> => {
  const data = await apiRequest({
    endpoint: `/api/axe-watcher/${api_key_id}/branches/default`,
    method: 'GET',
    token
  });

  return data as BranchOverview | null;
};

export const invoiceRequest = (
  data: InvoiceRequestBody
): Promise<InvoiceRequestResponse> =>
  apiRequest({
    endpoint: '/api/axe-devtools/invoice-request',
    method: 'POST',
    body: data
  });

export const updateProjectConfiguration = (
  token: string,
  project_id: string,
  data: UpdateProjectConfigurationBody
): Promise<ProjectConfiguration> =>
  apiRequest({
    endpoint: `/api/axe-spider/projects/${project_id}/configuration`,
    method: 'PUT',
    body: data,
    token
  });

export const createProjectRequest = (
  token: string,
  data: CreateProjectRequestBody
): Promise<CreateProjectResponseBody | null> =>
  apiRequest({
    endpoint: `/api/axe-spider/projects`,
    method: 'POST',
    body: data,
    token
  });

export const getSpiderProjects = (token: string): Promise<ProjectScan[]> =>
  apiRequest({
    endpoint: '/api/axe-spider/projects',
    method: 'GET',
    token
  });

export const getSpiderProjectSessions = async (
  project_id: string,
  token: string,
  options?: PaginationOptions
): Promise<Paginated<SessionOverview>> => {
  const data = await apiRequest({
    endpoint: `/api/axe-spider/projects/${project_id}/sessions`,
    method: 'GET',
    token,
    ...options
  });

  return data as Paginated<SessionOverview>;
};

export const getSpiderProjectScan = (
  project_id: string,
  scan_id: string,
  token: string
): Promise<Scan> =>
  apiRequest({
    endpoint: `/api/axe-spider/projects/${project_id}/scans/${scan_id}`,
    method: 'GET',
    token
  });

export const getSpiderProjectConfiguration = (
  token: string,
  project_id: string
): Promise<ProjectConfiguration> =>
  apiRequest({
    endpoint: `/api/axe-spider/projects/${project_id}/configuration`,
    method: 'GET',
    token
  });

export const startSpiderProject = async (
  data: TromboneGlobalConfiguration,
  token: string,
  project_id: string
): Promise<void> => {
  await apiRequest({
    body: data,
    endpoint: `/api/axe-spider/projects/${project_id}/scans`,
    method: 'POST',
    token
  });
};

export const abortSpiderProject = async (
  token: string,
  project_id: string,
  scan_id: string
): Promise<void> => {
  await apiRequest({
    endpoint: `/api/axe-spider/projects/${project_id}/scans/${scan_id}`,
    method: 'DELETE',
    token
  });
};

export const getSpiderProject = async (
  project_id: string,
  token: string
): Promise<ProjectScan> => {
  const data = await apiRequest({
    endpoint: `/api/axe-spider/projects/${project_id}`,
    method: 'GET',
    token
  });

  return data as ProjectScan;
};

export const deleteSpiderProject = async (
  token: string,
  project_id: string
): Promise<void> => {
  await apiRequest({
    endpoint: `/api/axe-spider/projects/${project_id}`,
    method: 'DELETE',
    token
  });
};

export const getAxeSpiderScanDetails = (
  token: string,
  project_id: string,
  scan_id: string
): Promise<ProjectScanDetails> =>
  apiRequest({
    endpoint: `/api/axe-spider/projects/${project_id}/scans/${scan_id}/details`,
    token,
    method: 'GET'
  });

export const getAxeSpiderProjectScanComparison = (
  token: string,
  project_id: string,
  scan_id: string
): Promise<AxeWatcherSessionComparisonResponse> =>
  apiRequest({
    endpoint: `/api/axe-spider/projects/${project_id}/scan_comparisons/${scan_id}`,
    token,
    method: 'GET'
  });

export const getAxeSpiderProjectScanComparisonMetadata = (
  token: string,
  project_id: string,
  scan_id_new: string,
  scan_id_old: string
): Promise<ProjectScanSessionComparisonMetadata> =>
  apiRequest({
    endpoint: `/api/axe-spider/projects/${project_id}/scan_comparisons_metadata/${scan_id_new}/${scan_id_old}`,
    token,
    method: 'GET'
  });

export const getAxeSpiderProjectScanIssues = (
  token: string,
  project_id: string,
  scan_id: string,
  previous_scan_id?: string | null
): Promise<UserFlowResults> => {
  const queryParams = encodeQueryParams(
    previous_scan_id
      ? {
          previous_session_id: previous_scan_id
        }
      : {}
  );

  return apiRequest({
    endpoint:
      `/api/axe-spider/projects/${project_id}/scans/${scan_id}/issues` +
      (queryParams ? `?${queryParams}` : ''),
    token,
    method: 'GET',
    headers: {
      'Accept-Encoding': 'gzip'
    }
  });
};

export const getSpiderProjectScanRuntimeDetails = (
  token: string,
  project_id: string,
  scan_id?: string
): Promise<ScanRuntimeDetails> => {
  const queryParams = encodeQueryParams({
    scan_id
  });

  return apiRequest({
    endpoint:
      `/api/axe-spider/projects/${project_id}/runtime-details` +
      (queryParams ? `?${queryParams}` : ''),
    token,
    method: 'GET'
  });
};

export const dquTransactionNotification = async (
  token: string,
  data: DquTransactionNotificationParams
): Promise<void> => {
  await apiRequest({
    endpoint: '/api/deque-university/transaction-notification',
    method: 'POST',
    token,
    body: data
  });
};

export const getAxeWatcherSessionComparisonMetadata = async (
  token: string,
  params: { project_id: string; session_id_new: string; session_id_old: string }
): Promise<SessionComparisonMetadata> => {
  const data = await apiRequest({
    endpoint: `/api/axe-watcher/${params.project_id}/session_comparisons_metadata/${params.session_id_new}/${params.session_id_old}`,
    method: 'GET',
    token
  });

  return data as SessionComparisonMetadata;
};

export const getAxeWatcherSessionComparison = async (
  token: string,
  params: { project_id: string; session_comparison_id: string }
): Promise<AxeWatcherSessionComparisonResponse> => {
  const data = await apiRequest({
    endpoint: `/api/axe-watcher/${params.project_id}/session_comparisons/${params.session_comparison_id}`,
    method: 'GET',
    token
  });

  return data as AxeWatcherSessionComparisonResponse;
};

export const getAxeWatcherProject = async (
  token: string,
  project_id: string
): Promise<Omit<AxeWatcherProject, 'project_state'>> => {
  const data = await apiRequest<
    Promise<Omit<AxeWatcherProject, 'project_state'>>
  >({
    endpoint: `/api/axe-watcher/${project_id}/project`,
    method: 'GET',
    token
  });

  return data;
};

export const getIntegrationToken = async (
  token: string,
  enterprise_id: string
): Promise<{ token: string }> => {
  const { token: signedJwt } = await apiRequest<{ token: string }>({
    endpoint: `/api/integrations/enterprises/${enterprise_id}/token`,
    method: 'GET',
    token
  });
  return { token: signedJwt };
};

type CreateIntegrationTemplateParams = {
  product_slug: string;
  project_id: string;
  issue_type_id: string;
  name: string;
  field_mappings?: Record<string, unknown>;
  impact_mappings?: Record<string, unknown>;
  custom_labels?: Array<string>;
};

export const createIntegrationTemplate = (
  token: string,
  enterprise_id: string,
  data: CreateIntegrationTemplateParams
): Promise<IntegrationTemplate> => {
  return apiRequest<IntegrationTemplate>({
    endpoint: `/api/integrations/enterprises/${enterprise_id}/templates`,
    method: 'POST',
    token,
    body: data
  });
};

type UpdateIntegrationTemplateParams = Partial<
  Omit<CreateIntegrationTemplateParams, 'product_slug'>
>;

export const updateIntegrationTemplate = (
  token: string,
  enterprise_id: string,
  template_id: string,
  data: UpdateIntegrationTemplateParams
): Promise<IntegrationTemplate> => {
  return apiRequest<IntegrationTemplate>({
    endpoint: `/api/integrations/enterprises/${enterprise_id}/templates/${template_id}`,
    method: 'PUT',
    token,
    body: data
  });
};

type DeleteResult = {
  deleted: boolean;
};

export const deleteIntegrationTemplate = (
  token: string,
  enterprise_id: string,
  template_id: string
): Promise<DeleteResult> => {
  return apiRequest<DeleteResult>({
    method: 'DELETE',
    endpoint: `/api/integrations/enterprises/${enterprise_id}/templates/${template_id}`,
    token
  });
};

export const getIntegrationTemplateById = (
  token: string,
  enterprise_id: string,
  template_id: string
): Promise<IntegrationTemplate | null> => {
  return apiRequest<IntegrationTemplate | null>({
    method: 'GET',
    endpoint: `/api/integrations/enterprises/${enterprise_id}/templates/${template_id}`,
    token
  });
};

export const getEnterpriseIntegrationTemplates = (
  token: string,
  enterprise_id: string,
  product?: SupportedIntegrationProductSlugs
): Promise<IntegrationTemplate[]> => {
  const queryParam = encodeQueryParams({
    product_slug: product
  });
  return apiRequest<IntegrationTemplate[]>({
    method: 'GET',
    endpoint: `/api/integrations/enterprises/${enterprise_id}/templates${
      queryParam ? `?${queryParam}` : ''
    }`,
    token
  });
};

type SendIssueBody = {
  projectId?: string;
  issueTypeId?: string;
  templateId?: string;
  issues: UniversalIssue[];
};

export const sendIssue = (
  token: string,
  product_slug: SupportedIntegrationProductSlugs,
  enterprise_id: string,
  options: SendIssueBody
): Promise<UniversalIssue[]> => {
  return apiRequest<UniversalIssue[]>({
    endpoint: `/api/integrations/${product_slug}/enterprises/${enterprise_id}`,
    method: 'POST',
    token,
    body: options
  });
};

interface GetAuditorTestsParams extends AuditorTestParams {
  token: string;
}

export const getAxeAuditorTests = async ({
  token,
  ...options
}: GetAuditorTestsParams): Promise<Paginated<AuditorTest>> => {
  if (options.sortBy) {
    options.sortBy = options.sortBy.split(' ').join('');
  }
  const queryParams = encodeQueryParams({
    sortBy: options.sortBy,
    sortOrder: options.sortOrder,
    createdOnEndingAt: options.createdOnEndingAt,
    createdOnStartingFrom: options.createdOnStartingFrom,
    type: options.type,
    testGroup: options.testGroup,
    search: options.search
  });
  const data = await apiRequest({
    endpoint:
      '/api/axe-auditor/v1/tests' + (queryParams ? `?${queryParams}` : ''),
    method: 'GET',
    token,
    ...options
  });

  return data as Paginated<AuditorTest>;
};
interface GetAuditorTestDetailParams {
  token: string;
  testId: string;
}
export const getAuditorTestDetail = async ({
  testId,
  token
}: GetAuditorTestDetailParams): Promise<TestDetail> => {
  const data = await apiRequest({
    endpoint: '/api/axe-auditor/v1/tests/' + testId,
    method: 'GET',
    token
  });
  return data as TestDetail;
};
interface GetAuditorTestRunUnitsParams extends AuditorTestRunUnitParams {
  token: string;
  testId: string;
  testRunId: string;
}
export const getAxeAuditorTestRunUnits = async ({
  token,
  testId,
  testRunId,
  ...options
}: GetAuditorTestRunUnitsParams): Promise<Paginated<RunUnit>> => {
  if (options.sortBy) {
    options.sortBy = options.sortBy.split(' ').join('');
  }
  const search = (options.search || '').trim();
  const searchParam = search ? { search } : {};
  const queryParams = encodeQueryParams({
    sortBy: options.sortBy,
    sortOrder: options.sortOrder,
    type: options.type,
    status: options.status,
    ...searchParam
  });
  const data = await apiRequest({
    endpoint:
      `/api/axe-auditor/v1/tests/${testId}/test-runs/${testRunId}/units` +
      (queryParams ? `?${queryParams}` : ''),
    method: 'GET',
    token,
    ...options
  });
  return data as Paginated<RunUnit>;
};
