import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Combobox,
  IconButton,
  Loader,
  Panel,
  PanelContent
} from '@deque/cauldron-react';

import { useTranslation } from 'react-i18next';

import styles from './AddUsers.css';
import { ComboboxValue } from '@deque/cauldron-react/lib/components/Combobox/ComboboxOption';
import { useAuthContext } from '../../../common/contexts/auth';
import { useEnterprises } from '../../../common/contexts/enterprises';
import useV2EnterpriseMembers from '../../../common/hooks/useV2EnterpriseMembers';
import { EnterpriseMembers } from '../../../common/utils/billing-client/client-v2';
import ContentToast from '../../../common/components/ContentToast';

export interface AddUsersProps {
  userIds: string[];
  setUsersToAdd: (usersToAdd: string[]) => void;
  setUsersToRemove: (usersToAdd: string[]) => void;
}

type ComboboxOption = {
  label: string;
  value: string;
};

export function isAcceptedMember(
  enterpiseMember: EnterpriseMembers.PendingOrAcceptedMember
): enterpiseMember is EnterpriseMembers.AcceptedMember {
  return (
    (enterpiseMember as EnterpriseMembers.AcceptedMember).user_id !== undefined
  );
}

export const getComboboxLabel = (
  enterpiseMember: EnterpriseMembers.AcceptedMember | null
): string => {
  if (!enterpiseMember) {
    return '';
  }

  return `${enterpiseMember.first_name} ${enterpiseMember.last_name} (${enterpiseMember.email})`;
};

const getUserFromId = (
  enterpriseMembers: EnterpriseMembers.PendingOrAcceptedMember[] | null,
  userId: string
): EnterpriseMembers.AcceptedMember | null => {
  const foundUser = enterpriseMembers?.find(
    enterpiseMember =>
      isAcceptedMember(enterpiseMember) && enterpiseMember.user_id === userId
  );

  if (foundUser && isAcceptedMember(foundUser)) {
    return foundUser;
  } else {
    return null;
  }
};

const convertEnterpiseMembersToComboboxOptions = (
  enterpriseMembers: EnterpriseMembers.PendingOrAcceptedMember[] | null,
  membersToAdd: string[]
): ComboboxOption[] => {
  const acceptedUsersAsComboboxOptions: ComboboxOption[] = [];
  if (enterpriseMembers) {
    for (const enterpiseMember of enterpriseMembers) {
      if (
        isAcceptedMember(enterpiseMember) &&
        !membersToAdd.includes(enterpiseMember.user_id)
      ) {
        acceptedUsersAsComboboxOptions.push({
          label: getComboboxLabel(enterpiseMember),
          value: enterpiseMember.user_id
        });
      }
    }
  }

  return acceptedUsersAsComboboxOptions;
};

export const AddUsers: React.FC<AddUsersProps> = ({
  userIds: initiallySelectedUserIds,
  setUsersToAdd,
  setUsersToRemove
}) => {
  const { t } = useTranslation();

  const [membersToAdd, setMembersToAdd] = useState<string[]>(
    initiallySelectedUserIds
  );

  const { user } = useAuthContext();
  const {
    activeEnterprise,
    loading: loadingEnterprises,
    error: enterprisesError
  } = useEnterprises();

  const token = user?.token;
  const enterpriseId = activeEnterprise?.id;
  const {
    pendingOrAcceptedMembers,
    loading: loadingMembers,
    error: memberError
  } = useV2EnterpriseMembers({ token, enterpriseId });

  // Calculate users that have been added or removed
  useEffect(() => {
    const netAddedUsers: string[] = [];
    for (const memberToAdd of membersToAdd) {
      if (!initiallySelectedUserIds.includes(memberToAdd)) {
        netAddedUsers.push(memberToAdd);
      }
    }
    setUsersToAdd(netAddedUsers);

    const netRemovedUsers: string[] = [];
    for (const initiallySelectedUserId of initiallySelectedUserIds) {
      if (
        !membersToAdd.find(
          memberToFind => memberToFind === initiallySelectedUserId
        )
      ) {
        netRemovedUsers.push(initiallySelectedUserId);
      }
    }
    setUsersToRemove(netRemovedUsers);
  }, [membersToAdd]);

  // Precalculate combobox options
  const comboboxOptions = useMemo(
    () =>
      convertEnterpiseMembersToComboboxOptions(
        pendingOrAcceptedMembers,
        membersToAdd
      ),
    [pendingOrAcceptedMembers, membersToAdd]
  );

  // If there is an error, show Toast and exit
  if (enterprisesError || memberError) {
    return (
      <ContentToast show={true} type="caution">
        {t('Failed to load users.')}
      </ContentToast>
    );
  }

  // Show loader (with focus) while loading enterpise members
  const loaderRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    loaderRef.current?.focus();
  }, [loaderRef.current]);
  if (loadingEnterprises || loadingMembers) {
    return (
      <Loader label={t('Loading users...')} tabIndex={-1} ref={loaderRef} />
    );
  }

  const handleSelectionChange = ({
    value
  }: {
    target: HTMLElement;
    value: ComboboxValue;
    previousValue: ComboboxValue;
  }) => {
    if (value) {
      setMembersToAdd(currentMembersToAdd => [...currentMembersToAdd, value]);
    }
  };

  const removeMember = (userIdToRemove: string): void => {
    setMembersToAdd(currentMembersToAdd =>
      currentMembersToAdd.filter(
        memberToCheck => memberToCheck !== userIdToRemove
      )
    );
  };

  return (
    <div className={styles.addedUserWrapper}>
      {comboboxOptions.length === 0 && (
        <div className={styles.noUsersNotice}>
          {t('All users have been added to the team')}
        </div>
      )}
      <Combobox
        label={t('Find and Add Members')}
        options={comboboxOptions}
        onSelectionChange={handleSelectionChange}
        key={new Date().getTime()} // random key to force regeneration of a combobox to avoid selection from "sticking" after re-render
      />

      {membersToAdd.length > 0 && (
        <Panel padding={false} className={styles.addedUserPanel}>
          {membersToAdd.map(userId => (
            <PanelContent
              className={styles.addedUserPanelContent}
              key={userId}
              data-testid="selected-user"
            >
              <span>
                {getComboboxLabel(
                  getUserFromId(pendingOrAcceptedMembers, userId)
                )}
              </span>
              <span className={styles.spacer}></span>
              <IconButton
                icon="trash"
                label={t('Delete')}
                tooltipProps={{ placement: 'bottom' }}
                onClick={() => removeMember(userId)}
              />
            </PanelContent>
          ))}
        </Panel>
      )}
    </div>
  );
};

export default AddUsers;
