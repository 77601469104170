import React, {
  type ReactNode,
  createContext,
  useMemo,
  useContext,
  useCallback
} from 'react';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';

import type { ErrorOrNull } from '../../../typings/utils';
import getProjects, {
  GetIntegrationProjectsResponse
} from '../../common/utils/integrations-client/jira/getProjectsLegacy';
import { useIntegrations } from '../../common/contexts/Integrations';
import getProjectIssueCreatePermissions from '../../common/utils/integrations-client/jira/getProjectIssueCreatePermissions';

export interface IssueType {
  name: string;
  id: string;
}

export interface Project {
  name: string;
  id: string;
  key: string;
  issueTypes?: IssueType[];
}

/**
 * State stored within the context.
 */

export interface State {
  loading: boolean;
  error: Error | null;
  projects: Project[];
}

export interface Methods {
  getProjectById: (projectId: string | null) => Project | undefined;
  getIssueTypesByProjectId: (
    projectId: string | null
  ) => IssueType[] | undefined;
  getIssueTypeById: (issueTypeId: string | null) => IssueType | undefined;
}

const Context = createContext<State & Methods>({
  loading: true,
  error: null,
  projects: [],
  getProjectById: () => undefined,
  getIssueTypesByProjectId: () => undefined,
  getIssueTypeById: () => undefined
});

export interface IntegrationProjectsProviderProps {
  initialIntegrationProjects?: Project[];
  initialLoading?: boolean;
  initialError?: ErrorOrNull;
  initialData?: GetIntegrationProjectsResponse;
  children: ReactNode;
}

export const IntegrationProjectsProvider = ({
  initialIntegrationProjects = [],
  initialLoading = false,
  initialError = null,
  children
}: IntegrationProjectsProviderProps) => {
  const { t } = useTranslation();

  const {
    loading: integrationsLoading,
    error: integrationsError,
    updatingToken,
    integrationUser,
    canAccessIntegrations,
    connectedIntegrations,
    getToken
  } = useIntegrations();

  const loading = useMemo(() => {
    return (
      integrationsLoading || updatingToken || !integrationUser || initialLoading
    );
  }, [integrationsLoading, updatingToken, integrationUser]);

  const error = useMemo(() => {
    if (!loading && !canAccessIntegrations) {
      return new Error(t('Cannot access integrations'));
    }
    return integrationsError || initialError;
  }, [integrationsError]);

  const shouldFetch = useMemo(() => {
    return (
      !loading &&
      !error &&
      canAccessIntegrations &&
      !!connectedIntegrations?.length
    );
  }, [loading, error, canAccessIntegrations, connectedIntegrations]);

  const {
    data: integrationProjectsData,
    error: integrationProjectsError,
    isLoading: integrationProjectsLoading
  } = useSWR(
    shouldFetch &&
      initialIntegrationProjects.length === 0 &&
      'integrationProjects',
    () => getProjects({ getToken }),
    {
      revalidateOnFocus: false
    }
  );

  const {
    data: createProjectIssuePermissionsData,
    error: createProjectIssuePermissionsError,
    isLoading: createProjectIssuePermissionsLoading
  } = useSWR(
    shouldFetch &&
      !integrationProjectsLoading &&
      !integrationProjectsError &&
      initialIntegrationProjects.length === 0 &&
      'createProjectIssuePermissions',
    () => getProjectIssueCreatePermissions({ getToken }),
    {
      revalidateOnFocus: false
    }
  );

  const fetchedProjects = useMemo(
    () =>
      (initialIntegrationProjects.length > 0 && initialIntegrationProjects) ||
      integrationProjectsData?.values.map(project => {
        const fetchedIssueTypes: IssueType[] =
          project.issueTypes
            ?.filter(issueType => !issueType.subtask)
            .map(issueType => ({
              name: issueType.name || '',
              id: issueType.id || ''
            })) || [];

        return {
          key: project.key,
          name: project.name,
          id: project.id,
          issueTypes: fetchedIssueTypes
        };
      }) ||
      [],
    [integrationProjectsData]
  );

  const projectsWithPermissions = useMemo(
    () =>
      initialIntegrationProjects.length > 0
        ? initialIntegrationProjects
        : fetchedProjects.filter(project =>
            createProjectIssuePermissionsData?.projects.some(
              permission => permission.id === +project.id
            )
          ) || [],
    [fetchedProjects, createProjectIssuePermissionsData]
  );

  const getProjectById = useCallback(
    (id: string | null): Project | undefined =>
      id
        ? projectsWithPermissions.find(project => project.id === id)
        : undefined,
    [projectsWithPermissions]
  );

  const getIssueTypesByProjectId = useCallback(
    (id: string | null): IssueType[] | undefined => {
      if (!id) {
        return;
      }

      const project = getProjectById(id);
      if (!project) {
        return;
      }

      return project.issueTypes;
    },
    [getProjectById, projectsWithPermissions]
  );

  const getIssueTypeById = useCallback(
    (id: string | null): IssueType | undefined =>
      id
        ? projectsWithPermissions
            .map(project => project.issueTypes || [])
            .flat()
            .find(issueType => issueType.id === id)
        : undefined,
    [projectsWithPermissions]
  );

  const providerValue: State & Methods = useMemo(
    () => ({
      loading:
        loading ||
        integrationProjectsLoading ||
        createProjectIssuePermissionsLoading,
      error:
        error || integrationProjectsError || createProjectIssuePermissionsError,
      projects: projectsWithPermissions,
      getProjectById,
      getIssueTypesByProjectId,
      getIssueTypeById
    }),
    [
      loading,
      integrationsLoading,
      integrationProjectsLoading,
      error,
      integrationsError,
      integrationProjectsError,
      projectsWithPermissions
    ]
  );

  return <Context.Provider value={providerValue}>{children}</Context.Provider>;
};

/**
 * Use the Integrations context.
 */

export const useIntegrationProjects = (): State & Methods =>
  useContext(Context);
