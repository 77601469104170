import {
  Button,
  IconButton,
  Loader,
  Panel,
  PanelContent,
  PanelHeader,
  TextField
} from '@deque/cauldron-react';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthContext } from '../../../common/contexts/auth';
import { useEnterprises } from '../../../common/contexts/enterprises';
import useV2EnterpriseMembers from '../../../common/hooks/useV2EnterpriseMembers';
import { EnterpriseMembers } from '../../../common/utils/billing-client/client-v2';

import { EditVariant } from '../../types';
import { Team } from '../../../common/api-client';
import { MAX_TEAM_NAME_LENGTH } from '../../../common/constants';
import { deduplicateUserIds, getCurrentUserIds } from '../../utils/teams-utils';
import { AddUsers, getComboboxLabel, isAcceptedMember } from './AddUsers';
import styles from './SettingSteps.css';

export interface SettingStepsProps {
  variant: EditVariant;
  team: Team;
  setTeam: (team: Team) => void;
  nextStep: () => void;
  prevStep: () => void;
  gotoStep: (step: number) => void;
}

export const BasicData: React.FC<SettingStepsProps> = ({
  team,
  setTeam,
  nextStep
}) => {
  const { t } = useTranslation();

  const [nameError, setNameError] = useState<string>();

  const nameFieldRef = useRef<HTMLInputElement | null>(null);

  const validateTeamName = (value?: string) => {
    if (!value || !value?.length) {
      return t('Cannot be empty or blank');
    }
    if (value?.length > MAX_TEAM_NAME_LENGTH) {
      return t(`Cannot be longer than {{maxLength}} characters`, {
        maxLength: MAX_TEAM_NAME_LENGTH
      });
    }
    return undefined;
  };

  const onNext = () => {
    const name = nameFieldRef.current?.value.trim();
    const newNameError = validateTeamName(name);
    if (!newNameError) {
      // The name has been validated at this point, and is not undefined
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setTeam({ ...team, name: name! });
      nextStep();
    } else {
      setNameError(newNameError);
    }
  };

  return (
    <Panel className={styles.settingsPanel}>
      <PanelHeader className={styles.panelHeader}>
        <h2>{t('Provide Team Name')}</h2>
        <Button disabled variant="link">
          {t('Skip')}
        </Button>
      </PanelHeader>
      <PanelContent>
        <div className={styles.innerPanel}>
          <TextField
            name="teamName"
            fieldRef={nameFieldRef}
            label={t('Team Name')}
            defaultValue={team.name}
            error={nameError}
            required
            autoFocus
          />
        </div>
      </PanelContent>
      <PanelContent className={styles.panelFooter}>
        <Button type="button" onClick={onNext}>
          {t('Next')}
        </Button>
      </PanelContent>
    </Panel>
  );
};

export const TeamMembers: React.FC<SettingStepsProps> = ({
  team,
  setTeam,
  nextStep,
  prevStep
}) => {
  const { t } = useTranslation();

  const [usersToAdd, setUsersToAdd] = useState<string[]>([]);
  const [usersToRemove, setUsersToRemove] = useState<string[]>([]);

  const onNext = () => {
    const [user_ids_to_add, user_ids_to_remove] = deduplicateUserIds(
      team,
      usersToAdd,
      usersToRemove
    );
    setTeam({
      ...team,
      user_ids_to_add,
      user_ids_to_remove
    });
    nextStep();
  };

  return (
    <Panel className={styles.settingsPanel}>
      <PanelHeader className={styles.panelHeader}>
        <h2>{t(`Add Members to "{{name}}"`, { name: team.name })}</h2>
        <Button variant="link" onClick={nextStep}>
          {t('Skip')}
        </Button>
      </PanelHeader>
      <PanelContent>
        <div className={styles.innerPanel}>
          <AddUsers
            userIds={getCurrentUserIds(team)}
            setUsersToAdd={setUsersToAdd}
            setUsersToRemove={setUsersToRemove}
          />
        </div>
      </PanelContent>
      <PanelContent className={styles.panelFooter}>
        <Button onClick={prevStep} variant="secondary">
          {t('Previous')}
        </Button>
        <Button type="button" onClick={onNext}>
          {t('Next')}
        </Button>
      </PanelContent>
    </Panel>
  );
};

export const Review: React.FC<SettingStepsProps> = ({
  team,
  variant,
  prevStep,
  gotoStep
}) => {
  const { t } = useTranslation();

  const { user } = useAuthContext();
  const { activeEnterprise } = useEnterprises();

  const token = user?.token;
  const enterpriseId = activeEnterprise?.id;

  const { pendingOrAcceptedMembers, loading: loadingMembers } =
    useV2EnterpriseMembers({ token, enterpriseId });

  if (loadingMembers) {
    return <Loader label={t('Loading users...')} tabIndex={-1} />;
  }

  const rowForUserId = (userId: string) => {
    const foundUser: EnterpriseMembers.AcceptedMember | null =
      pendingOrAcceptedMembers?.find(
        enterpiseMember =>
          isAcceptedMember(enterpiseMember) &&
          enterpiseMember.user_id === userId
      ) as EnterpriseMembers.AcceptedMember | null;
    return (
      foundUser && (
        <li key={`user-${foundUser.email}`}>{getComboboxLabel(foundUser)}</li>
      )
    );
  };

  const submitLabel = () => {
    switch (variant) {
      case EditVariant.edit:
        return t('Update Team');
      case EditVariant.create:
        return t('Create Team');
    }
  };

  return (
    <Panel className={styles.settingsPanel}>
      <PanelHeader className={styles.panelHeader}>
        <h2>{t(`Review "{{name}}"`, { name: team.name })}</h2>
      </PanelHeader>
      <PanelContent>
        <div className={styles.innerPanel}>
          <h3>
            {t('Team Details')}{' '}
            <IconButton
              icon="pencil"
              label="Edit"
              onClick={() => gotoStep(0)}
            />
          </h3>
          <dl>
            <dt id="team-name-label">{t('Team Name')}</dt>
            <dd aria-labelledby="team-name-label">{team.name}</dd>
          </dl>
        </div>
      </PanelContent>
      <PanelContent>
        <div className={styles.innerPanel}>
          <h3>
            {t('Team Members')}{' '}
            <IconButton
              icon="pencil"
              label="Edit"
              onClick={() => gotoStep(1)}
            />
          </h3>
          <dl>
            <dt id="team-members-added-label">
              {t(`Added ({{addedCount}})`, {
                addedCount: team.user_ids_to_add.length
              })}
            </dt>
            <dd aria-labelledby="team-members-added-label">
              {team.user_ids_to_add.length ? (
                <ul>
                  {team.user_ids_to_add.map(user_id => rowForUserId(user_id))}
                </ul>
              ) : (
                <>{t('No users added')}</>
              )}
            </dd>
            {variant === EditVariant.edit && (
              <>
                <dt id="team-members-removed-label">
                  {t(`Removed ({{removedCount}})`, {
                    removedCount: team.user_ids_to_remove.length
                  })}
                </dt>
                <dd aria-labelledby="team-members-removed-label">
                  {team.user_ids_to_remove.length ? (
                    <ul>
                      {team.user_ids_to_remove.map(user_id =>
                        rowForUserId(user_id)
                      )}
                    </ul>
                  ) : (
                    <>{t('No users removed')}</>
                  )}
                </dd>
              </>
            )}
          </dl>
        </div>
      </PanelContent>
      <PanelContent className={styles.panelFooter}>
        <Button onClick={prevStep} variant="secondary">
          {t('Previous')}
        </Button>
        <Button type="submit">{submitLabel()}</Button>
      </PanelContent>
    </Panel>
  );
};
