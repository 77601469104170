import React, {
  type FC,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { z } from 'zod';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { TFunction, useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { Loader, Panel, PanelContent } from '@deque/cauldron-react';

import {
  IMPACTS,
  type Impacts,
  type TemplateFormFields
} from '../../../../types';
import {
  IntegrationTemplate,
  createIntegrationTemplate,
  updateIntegrationTemplate
} from '../../../../../common/api-client';
import analyticsInstances, {
  getFallbackAnalyticsInstanceId
} from '../../../../../common/analyticsInstances';
import TemplateWizardFooter from './FormFooter/FormFooter';
import { useAuthContext } from '../../../../../common/contexts/auth';
import useMediaQuery from '../../../../../common/hooks/useMediaQuery';
import { useEnterprises } from '../../../../../common/contexts/enterprises';
import { useGlobalToast } from '../../../../../common/contexts/globalToast';
import useIntegrationTemplates from '../../../../hooks/useIntegrationTemplates';
import { useIntegrationProjects } from '../../../../contexts/IntegrationProjects';
import { SupportedIntegrationProductSlugs } from '../../../../../common/constants';
import { useIntegrationTemplateWizardSteps } from '../../../../contexts/IntegrationTemplateWizardSteps';
import getIntegrationProductName from '../../../../../common/utils/get-integration-product-name-from-slug';
import styles from './TemplateWizardForm.css';

export interface FieldMappingField {
  axeField: string;
  mappingField: string;
}

export const defaultTemplateValues: TemplateFormFields = {
  templateName: '',
  project: '',
  issueType: '',
  fieldMapping: [],
  impactMapping: IMPACTS.reduce(
    (acc, impact) => ({ ...acc, [impact]: '' }),
    {} as Record<Impacts, string>
  ),
  customLabels: []
};

const mapTemplateDataForFrom = (
  template: IntegrationTemplate
): TemplateFormFields => {
  const { name, project_id, issue_type_id, field_mappings, impact_mappings } =
    template;

  const fieldMapping = field_mappings
    ? Object.entries(field_mappings).map(([key, value]) => ({
        axeField: value as string,
        mappingField: key
      }))
    : defaultTemplateValues.fieldMapping;

  const impactMapping = impact_mappings
    ? { ...defaultTemplateValues.impactMapping, ...impact_mappings }
    : defaultTemplateValues.impactMapping;

  return {
    ...defaultTemplateValues,
    templateName: name,
    project: project_id || '',
    issueType: issue_type_id || '',
    fieldMapping,
    impactMapping
  };
};

const getTemplateSchema = (t: TFunction) =>
  z.object({
    templateName: z
      .string({
        required_error: t('Template Name is required')
      })
      .min(1, {
        message: t('Template Name is required')
      }),
    project: z
      .string({
        required_error: t('Project is required')
      })
      .min(1, {
        message: t('Project is required')
      }),
    issueType: z
      .string({
        required_error: t('Issue Type is required')
      })
      .min(1, {
        message: t('Issue Type is required')
      }),
    fieldMapping: z.array(
      z
        .object({
          axeField: z.string().optional(),
          mappingField: z.string().optional()
        })
        .refine(
          data =>
            (data.axeField && data.mappingField) ||
            (!data.axeField && !data.mappingField),
          {
            message: t('Field is required')
          }
        )
    ),
    impactMapping: z.record(
      z.enum(IMPACTS as [string, ...string[]]),
      z.string().optional()
    )
    //for future implementation, out of scope for now
    // customLabels: z.array(z.record(z.string(), z.string()))
  });

const TemplateWizardForm: FC<{
  initialTemplate?: IntegrationTemplate;
  integrationProductSlug: SupportedIntegrationProductSlugs;
}> = ({ initialTemplate, integrationProductSlug }) => {
  const { t } = useTranslation();
  const analytics =
    analyticsInstances[getFallbackAnalyticsInstanceId(integrationProductSlug)];

  const { user } = useAuthContext();
  const { activeEnterprise } = useEnterprises();
  const narrow = useMediaQuery('(max-width: 37.5rem)');
  const { setContents } = useGlobalToast();
  const { currentStep, steps } = useIntegrationTemplateWizardSteps();
  const { projects } = useIntegrationProjects();
  const { mutateTemplates } = useIntegrationTemplates({
    integrationProductSlug
  });
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const templateSchema = useMemo(() => getTemplateSchema(t), [t]);

  const methods = useForm({
    resolver: zodResolver(templateSchema),
    defaultValues: defaultTemplateValues
  });
  const { handleSubmit, getValues, reset } = methods;

  useEffect(() => {
    if (!initialTemplate) {
      return;
    }

    const initialValues = mapTemplateDataForFrom(initialTemplate);
    reset(initialValues);
  }, [initialTemplate]);

  const mapFieldMappingFormValues = useCallback(() => {
    const formFieldMapping = getValues('fieldMapping');

    return formFieldMapping.reduce((result, item) => {
      result[item.mappingField] = item.axeField;
      return result;
    }, {} as Record<string, string>);
  }, []);

  const mapImpactMappingFormValues = useCallback(() => {
    const formImpactMapping = getValues('impactMapping');

    const chosenImpacts = Object.entries(formImpactMapping).filter(
      ([, value]) => !!value
    );

    return chosenImpacts.length ? Object.fromEntries(chosenImpacts) : undefined;
  }, []);

  const onSubmit = async () => {
    if (!user?.token || !activeEnterprise?.id) {
      return;
    }

    setIsLoading(true);

    const impactMapping = mapImpactMappingFormValues();
    const filedMapping = mapFieldMappingFormValues();

    const formValues = getValues();
    const { templateName, issueType, project } = formValues;

    const templateData = {
      name: templateName,
      issue_type_id: issueType,
      project_id: project,
      field_mappings: filedMapping,
      impact_mappings: impactMapping
    };

    const integration = getIntegrationProductName(integrationProductSlug, {
      capitalize: true
    });
    const projectName = projects.find(p => p.id === project)?.name || '';
    const issueTypeName =
      projects
        .find(p => p.id === project)
        ?.issueTypes?.find(i => i.id === issueType)?.name || '';

    try {
      const result = initialTemplate
        ? await updateIntegrationTemplate(
            user.token,
            activeEnterprise.id,
            initialTemplate.id,
            templateData
          )
        : await createIntegrationTemplate(user.token, activeEnterprise.id, {
            ...templateData,
            product_slug: integrationProductSlug
          });

      mutateTemplates();

      if (initialTemplate) {
        analytics.integrationTemplateEditSave({
          integration: integrationProductSlug,
          project: projectName,
          issueType: issueTypeName,
          template: templateName
        });
      } else {
        analytics.integrationTemplateAddSave({
          integration: integrationProductSlug,
          project: projectName,
          issueType: issueTypeName,
          template: templateName
        });
      }

      const toastMessage = t(
        'Successfully {{ appliedAction }} a {{ integration }} Template "{{ templateName }}"',
        {
          appliedAction: initialTemplate ? 'updated' : 'added',
          integration,
          templateName: result.name
        }
      );

      setContents(toastMessage, 'confirmation');
      setIsLoading(false);

      return history.push(
        `/configuration/integrations/${integrationProductSlug}/templates/`
      );
    } catch (error) {
      if (initialTemplate) {
        analytics.integrationTemplateEditFail({
          integration: integrationProductSlug,
          project: projectName,
          issueType: issueTypeName,
          template: templateName
        });
      } else {
        analytics.integrationTemplateAddFail({
          integration: integrationProductSlug,
          project: projectName,
          issueType: issueTypeName,
          template: templateName
        });
      }
      setIsLoading(false);

      const toastMessage = t(
        'Unable to {{ appliedAction }} a {{ integration }} Template "{{ templateName }}"',
        {
          appliedAction: initialTemplate ? 'update' : 'create',
          integration,
          templateName
        }
      );
      setContents(toastMessage, 'error');
    }
  };

  if (isLoading) {
    const loaderLabel = initialTemplate
      ? t('Updating template...')
      : t('Creating template...');

    return <Loader label={loaderLabel} />;
  }

  return (
    <FormProvider {...methods}>
      <form className={styles.wrap} onSubmit={handleSubmit(onSubmit)}>
        <Panel padding={false} className={styles.container}>
          <PanelContent
            className={classNames(
              styles.footerContentContainer,
              narrow ? styles.mobilePanelContent : undefined
            )}
          >
            <div className={styles.headerContainer}>
              <h1 className={styles.header}>{steps[currentStep].header}</h1>
              {steps[currentStep].description && (
                <p className={styles.description}>
                  {steps[currentStep].description}
                </p>
              )}
            </div>
            {steps[currentStep].content}
          </PanelContent>
          <TemplateWizardFooter
            integrationProductSlug={integrationProductSlug}
            handleSubmit={handleSubmit(onSubmit)}
            narrow={narrow}
            isEditMode={!!initialTemplate}
          />
        </Panel>
      </form>
    </FormProvider>
  );
};

export default TemplateWizardForm;
