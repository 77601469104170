import { integrationsRequest } from '../client';
import { SupportedIntegrationProductSlugs } from '../../../constants';
import getIntegrationProductName from '../../../utils/get-integration-product-name-from-slug';

const integrationType = getIntegrationProductName(
  SupportedIntegrationProductSlugs.jiraIntegration
);

export interface ProjectPermissions {
  key: string;
  id: number;
}

export interface GetIntegrationPermissionsResponse {
  projects: ProjectPermissions[];
}

/**
 * `GET '/rest/api/3/permissions/project'` - retrieve projects the user has permission to create issues for.
 *
 * Used to retrieve a list of all the integration projects that the user has permission to create issues for.
 *
 * {@see - https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-permissions/#api-rest-api-3-permissions-project-post}
 * @returns {Promise<GetIntegrationPermissionsResponse | void>}
 *
 */
const getProjectIssueCreatePermissions = ({
  getToken
}: {
  getToken: () => Promise<string | undefined>;
}): Promise<GetIntegrationPermissionsResponse | void> => {
  return integrationsRequest<GetIntegrationPermissionsResponse>({
    integrationType,
    path: '/rest/api/3/permissions/project',
    init: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        permissions: ['CREATE_ISSUES']
      })
    },
    getToken
  });
};

export default getProjectIssueCreatePermissions;
