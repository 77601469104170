import { useEnterprises } from '../../common/contexts/enterprises';
import {
  getAllUserSubscriptions,
  listProductAccess
} from '@deque/billing-utils';
import {
  hasAccessToProduct,
  hasAnyActiveSubscription
} from '../../common/utils/subscriptions';
import { useAuthContext } from '../../common/contexts/auth';
import { ProductSlugs } from '../../common/constants';
import { useFeatureFlagState } from '../../common/contexts/featureFlags';
import { AXE_WATCHER_PAID_PLANS_V1 } from '../../axe-watcher/constants';

export interface ConfigurationAccessState {
  userHasAccessToConfiguration: boolean;
  checkUserAccessToProduct: (slug: ProductSlugs) => boolean;
}
export const useConfigurationAccess = (): ConfigurationAccessState => {
  const { activeEnterprise, isAdmin: isEnterpriseAdmin } = useEnterprises();
  const { billingUser } = useAuthContext();
  const hasAxeWatcherPaidPlans = useFeatureFlagState(AXE_WATCHER_PAID_PLANS_V1);

  // if user is admin show config even if the specific account doesn't have access to it
  // otherwise only consider user's own subscriptions
  const subscriptions =
    (activeEnterprise && isEnterpriseAdmin
      ? activeEnterprise.subscriptions
      : billingUser && getAllUserSubscriptions(billingUser)) || [];
  const productAccess = listProductAccess(subscriptions);

  // Consider showing the configuration page for products that are on free states
  const allowedSlugsWithFreeStates = hasAxeWatcherPaidPlans
    ? [ProductSlugs.axeDevToolsWatcher]
    : [];

  const userHasAccessToConfiguration = hasAnyActiveSubscription({
    productAccess,
    allowedSlugsWithFreeStates
  });

  const checkUserAccessToProduct = (slug: ProductSlugs) =>
    hasAccessToProduct({
      productAccess,
      slug,
      allowedSlugsWithFreeStates
    });

  return { checkUserAccessToProduct, userHasAccessToConfiguration };
};
