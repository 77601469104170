import { useCallback, useMemo } from 'react';
import useSWR, { type KeyedMutator } from 'swr';

import type { ErrorOrNull } from '../../../typings/utils';
import {
  type IntegrationTemplate,
  getEnterpriseIntegrationTemplates
} from '../../common/api-client';
import { SupportedIntegrationProductSlugs } from '../../common/constants';
import { useIntegrationProjects } from '../contexts/IntegrationProjects';
import { useEnterprises } from '../../common/contexts/enterprises';
import { useAuthContext } from '../../common/contexts/auth';

export interface IntegrationTemplateProps {
  integrationProductSlug: SupportedIntegrationProductSlugs;
}

export interface IntegrationTemplateState {
  templates: IntegrationTemplate[];
  loading: boolean;
  error: ErrorOrNull;
  mutateTemplates: KeyedMutator<IntegrationTemplate[] | null>;
  isTemplateNameUnique: (name: string, id?: string) => boolean;
  getTemplatesByProjectAndIssueTypeId: (
    projectId: string,
    issueTypeId: string
  ) => IntegrationTemplate[];
}

const useIntegrationTemplates = ({
  integrationProductSlug
}: IntegrationTemplateProps): IntegrationTemplateState => {
  const { user } = useAuthContext();
  // There will always be a user and with a token.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const token = user!.token;

  const {
    activeEnterprise,
    loading: enterprisesLoading,
    error: enterprisesError
  } = useEnterprises();

  const {
    loading: integrationProjectsLoading,
    error: integrationProjectError,
    projects
  } = useIntegrationProjects();

  const loading = useMemo(() => {
    return enterprisesLoading || integrationProjectsLoading;
  }, [enterprisesLoading, integrationProjectsLoading]);

  const error = useMemo(() => {
    let err: ErrorOrNull = null;
    if (enterprisesError) {
      err =
        typeof enterprisesError === 'string'
          ? new Error(enterprisesError)
          : enterprisesError;
    } else if (!activeEnterprise) {
      err = new Error('No active enterprise');
    } else if (integrationProjectError) {
      err = integrationProjectError;
    }

    return err;
  }, [enterprisesError, activeEnterprise, integrationProjectError]);

  const shouldFetch = useMemo(() => {
    return !loading && !error && !!token;
  }, [loading, error, token]);

  const {
    isLoading: templatesLoading,
    error: templatesError,
    mutate: mutateTemplates,
    data
  } = useSWR(
    shouldFetch && 'templates',
    () =>
      activeEnterprise
        ? getEnterpriseIntegrationTemplates(
            token,
            activeEnterprise.id,
            integrationProductSlug
          )
        : null,
    {
      revalidateOnFocus: false
    }
  );

  const templates = useMemo(
    () =>
      data?.filter(template =>
        projects.some(project => project.id === template.project_id)
      ) || [],
    [projects, data]
  );

  const isTemplateNameUnique = (name: string, id?: string): boolean =>
    !templates.some(
      template =>
        template.name.toLocaleLowerCase() === name.toLocaleLowerCase() &&
        id !== template.id
    );

  const getTemplatesByProjectAndIssueTypeId = useCallback(
    (projectId: string, issueTypeId: string): IntegrationTemplate[] => {
      return templates.filter(
        template =>
          template.project_id === projectId &&
          template.issue_type_id === issueTypeId
      );
    },
    [templates]
  );

  const state: IntegrationTemplateState = useMemo(() => {
    return {
      templates: templates,
      loading: loading || templatesLoading,
      error: error || templatesError,
      mutateTemplates,
      isTemplateNameUnique,
      getTemplatesByProjectAndIssueTypeId
    };
  }, [templates, loading, error, mutateTemplates]);

  return state;
};

export default useIntegrationTemplates;
