/**
 * Convert the given comma separated string `str` into an array. Trims whitespace and drops empty entries.
 *
 * Example
 *
 * ```
 * var fruits = commaSeparatedStringToArray(',apples, bananas ,\tpears)
 * // => ['apples', 'bananas', 'pears']
 * ```
 */

export const commaSeparatedStringToArray = (str = ''): string[] =>
  str
    .split(',')
    .map(s => s.trim())
    .filter(Boolean);

const allowedRedirectDomains = commaSeparatedStringToArray(
  process.env.ALLOWED_REDIRECT_DOMAINS
);
const allowedDomainRegexes = allowedRedirectDomains.map(domain => {
  const safe = domain.replace(/\./g, '\\.').replace(/\*/g, '(.*)') + '$';
  const regex = new RegExp(safe, 'i');
  return regex;
});

const supportUrl = process.env.SUPPORT_URL || '';

export const isSupportRedirect = (uri: string) => {
  try {
    return new URL(supportUrl).hostname === new URL(uri).hostname;
  } catch {
    return false;
  }
};

export const isValidRedirectURI = (redirectURI: string | null): boolean => {
  if (!redirectURI) {
    return false;
  }
  try {
    const redirectURIOrigin = new URL(redirectURI, window.location.origin)
      .origin;
    return (
      allowedDomainRegexes.some(r => r.test(redirectURIOrigin)) ||
      window.location.origin === redirectURIOrigin
    );
  } catch {
    return false;
  }
};
