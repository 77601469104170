import webdriverio from './assets/images/webdriverio.svg';
import playwright from './assets/images/playwright.svg';
import puppeteer from './assets/images/puppeteer.svg';
import webdriverjs from './assets/images/webdriverjs.svg';
import cypress from './assets/images/cypress.svg';

export const AUTOMATIONS = {
  CYPRESS: 'Cypress',
  WEBDRIVERJS: 'WebDriverJS',
  PLAYWRIGHT: 'Playwright',
  PLAYWRIGHT_TEST: 'Playwright Test',
  PUPPETEER: 'Puppeteer',
  WEBDRIVERIO: 'WebdriverIO',
  WEBDRIVERIOTESTRUNNER: 'WebdriverIO Testrunner'
};

type Automations = typeof AUTOMATIONS;

export type AutomationKey = keyof Automations;
export type Automation = Automations[AutomationKey];

export const AUTOMATION_IMAGES: Record<Automation, string> = {
  [AUTOMATIONS.CYPRESS]: cypress,
  [AUTOMATIONS.PLAYWRIGHT]: playwright,
  [AUTOMATIONS.PLAYWRIGHT_TEST]: playwright,
  [AUTOMATIONS.PUPPETEER]: puppeteer,
  [AUTOMATIONS.WEBDRIVERIO]: webdriverio,
  [AUTOMATIONS.WEBDRIVERIOTESTRUNNER]: webdriverio,
  [AUTOMATIONS.WEBDRIVERJS]: webdriverjs
};

export interface ProjectInfo {
  automation: Automation;
  name: string;
}

export interface SeverityCounts {
  totalCurrent: number;
  totalLast: number;
  numNew: number;
  numFixed: number;
}

export type SeveritiesType = 'critical' | 'serious' | 'moderate' | 'minor';
export type AdditionalFiltersType = 'new_issues';

export type PageType = 'git' | 'gitless' | 'branch';

export type Impact = SeveritiesType | AdditionalFiltersType;
export type Impacts = ReadonlyArray<Impact>;
export const IMPACTS: Impacts = [
  'critical',
  'serious',
  'moderate',
  'minor',
  'new_issues'
];

/** FEATURE FLAGS */

export const AXE_WATCHER_PAID_PLANS_V1 = 'axe_devtools_watcher_paid_plans_v1';
