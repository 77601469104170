import React, { useState } from 'react';
import {
  Icon,
  OptionsMenu,
  OptionsMenuItem,
  OptionsMenuTrigger,
  Panel
} from '@deque/cauldron-react';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import analyticsInstances, {
  getFallbackAnalyticsInstanceId
} from '../../../../common/analyticsInstances';
import { SupportedIntegrationProductSlugs } from '../../../../common/constants';
import DeleteTemplateAlert from './DeleteTemplateAlert';
import styles from './TemplateCard.css';

export interface TemplateCardProps {
  templateId: string;
  templateName: string;
  project: string;
  issueType: string;
  updatedBy: string | null;
  updatedAt: string | null;
  instanceUrl: string;
  canEdit: boolean;
  integrationProductSlug: SupportedIntegrationProductSlugs;
}

function TemplateCard({
  templateId,
  templateName,
  project,
  issueType,
  updatedBy,
  updatedAt,
  instanceUrl,
  canEdit,
  integrationProductSlug
}: TemplateCardProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const analytics =
    analyticsInstances[getFallbackAnalyticsInstanceId(integrationProductSlug)];

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  return (
    <>
      <Panel className={styles.templateCard}>
        <div className={styles.templateCardContent}>
          <div>
            <h2>{templateName}</h2>
            <p>
              {project}: {issueType}
            </p>
            <p>{instanceUrl}</p>
          </div>
          <p className={styles.updateInfo}>
            {updatedAt && updatedBy && (
              <Trans>
                <span>{{ updatedBy }}</span> edited {{ updatedAt }}
              </Trans>
            )}
          </p>
        </div>
        {canEdit && (
          <div className={styles.actions}>
            <OptionsMenu
              className={styles.optionMenu}
              align="left"
              trigger={triggerProps => (
                <OptionsMenuTrigger {...triggerProps}>
                  <Icon type="kabob" label="Options" />
                </OptionsMenuTrigger>
              )}
            >
              {/* TODO: Add back in post-MVP
           <OptionsMenuItem
            onSelect={() => location('/configuration/integrations')}
          >
            {t('Preview template')}
          </OptionsMenuItem> */}
              <OptionsMenuItem
                onSelect={() => {
                  analytics.integrationTemplateEditClick({
                    integration: integrationProductSlug,
                    project,
                    issueType,
                    template: templateName
                  });
                  history.push(
                    `/configuration/integrations/${integrationProductSlug}/templates/${templateId}`
                  );
                }}
              >
                {t('Edit template')}
              </OptionsMenuItem>
              <OptionsMenuItem onSelect={() => setShowConfirmDelete(true)}>
                {t('Delete template')}
              </OptionsMenuItem>
            </OptionsMenu>
          </div>
        )}
      </Panel>
      {showConfirmDelete && (
        <DeleteTemplateAlert
          templateId={templateId}
          setShowConfirmDelete={setShowConfirmDelete}
          integrationProductSlug={integrationProductSlug}
          issueType={issueType}
          project={project}
          templateName={templateName}
        />
      )}
    </>
  );
}

export default TemplateCard;
