import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ApiKey } from '@deque/api-key-client';

import { createApiKey } from '../api-client';
import {
  SUPPORTED_TRIAL_PRODUCT_SLUGS,
  ProductSlugs,
  type SupportedTrialProductSlugs
} from '../constants';
import { useAuthContext } from '../contexts/auth';
import { useEnterprises } from '../contexts/enterprises';
import { useGlobalToast } from '../contexts/globalToast';
import { useProducts } from '../contexts/products';
import { useCrossProductAnalytics } from '../contexts/analytics';
import enterpriseBillingClient from '../utils/billing-client/enterprises/enterprises-v2';
import billingClient from '../utils/billing-client/me/me-v2';
import sendPlanChange from '../utils/send-plan-change-to-extension';
import { getTrialEligibility } from '@deque/billing-utils';

export interface UseTrialActivateProps {
  productSlug: SupportedTrialProductSlugs;
}

export interface UseTrialActivateState {
  apiKey: ApiKey | null;
  loading: boolean;
  error: Error | null;
}

export const useTrialActivate = ({
  productSlug
}: UseTrialActivateProps): UseTrialActivateState => {
  const { t } = useTranslation();
  const {
    activeEnterprise,
    loading: enterpriseLoading,
    isAdmin
  } = useEnterprises();
  const { user, billingUser, updateBillingUser } = useAuthContext();
  const { products, getProductBySlug } = useProducts();
  const { setContents } = useGlobalToast();
  const analytics = useCrossProductAnalytics(productSlug);
  const [apiKey, setApiKey] = useState<ApiKey | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const activateTrial = async () => {
      if (
        !loading ||
        enterpriseLoading ||
        !billingUser ||
        !user ||
        !products.length
      ) {
        return;
      }

      try {
        if (!SUPPORTED_TRIAL_PRODUCT_SLUGS.includes(productSlug)) {
          throw new Error(
            t('{{productSlug}} is not supported', { productSlug })
          );
        }

        if (activeEnterprise && !isAdmin) {
          throw new Error(
            t(
              'Contact your administrator to start a trial for your organization.'
            )
          );
        }

        const product = getProductBySlug(productSlug);

        if (!product) {
          throw new Error(
            t('{{productSlug}} product is not found', { productSlug })
          );
        }

        const { canStartTrial, isRestart } = getTrialEligibility({
          user: billingUser,
          product,
          enterprise: activeEnterprise ? activeEnterprise : undefined,
          isEnterpriseAdmin: isAdmin
        });
        if (!canStartTrial) {
          throw new Error(t('You can only create one subscription.'));
        }

        if (activeEnterprise) {
          await enterpriseBillingClient.createTrialSubscription(
            product.id,
            activeEnterprise.id,
            user.token
          );
        } else {
          await billingClient.createTrialSubscription(product.id, user.token);
        }

        analytics.trialStart(isRestart);

        if (productSlug === ProductSlugs.axeDevToolsExtension) {
          sendPlanChange();
        }

        if (product.api_key_generation === null) {
          setContents(
            t(`Success! {{ productName }} trial started.`, {
              productName: product.name
            }),
            'confirmation'
          );
        } else {
          const newApiKey = await createApiKey(user.token, {
            product_slug: productSlug,
            name: t(`{{productName}} {{subscriptionType}} Trial API Key`, {
              productName: product.name,
              subscriptionType: activeEnterprise ? t('Enterprise') : t('User')
            }),
            tags: []
          });

          navigator.clipboard.writeText(newApiKey.api_key);
          setContents(
            <Trans>
              Success! {{ productName: product.name }} trial started. Your API
              key has been copied to the clipboard and can also be accessed via
              the <Link to="/settings">settings page</Link>.
            </Trans>,
            'confirmation'
          );

          setApiKey(newApiKey);
        }

        setError(null);
      } catch (e) {
        setError(e as Error);
        setContents((e as Error).message, 'caution');
      } finally {
        setLoading(false);
      }
    };

    activateTrial();
  }, [
    productSlug,
    loading,
    enterpriseLoading,
    activeEnterprise,
    billingUser,
    user,
    isAdmin,
    products
  ]);

  useEffect(() => {
    if (!loading && !error) {
      updateBillingUser();
    }
  }, [loading, error]);

  return { apiKey, loading, error };
};
