import type { SettingsSchemaGeneralAxeCoreVersionValueEnum as AxeCoreVersion } from '@deque/orgwide-settings-client';

// This file was largely taken from https://github.com/dequelabs/axe-extension/blob/develop/src/shared/rulesets.ts
// TODO: Publish this in a library with https://github.com/dequelabs/walnut/issues/5220
export const standards = [
  'all',
  'wcag22aaa',
  'wcag22aa',
  'wcag22a',
  'wcag21aaa',
  'wcag21aa',
  'wcag21a',
  'wcag2aaa',
  'wcag2aa',
  'wcag2a',
  'TTv5'
] as const;

export const MINIMUM_TT_V5_AXE_CORE_VERSION: AxeCoreVersion = '4.7.1';

export type Standard = (typeof standards)[number];

export const recommendedStandard: Standard = 'wcag21aa';

interface Ruleset {
  name: string;
  tags: Standard[];
}

type Rulesets = { [key in Standard]: Ruleset };

const rulesets: Rulesets = {
  all: {
    name: 'All',
    tags: [
      'wcag22aaa',
      'wcag22aa',
      'wcag22a',
      'wcag21aaa',
      'wcag21aa',
      'wcag21a',
      'wcag2aaa',
      'wcag2aa',
      'wcag2a',
      'TTv5'
    ]
  },
  wcag22aaa: {
    name: 'WCAG 2.2 AAA',
    tags: [
      'wcag22aaa',
      'wcag22aa',
      'wcag22a',
      'wcag21aaa',
      'wcag21aa',
      'wcag21a',
      'wcag2aaa',
      'wcag2aa',
      'wcag2a'
    ]
  },
  wcag22aa: {
    name: 'WCAG 2.2 AA',
    tags: ['wcag22aa', 'wcag22a', 'wcag21aa', 'wcag21a', 'wcag2aa', 'wcag2a']
  },
  wcag22a: {
    name: 'WCAG 2.2 A',
    tags: ['wcag22a', 'wcag21a', 'wcag2a']
  },
  wcag21aaa: {
    name: 'WCAG 2.1 AAA',
    tags: ['wcag21aaa', 'wcag21aa', 'wcag21a', 'wcag2aaa', 'wcag2aa', 'wcag2a']
  },
  wcag21aa: {
    name: 'WCAG 2.1 AA',
    tags: ['wcag21aa', 'wcag21a', 'wcag2aa', 'wcag2a']
  },
  wcag21a: {
    name: 'WCAG 2.1 A',
    tags: ['wcag21a', 'wcag2a']
  },
  wcag2aaa: {
    name: 'WCAG 2.0 AAA',
    tags: ['wcag2aaa', 'wcag2aa', 'wcag2a']
  },
  wcag2aa: {
    name: 'WCAG 2.0 AA',
    tags: ['wcag2aa', 'wcag2a']
  },
  wcag2a: {
    name: 'WCAG 2.0 A',
    tags: ['wcag2a']
  },
  TTv5: {
    name: 'Trusted Tester v5',
    tags: ['TTv5']
  }
};

export default rulesets;
