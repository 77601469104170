import React, { ReactElement } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import '@deque/cauldron-styles/dist/index.css';
import '@deque/cauldron-react/lib/cauldron.css';
import 'typeface-roboto/index.css';

import { AuthProvider } from '../common/contexts/auth';
import { EnterprisesProvider } from '../common/contexts/enterprises';
import { Provider as FeatureFlagProvider } from '../common/contexts/featureFlags';
import { Provider as OnPremProvider } from '../common/contexts/onPrem';
import { Provider as ServerInfoProvider } from '../common/contexts/serverInfo';
import { ProductsProvider } from '../common/contexts/products';
import { GlobalToastProvider } from '../common/contexts/globalToast';
import { PRODUCT_URLS, ProductSlugs } from '../common/constants';
import FeatureRequired from '../common/components/FeatureRequired';
import NotOnPrem from '../common/components/NotOnPrem';
import Loadable from '../common/components/Loadable';
import Protected from '../common/components/Protected';
import AxeProductTrial from '../common/components/AxeProductTrial';
import type { SupportedTrialProductSlugs } from '../common/constants';
import { AnalyticsProvider } from '../common/contexts/analytics';
import analyticsInstances from '../common/analyticsInstances';

import AppLayout from './components/AppLayout';
import AppRoutes from './components/AppRoutes';
import StripeElements from './components/StripeElements';
import Maintenance from './components/Maintenance';
import { ConfigurationProvider } from '../common/contexts/Configuration';
import { IntegrationsProvider } from '../common/contexts/Integrations';
import { IntegrationProjectsProvider } from './contexts/IntegrationProjects';

const AxeDevTools = React.lazy(
  () =>
    import(/* webpackChunkName: "AxeDevTools" */ '../axe-devtools/AxeDevTools')
);

const AxeDevToolsMobile = React.lazy(
  () =>
    import(
      /* webpackChunkName: "AxeDevToolsMobile" */ '../axe-devtools-mobile/AxeDevToolsMobile'
    )
);

const AxeWatcher = React.lazy(
  () => import(/* webpackChunkName: "AxeWatcher" */ '../axe-watcher/AxeWatcher')
);

const AxeAuditor = React.lazy(
  () => import(/* webpackChunkName: "AxeAuditor" */ '../axe-auditor/AxeAuditor')
);

const AxeSpider = React.lazy(
  () => import(/* webpackChunkName: "AxeSpider" */ '../axe-spider/AxeSpider')
);

const createProductTrialPage = (
  productSlug: SupportedTrialProductSlugs,
  featureFlagId?: string
): React.FC => {
  const ProductTrialPage: React.FC = () => (
    <NotOnPrem>
      <Protected>
        {featureFlagId ? (
          <FeatureRequired featureFlagId={featureFlagId}>
            <AxeProductTrial productSlug={productSlug} />
          </FeatureRequired>
        ) : (
          <AxeProductTrial productSlug={productSlug} />
        )}
      </Protected>
    </NotOnPrem>
  );

  return ProductTrialPage;
};

interface AppProps {
  isOnPrem: boolean;
  stripePublishableKey?: string;
  paragonProjectId?: string;
}

const App = ({
  isOnPrem,
  stripePublishableKey,
  paragonProjectId
}: AppProps): ReactElement => (
  <GlobalToastProvider>
    <Maintenance>
      <OnPremProvider isOnPrem={isOnPrem}>
        <AuthProvider isOnPrem={isOnPrem}>
          <ServerInfoProvider>
            <AnalyticsProvider analytics={analyticsInstances}>
              <FeatureFlagProvider>
                <EnterprisesProvider>
                  <ProductsProvider>
                    <ConfigurationProvider>
                      <IntegrationsProvider paragonProjectId={paragonProjectId}>
                        <IntegrationProjectsProvider>
                          <StripeElements
                            stripePublishableKey={stripePublishableKey}
                          >
                            <Router>
                              <Switch>
                                <Route
                                  exact
                                  path={
                                    PRODUCT_URLS[ProductSlugs.axeLinter].trial
                                  }
                                  component={createProductTrialPage(
                                    ProductSlugs.axeLinter,
                                    'axe_linter_online_try'
                                  )}
                                />
                                <Route
                                  exact
                                  path={
                                    PRODUCT_URLS[
                                      ProductSlugs.axeDevToolsExtension
                                    ].trial
                                  }
                                  component={createProductTrialPage(
                                    ProductSlugs.axeDevToolsExtension
                                  )}
                                />
                                <Route
                                  exact
                                  path={
                                    PRODUCT_URLS[ProductSlugs.axeSpider].trial
                                  }
                                  component={createProductTrialPage(
                                    ProductSlugs.axeSpider,
                                    'axe_spider_v1'
                                  )}
                                />
                                <Route
                                  path={
                                    PRODUCT_URLS[
                                      ProductSlugs.axeDevToolsExtension
                                    ].root
                                  }
                                >
                                  <Loadable>
                                    <AxeDevTools />
                                  </Loadable>
                                </Route>
                                <Route
                                  path={
                                    PRODUCT_URLS[
                                      ProductSlugs.axeDevToolsWatcher
                                    ].root
                                  }
                                >
                                  <NotOnPrem>
                                    <FeatureRequired featureFlagId="axe_devtools_watcher_v1">
                                      <Loadable>
                                        <AxeWatcher />
                                      </Loadable>
                                    </FeatureRequired>
                                  </NotOnPrem>
                                </Route>
                                <Route
                                  path={
                                    PRODUCT_URLS[ProductSlugs.axeDevToolsMobile]
                                      .root
                                  }
                                >
                                  <NotOnPrem>
                                    <FeatureRequired featureFlagId="mobile_free_trial">
                                      <Loadable>
                                        <AxeDevToolsMobile />
                                      </Loadable>
                                    </FeatureRequired>
                                  </NotOnPrem>
                                </Route>
                                <Route
                                  path={
                                    PRODUCT_URLS[ProductSlugs.axeAuditor].root
                                  }
                                >
                                  <NotOnPrem>
                                    <FeatureRequired featureFlagId="axe_auditor_v3">
                                      <Loadable>
                                        <AxeAuditor />
                                      </Loadable>
                                    </FeatureRequired>
                                  </NotOnPrem>
                                </Route>
                                <Route
                                  path={
                                    PRODUCT_URLS[ProductSlugs.axeSpider].root
                                  }
                                >
                                  <FeatureRequired featureFlagId="axe_spider_v1">
                                    <Loadable>
                                      <AxeSpider />
                                    </Loadable>
                                  </FeatureRequired>
                                </Route>
                                <Route>
                                  <AppLayout>
                                    <AppRoutes />
                                  </AppLayout>
                                </Route>
                              </Switch>
                            </Router>
                          </StripeElements>
                        </IntegrationProjectsProvider>
                      </IntegrationsProvider>
                    </ConfigurationProvider>
                  </ProductsProvider>
                </EnterprisesProvider>
              </FeatureFlagProvider>
            </AnalyticsProvider>
          </ServerInfoProvider>
        </AuthProvider>
      </OnPremProvider>
    </Maintenance>
  </GlobalToastProvider>
);

export default App;
