import React, { useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { useGlobalToast } from '../contexts/globalToast';
import { ProductSlugs } from '@deque/billing-utils';
import styles from './usePurchaseNotification.css';
import { useTranslation } from 'react-i18next';

export const PURCHASE_QUERY_NAME = 'purchased';

const usePurchaseNotification = () => {
  const { setContents } = useGlobalToast();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const purchasedParam = searchParams.get(PURCHASE_QUERY_NAME);
    if (purchasedParam) {
      // we navigate from a purchase page to this page using a browser redirect and that means that app is reloaded
      // put here any logic to setup any state, such as Toast messages
      switch (purchasedParam) {
        case ProductSlugs.dequeUniversity:
          // Set toast for Deque University
          setContents(
            <p className={styles.toast_message}>
              {t(
                "Deque University order processes in 1 business day (Monday - Friday, 9AM-5PM EST). You'll get an email when courses are ready. For multiple users, email a list of full names & email addresses to "
              )}
              <a href="mailto:training@deque.com">training@deque.com</a>
            </p>,
            'info'
          );
          break;
        default:
      }

      // React redirect to the same URL but without purchased param
      searchParams.delete(PURCHASE_QUERY_NAME);
      history.push(`${location.pathname}?${searchParams.toString()}`);
    }
  }, []);

  return new URLSearchParams(location.search);
};

export default usePurchaseNotification;
