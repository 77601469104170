import React, { useEffect, useMemo, useState } from 'react';
import {
  ClientApi,
  Configuration,
  type PartialSettingsSchema,
  type SettingsSchema
} from '@deque/orgwide-settings-client';

import type { ErrorOrNull, WithRequired } from '../../../typings/utils';
import { useEnterprises } from '../../common/contexts/enterprises';
import { useAuthContext } from '../../common/contexts/auth';
import { useFeatureFlagLoading } from '../../common/contexts/featureFlags';

type UpdateSettings = (updates: PartialSettingsSchema) => Promise<void>;

const defaultFunction = () => {
  throw new Error('ConfigurationProvider not initialized');
};

export type AllSettingsSchema = WithRequired<
  SettingsSchema,
  'general' | 'axeDevtoolsPro' | 'axeDevtoolsWatcher'
>;

export interface State {
  loading: boolean;
  loadingError: ErrorOrNull;
  saving: boolean;
  savingError: ErrorOrNull;
  clearSavingError: () => void;
  isEnterpriseMember: boolean;
  isEnterpriseAdmin: boolean;
  // User settings if they are a user or enterprise settings if they are an enterprise admin.
  settings: AllSettingsSchema | null;
  // Update user settings if they are a user or enterprise settings if they are an enterprise admin.
  updateSettings: UpdateSettings;
}

export type LoadedState = State & {
  loading: false;
  loadingError: null;
  settings: AllSettingsSchema;
};

export interface ConfigurationProviderProps {
  children: React.ReactNode;
  // Note: only for testing purposes
  initialUserSettings?: AllSettingsSchema;
  initialEnterpriseSettings?: AllSettingsSchema;
  initialLoading?: boolean;
  initialLoadingError?: ErrorOrNull;
  initialSaving?: boolean;
  initialSavingError?: ErrorOrNull;
}

export const defaultState = {
  loading: false,
  loadingError: null,
  saving: false,
  savingError: null,
  isEnterpriseMember: false,
  isEnterpriseAdmin: false,
  settings: null,
  clearSavingError: defaultFunction,
  updateSettings: defaultFunction
};

const context = React.createContext<State>(defaultState);

const toError = (error: string | ErrorOrNull): ErrorOrNull => {
  if (!error) {
    return null;
  }

  if (typeof error === 'string') {
    return new Error(error);
  }

  return error;
};

export const ConfigurationProvider: React.FC<ConfigurationProviderProps> = ({
  children,
  initialUserSettings = null,
  initialEnterpriseSettings = null,
  initialLoading = true,
  initialLoadingError = null,
  initialSaving = false,
  initialSavingError = null
}) => {
  const { user, loading: authLoading, error: authError } = useAuthContext();
  const {
    isAdmin,
    activeEnterprise,
    loading: loadingEnterprises,
    error: enterprisesError
  } = useEnterprises();
  const [loading, setLoading] = useState(initialLoading);
  const [loadingError, setLoadingError] =
    useState<ErrorOrNull>(initialLoadingError);
  const [saving, setSaving] = useState(initialSaving);
  const [savingError, setSavingError] =
    useState<ErrorOrNull>(initialSavingError);
  const [userSettings, setUserSettings] = useState<AllSettingsSchema | null>(
    initialUserSettings
  );
  const [enterpriseSettings, setEnterpriseSettings] =
    useState<AllSettingsSchema | null>(initialEnterpriseSettings);
  const isFeatureFlagLoading = useFeatureFlagLoading();

  const settingsClient = useMemo(() => {
    if (authLoading || loadingEnterprises || isFeatureFlagLoading) {
      return null;
    }

    if (!user?.token) {
      setLoading(false);
      return null;
    }

    // For testing purposes only, do not try and initialize the client
    // if we have initial settings.
    if (initialUserSettings || initialEnterpriseSettings) {
      setLoading(initialLoading);
      return null;
    }

    return new ClientApi(
      new Configuration({
        basePath: window.location.origin,
        accessToken: user.token
      })
    );
  }, [user?.token, authLoading, loadingEnterprises, isFeatureFlagLoading]);

  useEffect(() => {
    (async () => {
      if (!settingsClient) {
        return;
      }

      try {
        setLoadingError(null);
        setLoading(true);

        let allUserSettings: AllSettingsSchema | null = null;
        let allEnterpriseSettings: AllSettingsSchema | null = null;
        if (activeEnterprise) {
          [allUserSettings, allEnterpriseSettings] = await Promise.all([
            settingsClient.getEnterpriseUserSettings({
              enterpriseId: activeEnterprise.id
            }) as Promise<AllSettingsSchema>,
            settingsClient.getEnterpriseSettings({
              enterpriseId: activeEnterprise.id
            }) as Promise<AllSettingsSchema>
          ]);
        } else {
          allUserSettings =
            (await settingsClient.getUserSettings()) as AllSettingsSchema;
        }

        setUserSettings(allUserSettings);
        setEnterpriseSettings(allEnterpriseSettings);
      } catch (err) {
        setLoadingError(err as Error);
      } finally {
        setLoading(false);
      }
    })();

    /**
     * When the user joins an enterprise via invitation `updateBillingUser()` is called
     * which will update the `activeEnterprise` in the `enterprises` context.
     * Watch for changes to the active enterprise and re-fetch settings.
     */
  }, [settingsClient, activeEnterprise]);

  const updateUserSettings: UpdateSettings = async (
    updates: PartialSettingsSchema
  ) => {
    if (!settingsClient) {
      throw new Error('Settings client not initialized');
    }

    try {
      setSavingError(null);
      setSaving(true);

      const updatedSettings = (await settingsClient.patchUserSettings({
        partialSettingsSchema: updates
      })) as AllSettingsSchema;

      setUserSettings(updatedSettings);
    } catch (err) {
      setSavingError(err as Error);
    } finally {
      setSaving(false);
    }
  };

  const updateEnterpriseSettings: UpdateSettings = async (
    updates: PartialSettingsSchema
  ) => {
    if (!settingsClient) {
      throw new Error('Settings client not initialized');
    }

    if (!activeEnterprise) {
      throw new Error('No active enterprise');
    }

    try {
      setSavingError(null);
      setSaving(true);

      const updatedSettings = (await settingsClient.patchEnterpriseSettings({
        enterpriseId: activeEnterprise.id,
        partialSettingsSchema: updates
      })) as AllSettingsSchema;

      setEnterpriseSettings(updatedSettings);
    } catch (err) {
      setSavingError(err as Error);
    } finally {
      setSaving(false);
    }
  };

  const clearSavingError = () => {
    setSavingError(null);
  };

  return (
    <context.Provider
      value={{
        loading: authLoading || loading || loadingEnterprises,
        loadingError:
          toError(authError) || toError(enterprisesError) || loadingError,
        saving,
        savingError,
        clearSavingError,
        settings: isAdmin ? enterpriseSettings : userSettings,
        updateSettings: isAdmin ? updateEnterpriseSettings : updateUserSettings,
        isEnterpriseMember: !!activeEnterprise,
        isEnterpriseAdmin: isAdmin
      }}
    >
      {children}
    </context.Provider>
  );
};

export const useConfiguration = (): State => React.useContext(context);

export const useLoadedConfiguration = (): LoadedState => {
  const state = useConfiguration();

  if (state.loading || !state.settings) {
    throw new Error('Configuration not loaded');
  }

  return state as LoadedState;
};
