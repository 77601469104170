import { useCallback, useMemo } from 'react';
import useSWR from 'swr';

import type { ErrorOrNull } from '../../../typings/utils';
import { useIntegrations } from '../../common/contexts/Integrations';
import { useIntegrationProjects } from '../contexts/IntegrationProjects';
import getIssueTypeFields from '../../common/utils/integrations-client/jira/getIssueTypeFieldsLegacy';

export interface Field {
  name: string;
  id: string;
  required: boolean;
}

export interface AllowedValue {
  id: string;
  name: string;
}

export interface Priorities extends Field {
  allowedValues: AllowedValue[];
}

export interface IssueTypeFieldsProps {
  issueTypeId: string;
}

export interface IssueTypeFieldsState {
  fields: Field[];
  priorities: Priorities | null;
  loading: boolean;
  error: ErrorOrNull;
  getPriorityById: (id: string) => AllowedValue | null;
}

const useIssueTypeFields = ({
  issueTypeId
}: IssueTypeFieldsProps): IssueTypeFieldsState => {
  const { getToken } = useIntegrations();
  const { projects } = useIntegrationProjects();

  const projectId = useMemo(() => {
    if (!projects) {
      return;
    }
    return projects.find(project =>
      project.issueTypes?.find(issueType => issueType.id === issueTypeId)
    )?.id;
  }, [projects, issueTypeId]);

  const {
    data: issueTypeFieldsData,
    error: issueTypeFieldsError,
    isLoading: issueTypeFieldsLoading
  } = useSWR(
    projectId && issueTypeId && `issueTypeFields:${issueTypeId}`,
    () =>
      getIssueTypeFields({
        projectIdOrKey: projectId as string,
        issueTypeId,
        getToken
      }),
    {
      revalidateOnFocus: false
    }
  );

  const fields = useMemo(() => {
    return (
      issueTypeFieldsData?.fields.map(field => ({
        name: field.name,
        id: field.fieldId,
        required: field.required
      })) || []
    );
  }, [issueTypeFieldsData]);

  const priorities = useMemo(() => {
    return (
      issueTypeFieldsData?.fields
        .filter(field => field.key === 'priority')
        .map(field => ({
          name: field.name,
          id: field.fieldId,
          required: field.required,
          allowedValues: field.allowedValues || []
        }))[0] || null
    );
  }, [issueTypeFieldsData]);

  const getPriorityById = useCallback(
    (priorityValueId: string) =>
      priorities?.allowedValues.find(({ id }) => id === priorityValueId) ||
      null,
    [priorities]
  );

  const state = useMemo(
    () => ({
      fields,
      priorities,
      loading: issueTypeFieldsLoading,
      error: issueTypeFieldsError,
      getPriorityById
    }),
    [
      fields,
      priorities,
      issueTypeFieldsLoading,
      issueTypeFieldsError,
      getPriorityById
    ]
  );

  return state;
};

export default useIssueTypeFields;
