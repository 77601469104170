import { Button } from '@deque/cauldron-react';
import React, { useState } from 'react';
import { Toast } from '@deque/cauldron-react';
import { SupportedIntegrationProductSlugs } from '../../../../common/constants';
import analyticsInstances, {
  getFallbackAnalyticsInstanceId
} from '../../../../common/analyticsInstances';
import Link from '../../../../common/components/Link';
import { t } from '../../../../common/utils/testUtils';
import { IntegrationProductInfo } from '../../../../common/utils/get-integration-product-name-from-slug';
import styles from './ConnectionButtonsLine.css';
import { getIntegrationToken } from '../../../../common/api-client';
import { AuthUser } from '../../../../common/contexts/auth';
import { v2 } from '@deque/billing-service-client';
import { IntegrationConnection } from '../../../../common/utils/integrations-client/jira';
import {
  type AuthenticatedConnectUser,
  paragon as integrationsClient
} from '@useparagon/connect';
import {
  createIntegrationConnection,
  deleteIntegrationConnection,
  disconnectIntegrationConnection,
  reconnectIntegrationConnection
} from '../../../../common/utils/integrations-client/jira/connections';
import { useGlobalToast } from '../../../../common/contexts/globalToast';
import ScrimmedLoader from '../../../../common/components/ScrimmedLoader';

const { PARAGON_PROJECT_ID } = process.env;

type ParagonActionFunction = {
  action(): Promise<void>;
  handleSuccess(): Promise<void>;
  handleFailure(): Promise<void>;
  errorMessage: string;
  setProcessingFlag: boolean;
};

interface ConnectionButtonsProps {
  connection: IntegrationConnection | null;
  integrationProductInfo: IntegrationProductInfo;
  enterprise: v2.Enterprise;
  canManageIntegrations: boolean;
  authUser: AuthUser;
  connectionsUpdated: () => void;
}

export function ConnectionButtons({
  connection,
  integrationProductInfo,
  enterprise,
  canManageIntegrations,
  authUser,
  connectionsUpdated
}: ConnectionButtonsProps) {
  const analytics =
    analyticsInstances[
      getFallbackAnalyticsInstanceId(
        integrationProductInfo.integrationProductSlug
      )
    ];
  const { setContents } = useGlobalToast();
  const [error, setError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const paragonActionWrapper = async (
    action: ParagonActionFunction
  ): Promise<void> => {
    try {
      if (action.setProcessingFlag) {
        setIsProcessing(true);
      }

      const { token: integrationToken } = await getIntegrationToken(
        authUser.token,
        enterprise.id
      );

      await integrationsClient.authenticate(
        PARAGON_PROJECT_ID || '',
        integrationToken
      );
      await action.action();
      await action.handleSuccess();

      connectionsUpdated();
    } catch (exception) {
      // eslint-disable-next-line no-console
      console.error(exception);

      setError(action.errorMessage);

      await action.handleFailure();
    } finally {
      setIsProcessing(false);
    }
  };

  const connect = async (): Promise<void> => {
    await paragonActionWrapper({
      action: async () => {
        // Get connections as of right now
        const beforeConnectUserInfo =
          integrationsClient.getUser() as AuthenticatedConnectUser;
        let beforeConnectCredentials =
          beforeConnectUserInfo.integrations[
            integrationProductInfo.paragonIntegrationName
          ]?.allCredentials;

        // Make a copy of the array of initial credentials, since Paragon replaces the content with newer values after connecting
        if (beforeConnectCredentials) {
          beforeConnectCredentials = [...beforeConnectCredentials];
        }

        // Establish a new connections (this may never return if user cancels connection)
        await integrationsClient.installIntegration(
          integrationProductInfo.paragonIntegrationName,
          {
            allowMultipleCredentials: true
          }
        );

        // Get connections now
        const afterConnectUserInfo =
          integrationsClient.getUser() as AuthenticatedConnectUser;
        const afterConnectCredentials =
          afterConnectUserInfo.integrations[
            integrationProductInfo.paragonIntegrationName
          ]?.allCredentials;

        // Find connections that was added
        if (
          beforeConnectCredentials &&
          afterConnectCredentials &&
          afterConnectCredentials.length > 0
        ) {
          const addedIntegrationCredentials = afterConnectCredentials.filter(
            afterConnectCredential => {
              const thisConnectionWasInOriginalSet =
                beforeConnectCredentials &&
                beforeConnectCredentials.some(beforeConnectCredential => {
                  return (
                    afterConnectCredential.id === beforeConnectCredential.id
                  );
                });

              return !thisConnectionWasInOriginalSet;
            }
          );

          if (addedIntegrationCredentials.length < 1) {
            throw new Error(t('Unable to find added integration'));
          }

          if (addedIntegrationCredentials.length > 1) {
            // if there are more then 1 new connection, log but continue picking one of the connection as the one that was added
            // eslint-disable-next-line no-console
            console.error(
              `Found ${addedIntegrationCredentials.length} new integrations while expecting only one.`
            );
          }

          const addedIntegrationCredential = addedIntegrationCredentials[0];
          const baseUrl = addedIntegrationCredential.providerData
            .instanceUrl as string;

          if (connection) {
            // We have an existing connection on the server side, update it
            await reconnectIntegrationConnection({
              enterpriseId: enterprise.id,
              token: authUser.token,
              connectionId: connection.id,
              body: {
                credential_id: addedIntegrationCredential.id,
                base_url: baseUrl
              }
            });
          } else {
            // This is a brand new connection, add it on the server side
            await createIntegrationConnection({
              enterpriseId: enterprise.id,
              token: authUser.token,
              body: {
                product_slug:
                  integrationProductInfo.integrationProductSlug as unknown as SupportedIntegrationProductSlugs,
                credential_id: addedIntegrationCredential.id,
                base_url: baseUrl
              }
            });
          }
        } else {
          throw new Error(
            t('Unexpected error while connecting to an integration')
          );
        }
      },
      handleSuccess: async () => {
        setContents(
          t('Successfully connected to {{integrationType}}', {
            integrationType: integrationProductInfo.displayName
          }),
          'confirmation'
        );

        await analytics.integrationConnect({
          integration:
            integrationProductInfo.integrationProductSlug as unknown as SupportedIntegrationProductSlugs
        });
      },
      handleFailure: async () => {
        await analytics.integrationConnectFail({
          integration:
            integrationProductInfo.integrationProductSlug as unknown as SupportedIntegrationProductSlugs
        });
      },
      errorMessage: t(
        'There was an error connecting to {{ displayName }}. Please try again.',
        {
          displayName: integrationProductInfo.displayName
        }
      ),
      // We will not keep track of a state when we are connecting (which is used to disable buttons).
      // This is because Paragon might never return from a method to connect (specifically this happens when user cancels the connection)
      setProcessingFlag: false
    });
  };

  const disconnect = async (): Promise<void> => {
    await paragonActionWrapper({
      action: async () => {
        if (connection) {
          // Disconnect
          await integrationsClient.uninstallIntegration(
            integrationProductInfo.paragonIntegrationName,
            {
              selectedCredentialId: connection.credential_id
            }
          );

          // Update server side connection
          await disconnectIntegrationConnection({
            enterpriseId: enterprise.id,
            token: authUser.token,
            connectionId: connection.id
          });
        }
      },
      handleSuccess: async () => {
        setContents(
          t('Successfully disconnected from {{integrationType}}', {
            integrationType: integrationProductInfo.displayName
          }),
          'confirmation'
        );

        await analytics.integrationDisconnect({
          integration:
            integrationProductInfo.integrationProductSlug as unknown as SupportedIntegrationProductSlugs
        });
      },
      handleFailure: async () => {
        await analytics.integrationDisconnectFail({
          integration:
            integrationProductInfo.integrationProductSlug as unknown as SupportedIntegrationProductSlugs
        });
      },
      errorMessage: t(
        'There was an error disconnecting from {{ displayName }}. Please try again.',
        {
          displayName: integrationProductInfo.displayName
        }
      ),
      setProcessingFlag: true
    });
  };

  const deleteConnection = async (): Promise<void> => {
    try {
      setIsProcessing(true);

      // Delete connection from a server
      if (connection) {
        await deleteIntegrationConnection({
          enterpriseId: enterprise.id,
          token: authUser.token,
          connectionId: connection.id
        });
      }

      setContents(
        t('Successfully deleted connection to {{integrationType}}', {
          integrationType: integrationProductInfo.displayName
        }),
        'confirmation'
      );

      // Refresh list of connections
      connectionsUpdated();
    } catch (exception) {
      // eslint-disable-next-line no-console
      console.error(exception);

      setError(
        t(
          'There was an error deleting {{ displayName }} connection. Please try again.',
          {
            displayName: integrationProductInfo.displayName
          }
        )
      );
    } finally {
      setIsProcessing(false);
    }
  };

  if (isProcessing) {
    return <ScrimmedLoader />;
  }

  let errorToast = null;
  if (error) {
    errorToast = (
      <Toast show type="error" onDismiss={() => setError('')}>
        {error}
      </Toast>
    );
  }

  if (connection) {
    const isConnected = connection.status === 'connected';

    return (
      <div className={styles.installedGroup}>
        {errorToast}
        {!isConnected && canManageIntegrations && (
          <Button variant="primary" onClick={connect}>
            {t('Connect to {{displayName}}', {
              displayName: integrationProductInfo.displayName
            })}
          </Button>
        )}
        <Link
          variant="button-secondary"
          url={`/configuration/integrations/${connection.id}/templates`}
        >
          {t('Configure {{displayName}} Templates', {
            displayName: integrationProductInfo.displayName
          })}
        </Link>
        {isConnected && canManageIntegrations && (
          <Button variant="link" onClick={disconnect}>
            {t('Disconnect from {{displayName}}', {
              displayName: integrationProductInfo.displayName
            })}
          </Button>
        )}
        {!isConnected && canManageIntegrations && (
          <Button variant="link" onClick={deleteConnection}>
            {t('Delete {{displayName}} connection', {
              displayName: integrationProductInfo.displayName
            })}
          </Button>
        )}
      </div>
    );
  } else {
    if (canManageIntegrations) {
      return (
        <div className={styles.installedGroup}>
          {errorToast}
          <Button variant="primary" onClick={connect}>
            {t('Add new connection to {{displayName}}', {
              displayName: integrationProductInfo.displayName
            })}
          </Button>
        </div>
      );
    }
  }

  return null;
}
