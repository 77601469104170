import React, { type FC } from 'react';

import { useParams } from 'react-router-dom';
import { IntegrationTemplateWizardSteps } from '../../../contexts/IntegrationTemplateWizardSteps';
import { SupportedIntegrationProductSlugs } from '../../../../common/constants';
import useIntegrationTemplates from '../../../hooks/useIntegrationTemplates';
import TemplateWizardForm from './TemplateWizardForm/TemplateWizardForm';
import TemplateStepper from './TemplateStepper/TemplateStepper';
import NotFound from '../../../../common/pages/NotFound';

type QueryParamsType = {
  id: string;
};

type TemplateWizardProps = {
  isEditMode?: boolean;
  integrationProductSlug: SupportedIntegrationProductSlugs;
};

const TemplateWizard: FC<TemplateWizardProps> = ({
  integrationProductSlug,
  isEditMode = false
}) => {
  const { id } = useParams<QueryParamsType>();
  const { templates, loading } = useIntegrationTemplates({
    integrationProductSlug
  });

  const initialTemplate = templates.find(template => template.id === id);

  if (isEditMode && !initialTemplate && !loading) {
    return <NotFound />;
  }

  return (
    <IntegrationTemplateWizardSteps
      isEditMode={isEditMode}
      integrationProductSlug={integrationProductSlug}
    >
      <TemplateStepper />
      <TemplateWizardForm
        integrationProductSlug={integrationProductSlug}
        initialTemplate={initialTemplate}
      />
    </IntegrationTemplateWizardSteps>
  );
};

export default TemplateWizard;
