import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext
} from 'react';
import {
  type AuthenticatedConnectUser,
  type ConnectUser,
  type IntegrationInstallEvent,
  paragon as integrationsClient,
  IntegrationUninstallEvent,
  SDK_EVENT
} from '@useparagon/connect';
import { listUserProductAccess } from '@deque/billing-utils';
import { SettingsSchemaJiraIntegrationInstanceBaseUrlTypeEnum as SettingsBaseUrl } from '@deque/orgwide-settings-client';

import type { ErrorOrNull, OrNull } from '../../../typings/utils';
import type { IntegrationTypes } from '../utils/get-integration-product-name-from-slug';
import {
  INTEGRATION_PRODUCT_SLUGS,
  SupportedIntegrationProductSlugs
} from '../../common/constants';
import { useConfiguration } from './Configuration';
import { useAuthContext } from '../../common/contexts/auth';
import { getIntegrationToken } from '../../common/api-client';
import { useEnterprises } from '../../common/contexts/enterprises';
import { checkTokenValidity } from '../utils/check-token-validity';
import client from '../../common/utils/integrations-client/client';
import { useFeatureFlags } from '../../common/contexts/featureFlags';

export { integrationsClient };

/**
 * State stored within the context.
 */

export interface State {
  loading: boolean;
  error: ErrorOrNull;
  projectId: string;
  integrationUser: ConnectUser | AuthenticatedConnectUser | null;
  canAccessIntegrations: boolean;
  canManageIntegrations: boolean;
  getToken: () => Promise<string | undefined>;
  updatingToken: boolean;
  integrationSubscriptionSlugs: SupportedIntegrationProductSlugs[];
  connectedIntegrations: string[];
}

const Context = createContext<State>({
  loading: true,
  error: null,
  projectId: '',
  integrationUser: null,
  canAccessIntegrations: false,
  canManageIntegrations: false,
  getToken: () => Promise.resolve(''),
  updatingToken: false,
  integrationSubscriptionSlugs: [],
  connectedIntegrations: []
});

export interface IntegrationsProviderProps {
  initialIntegrationUser?: ConnectUser | null;
  initialIntegrationToken?: string;
  initialCanAccessIntegrations?: boolean;
  initialCanManageIntegrations?: boolean;
  initialUpdatingToken?: boolean;
  initialError?: ErrorOrNull;
  initialLoading?: boolean;
  initialTokenError?: ErrorOrNull;
  paragonProjectId?: string;
  children: React.ReactNode;
}

export const IntegrationsProvider: React.ComponentType<
  IntegrationsProviderProps
> = ({
  initialIntegrationUser = null,
  initialIntegrationToken = null,
  initialUpdatingToken = false,
  initialError = null,
  initialCanAccessIntegrations = false,
  initialCanManageIntegrations = false,
  initialLoading = false,
  initialTokenError = null,
  paragonProjectId = '',
  children
}) => {
  const { user: authUser, billingUser } = useAuthContext();
  const {
    activeEnterprise,
    loading: enterprisesLoading,
    error: enterprisesError,
    isAdmin
  } = useEnterprises();
  const {
    loading: areFeatureFlagsLoading,
    loadError: featureFlagsLoadError,
    featureFlags
  } = useFeatureFlags();
  const {
    updateSettings,
    settings,
    loading: configurationLoading,
    loadingError: configurationLoadingError,
    saving: configurationSaving,
    savingError: configurationSavingError
  } = useConfiguration();

  // This context's state
  const [integrationUser, setIntegrationUser] = useState<OrNull<ConnectUser>>(
    initialIntegrationUser
  );
  const [integrationToken, setIntegrationToken] = useState<OrNull<string>>(
    initialIntegrationToken
  );
  const [updatingToken, setUpdatingToken] = useState(initialUpdatingToken);
  const [tokenError, setTokenError] = useState<ErrorOrNull>(initialTokenError);

  // Business logic
  const hasIntegrationsV1 = featureFlags.some(
    flag => flag.id === 'integrations_v1' && flag.state === true
  );

  const loading = useMemo(() => {
    return (
      enterprisesLoading ||
      areFeatureFlagsLoading ||
      configurationLoading ||
      configurationSaving ||
      initialLoading
    );
  }, [
    enterprisesLoading,
    areFeatureFlagsLoading,
    configurationLoading,
    configurationSaving,
    initialLoading
  ]);

  const error = useMemo(() => {
    if (enterprisesError) {
      return typeof enterprisesError === 'string'
        ? new Error(enterprisesError)
        : enterprisesError;
    }
    return (
      featureFlagsLoadError ||
      configurationLoadingError ||
      configurationSavingError ||
      tokenError ||
      initialError
    );
  }, [
    enterprisesError,
    featureFlagsLoadError,
    configurationLoadingError,
    configurationSavingError
  ]);

  const integrationSubscriptionSlugs = useMemo(
    () =>
      hasIntegrationsV1 && activeEnterprise && billingUser && !loading && !error
        ? INTEGRATION_PRODUCT_SLUGS.filter(
            slug => listUserProductAccess(billingUser)[slug] === 'paid'
          )
        : [],
    [activeEnterprise, billingUser, hasIntegrationsV1, loading, error]
  );

  const canAccessIntegrations = useMemo(
    () =>
      initialCanAccessIntegrations || integrationSubscriptionSlugs.length > 0,
    [initialCanAccessIntegrations, integrationSubscriptionSlugs]
  );

  // TODO: this will need to be updated once support for product admins is added
  const canManageIntegrations = useMemo(
    () => initialCanManageIntegrations || (canAccessIntegrations && isAdmin),
    [initialCanManageIntegrations, canAccessIntegrations, isAdmin]
  );

  const connectedIntegrations = useMemo(
    () =>
      canAccessIntegrations &&
      integrationUser &&
      integrationUser.authenticated &&
      integrationUser.integrations
        ? Object.keys(integrationUser.integrations).filter(key => {
            const integration = integrationUser.integrations[key];
            return (
              integration?.enabled &&
              integration?.credentialStatus &&
              integration?.credentialStatus === 'VALID'
            );
          })
        : [],
    [canAccessIntegrations, integrationUser, integrationToken]
  );

  useEffect(() => {
    if (!canAccessIntegrations) {
      return;
    }

    if (
      !integrationToken ||
      (integrationToken && !checkTokenValidity(integrationToken))
    ) {
      getToken();
      return;
    }

    const listener = async (
      integrationType: string,
      eventType: 'install' | 'uninstall'
    ) => {
      const authedUser = integrationsClient.getUser();
      if (authedUser.authenticated && authedUser.integrations) {
        setIntegrationUser({ ...authedUser });

        if (eventType === 'install') {
          const response = await client[
            integrationType as IntegrationTypes
          ].getServerInfo({ getToken });

          // Update the instance base URL if it has changed or is not currently set
          if (
            response?.baseUrl &&
            response.baseUrl !==
              settings?.jiraIntegration?.instanceBaseUrl.value
          ) {
            await updateSettings({
              jiraIntegration: {
                instanceBaseUrl: {
                  value: response.baseUrl,
                  type: SettingsBaseUrl.Fixed
                }
              }
            });
          }
        }
      }
    };

    integrationsClient.subscribe(
      SDK_EVENT.ON_INTEGRATION_INSTALL,
      (e: IntegrationInstallEvent) => listener(e.integrationType, 'install')
    );
    integrationsClient.subscribe(
      SDK_EVENT.ON_INTEGRATION_UNINSTALL,
      (e: IntegrationUninstallEvent) => listener(e.integrationType, 'uninstall')
    );

    return () => {
      integrationsClient.unsubscribe(
        SDK_EVENT.ON_INTEGRATION_INSTALL,
        (e: IntegrationInstallEvent) => listener(e.integrationType, 'uninstall')
      );
      integrationsClient.unsubscribe(
        SDK_EVENT.ON_INTEGRATION_UNINSTALL,
        (e: IntegrationUninstallEvent) =>
          listener(e.integrationType, 'uninstall')
      );
    };
  }, [integrationsClient, canAccessIntegrations, integrationToken]);

  const getToken = useCallback(async () => {
    try {
      // setUpdatingToken(true); // ToDo: this is a temporary fix
      if (!canAccessIntegrations || loading) {
        return;
      }

      if (integrationToken && checkTokenValidity(integrationToken)) {
        return integrationToken;
      }

      if (activeEnterprise && authUser?.token) {
        try {
          const { token: signedJwt } = await getIntegrationToken(
            authUser.token,
            activeEnterprise.id
          );
          setIntegrationToken(signedJwt);
          await integrationsClient.authenticate(paragonProjectId, signedJwt);
          const authenticatedUser = integrationsClient.getUser();
          setIntegrationUser(
            initialIntegrationUser
              ? { ...initialIntegrationUser }
              : { ...authenticatedUser }
          );
          return signedJwt;
        } catch (e) {
          setTokenError(e as Error);
        }
      }
    } finally {
      setUpdatingToken(initialUpdatingToken);
    }
    return;
  }, [
    canAccessIntegrations,
    integrationToken,
    activeEnterprise,
    authUser,
    paragonProjectId,
    loading
  ]);

  const state: State = useMemo(
    () => ({
      error,
      getToken,
      integrationUser,
      canAccessIntegrations,
      canManageIntegrations,
      updatingToken,
      loading,
      projectId: paragonProjectId,
      integrationSubscriptionSlugs,
      connectedIntegrations
    }),
    [
      error,
      getToken,
      updatingToken,
      loading,
      paragonProjectId,
      integrationUser,
      canAccessIntegrations,
      canManageIntegrations,
      integrationSubscriptionSlugs,
      connectedIntegrations
    ]
  );

  return <Context.Provider value={state}>{children}</Context.Provider>;
};

/**
 * Use the Integrations context.
 */

export const useIntegrations = (): State => useContext(Context);
