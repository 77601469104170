import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import type {
  loadStripe as loadStripeType,
  StripeElementsOptions
} from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { Loader } from '@deque/cauldron-react';
import { useServerInfo } from '../../common/contexts/serverInfo';

type StripeLoader = ReturnType<typeof loadStripeType> | null;

interface Props {
  children: React.ReactNode;
  stripePublishableKey?: string;
}

const options = {
  loader: 'never',
  appearance: {
    disableAnimations: true,
    theme: 'none',
    variables: {
      colorText: '#666666',
      colorDanger: '#d93251',
      colorPrimary: '#333333',
      colorIcon: 'var(--colorText)',
      colorIconCardError: 'var(--colorDanger)',
      colorIconCardCvc: 'var(--colorText)',
      fontSizeBase: '15px',
      borderRadius: '0',
      fontSmooth: 'never',
      fontWeightLight: '400',
      fontWeightNormal: '500'
    },
    rules: {
      '.Input': {
        border: '1px solid #ccc',
        borderColor: '#cccccc',
        borderBottomColor: '#4d4d4d',
        borderStyle: 'solid',
        borderLeftStyle: 'solid',
        margin: '0',
        marginBottom: '.25rem',
        padding: '8px',
        borderWidth: 'thin',
        transition: 'none',
        color: 'var(--colorText)'
      },
      '.Input:focus': {
        border: '1px solid #3c7aae',
        boxShadow:
          '0 0 0 2px #3c7aae, 0 0 5px rgba(60, 122, 174, 0.7), inset 0 1px 2px rgba(0, 0, 0, 0.05)',
        outline: '0'
      },
      '.Input::placeholder': {
        color: 'var(--colorText)',
        fontWeight: 'var(--fontWeightLight)'
      },
      '.Input--invalid': {
        border: '1px solid var(--colorDanger)'
      },
      '.Input:hover': {
        border: '1px solid var(--colorPrimary)'
      },
      '.Input--invalid::placeholder': {
        color: 'var(--colorDanger)'
      },
      '.Input--invalid:focus': {
        border: '1px solid var(--colorDanger)',
        boxShadow:
          '0 0 0 1px var(--colorDanger), inset 0 2px 3px 0 rgba(0, 0, 0, 0.05), 0 0 5px 0 rgba(217,50,81,0.7)',
        outline: '0'
      },
      '.Label': {
        fontWeight: 'var(--fontWeightNormal)',
        paddingTop: '4px',
        paddingBottom: '4px',
        fontSize: 'var(--fontSizeBase)',
        color: 'var(--colorPrimary)',
        marginBottom: '0',
        transition: 'none'
      },
      '.Label--invalid': {
        color: 'var(--colorPrimary)'
      },
      '.Error': {
        fontSize: '12px',
        fontWeight: 'var(--fontWeightLight)'
      }
    }
  }
} as StripeElementsOptions;
/**
 * Wrapper around the Stripe `<Elements/>` provider which is a noop when deployed on-prem.
 */

const StripeElements: React.ComponentType<Props> = ({
  children,
  stripePublishableKey
}) => {
  const { t } = useTranslation();
  const { serverInfo } = useServerInfo();
  const [loader, setLoader] = React.useState<StripeLoader>(null);
  const loaderRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    loaderRef.current?.focus();
  }, [loaderRef.current]);

  React.useEffect(() => {
    // When isSelfProvisioningEnabled is false, we never show Stripe elements, so don't need to load the Stripe SDK.
    if (!serverInfo?.isSelfProvisioningEnabled) {
      return;
    }

    // Without a Stripe key, we can't do anything.
    if (!stripePublishableKey) {
      throw new Error('No Stripe publishable key set');
    }

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { loadStripe } = require('@stripe/stripe-js');
    setLoader(loadStripe(stripePublishableKey));
  }, [serverInfo, stripePublishableKey]);

  // When isSelfProvisioningEnabled is false, immediately mount children.
  if (!serverInfo?.isSelfProvisioningEnabled) {
    return <>{children}</>;
  }

  // While waiting on the effect, show a loader.
  // We can't mount the children, as they may be relying on a Stripe Element component.
  if (loader === null) {
    return <Loader label={t('Loading')} tabIndex={-1} ref={loaderRef} />;
  }

  // Mount the provider, wrapping our child components.
  return (
    <Elements stripe={loader} options={options}>
      {children}
    </Elements>
  );
};

export default StripeElements;
