import { integrationsRequest } from '../client';
import { SupportedIntegrationProductSlugs } from '../../../constants';
import getIntegrationProductName from '../../../utils/get-integration-product-name-from-slug';

const integrationType = getIntegrationProductName(
  SupportedIntegrationProductSlugs.jiraIntegration
);

export interface IntegrationConnection {
  id: string;
  product_slug: SupportedIntegrationProductSlugs;
  credential_id: string;
  base_url: string;
  enterprise_id: string;
  connected_user_id: string;
  status: 'connected' | 'disconnected' | 'deleted';
  created_at: string;
  updated_at: string;
}

export type JiraServerInfoResponse = {
  baseUrl: string;
  buildDate: string;
  buildNumber: number;
  defaultLocale: {
    locale: string;
  };
  displayUrl: string;
  displayUrlServicedeskHelpCenter: string;
  scmInfo: string;
  serverTime: string;
  serverTimeZone: string;
  serverTitle: string;
  version: string;
  versionNumbers: number[];
};

/**
 * `GET /rest/api/3/serverInfo` - retrieve Jira server instance information.
 *
 * Used to retrieve server instance information from Jira, such as the server's base URL, which we subsequently persist (via axe Configurations) and
 * use to construct URLs for human usable links to Jira issues.
 *
 * {@see - https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-server-info/#api-group-server-info}
 * @returns {Promise<JiraServerInfoResponse | void>}
 *
 */
const getServerInfo = ({
  getToken
}: {
  getToken: () => Promise<string | undefined>;
}): Promise<JiraServerInfoResponse | void> => {
  return integrationsRequest<JiraServerInfoResponse>({
    integrationType,
    path: '/rest/api/3/serverInfo',
    getToken
  });
};

export { getServerInfo };
