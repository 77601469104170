import React from 'react';
import ReactDOM from 'react-dom';
import { datadogLogs } from '@datadog/browser-logs';
import App from './modules/app/App';
import './i18n';

const { IS_ON_PREM, STRIPE_PUBLISHABLE_KEY, PARAGON_PROJECT_ID } = process.env;
const isOnPrem = IS_ON_PREM === 'true';

/** Send error logs to DataDog. */
if (process.env.DATADOG_CLIENT_TOKEN) {
  datadogLogs.init({
    clientToken: process.env.DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    env: process.env.DEPLOY_ENV || 'local',
    service: 'axe_devtools_app',
    forwardErrorsToLogs: true
  });
}

// TODO: add a check for the paragon project id once development gets further along
if (
  !isOnPrem &&
  !STRIPE_PUBLISHABLE_KEY &&
  process.env.NODE_ENV === 'development'
) {
  // eslint-disable-next-line no-console
  console.error(
    '**Stripe publishable key environment variable not set**\n**Add an environment variable STRIPE_PUBLISHABLE_KEY**'
  );
}

// Run axe in development.
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

ReactDOM.render(
  <App
    isOnPrem={isOnPrem}
    stripePublishableKey={STRIPE_PUBLISHABLE_KEY}
    paragonProjectId={PARAGON_PROJECT_ID}
  />,
  document.getElementById('app')
);
