import React, { ReactElement } from 'react';
import {
  Button,
  Alert,
  AlertContent,
  AlertActions
} from '@deque/cauldron-react';
import { useTranslation, Trans } from 'react-i18next';
import { useGlobalToast } from '../contexts/globalToast';
import Link from './Link';

export interface Props {
  show: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  isPaidPlan?: boolean;
}

const DowngradeDialog = ({
  show,
  onClose,
  onConfirm,
  isPaidPlan
}: Props): ReactElement => {
  const { t } = useTranslation();
  const { setContents } = useGlobalToast();

  const handleDowngradeClick = async () => {
    await onConfirm();
    if (!isPaidPlan) {
      return;
    }

    setContents(
      <Trans>
        Your Pro plan will be downgraded at the end of your current billing
        period. Please give us feedback to make axe DevTools Extension a better
        product by filling out this{' '}
        <Link
          name={t('downgrade survey')}
          openInNewTab={true}
          url="https://www.surveymonkey.com/r/JJ2KFBH"
        />
      </Trans>,
      'confirmation'
    );
  };

  return (
    <Alert
      heading={t('Downgrade')}
      onClose={onClose}
      show={show}
      variant="warning"
    >
      <AlertContent>
        {isPaidPlan ? (
          <Trans>
            Do you want to cancel your plan at the end of your current billing
            period?&nbsp; Your Pro plan will then{' '}
            <strong>revert to Free</strong> and you will{' '}
            <strong>
              lose access to all of your axe DevTools Extension data
            </strong>
            .
          </Trans>
        ) : (
          <Trans>
            Do you want to cancel your plan, effective immediately?&nbsp; Your
            Pro plan will <strong>revert to Free</strong> and you will{' '}
            <strong>
              lose access to all of your axe DevTools Extension data
            </strong>
            .
          </Trans>
        )}
      </AlertContent>
      <AlertActions>
        <Button variant="error" onClick={handleDowngradeClick}>
          {t('Downgrade')}
        </Button>
        <Button variant="secondary" onClick={onClose}>
          {t('Cancel')}
        </Button>
      </AlertActions>
    </Alert>
  );
};

export default DowngradeDialog;
