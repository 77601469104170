import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { TFunction, useTranslation } from 'react-i18next';
import {
  DescriptionList,
  DescriptionListItem,
  DescriptionTerm,
  DescriptionDetails,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Loader,
  Address,
  AddressLine,
  AddressCityStateZip
} from '@deque/cauldron-react';
import { StripeTypes } from '@deque/billing-service-client';
import styles from './Invoice.css';
import useInvoice from '../hooks/useInvoice';
import { useAuthContext } from '../../common/contexts/auth';
import ErrorMessage from '../components/issue/ErrorMessage';
import PageTitle from '../../common/components/PageTitle';
import { useFeatureFlagState } from '../../common/contexts/featureFlags';
import {
  formatTaxDescription,
  getFormattedAmount,
  countryCodeToName,
  taxIdTypes,
  TaxRate
} from '@deque/billing-utils';

// This business profile is hard-coded, rather than pulled from Stripe because
// Stripe's test API keys don't allow you to pull this information.
// See: https://stripe.com/docs/keys#test-live-modes
const businessProfile = {
  name: 'Deque Systems Inc.',
  support_address: {
    line1: '13800 Coppermine Road',
    city: 'Herndon',
    state: 'Virginia',
    postal_code: '20171',
    country: 'United States'
  },
  support_phone: '+1 703-225-0380',
  support_email: 'axeteam@deque.com'
};

interface RouteParams {
  id: string;
}

type TaxRateWithEffectivePercent = TaxRate & {
  effective_percentage: number | null;
};

/**
 * Converts a unix timestamp into a Date object
 */
const timestampToDate = (timestamp: number) => new Date(timestamp * 1000);

/**
 * Takes a Date and returns a date string in the format MMM DD, YYYY
 */
const getDateString = (d: Date) =>
  `${d.toLocaleString('en', {
    month: 'short'
  })} ${d.getDate()}, ${d.getFullYear()}`;

/**
 * Takes a timestamp and returns a date string in the format MMM DD, YYYY
 */
export const getDateStringFromTimestamp = (timestamp: number): string =>
  getDateString(timestampToDate(timestamp));

interface InvoiceProps {
  loading: boolean;
  error: Error | null;
  invoice: StripeTypes.Invoice | null;
  hasPaymentsV2?: boolean;
  hasTaxIdV1?: boolean;
  t: TFunction;
}

export const Invoice = ({
  loading,
  error,
  invoice,
  hasPaymentsV2 = true,
  hasTaxIdV1 = false,
  t
}: InvoiceProps): ReactElement => {
  const loaderRef = React.useRef<HTMLDivElement>(null);

  const showTax = !!(
    invoice?.tax &&
    invoice.tax > 0 &&
    invoice.total_tax_amounts.length > 0 &&
    hasPaymentsV2
  );

  const hasTaxId = !!(
    hasTaxIdV1 &&
    invoice?.customer_tax_ids &&
    invoice?.customer_tax_ids?.length > 0
  );

  const taxColSpan = showTax ? 4 : 3;

  const taxAmounts =
    invoice?.total_tax_amounts as StripeTypes.Invoice.TotalTaxAmount[];

  React.useEffect(() => {
    loaderRef.current?.focus();
  }, [loaderRef.current]);

  if (loading) {
    return (
      <Loader label={t('Loading invoice')} tabIndex={-1} ref={loaderRef} />
    );
  }

  if (error || !invoice) {
    return (
      <div className={styles.error}>
        <ErrorMessage error={t('Unable to load invoice')} />
      </div>
    );
  }

  // See: https://stripe.com/docs/api/invoices/object#invoice_object-due_date
  const dueDate = invoice.due_date || invoice.created;

  return (
    <div className={styles.wrap}>
      <PageTitle
        title={t('Invoice | {{ number }}', { number: invoice.number })}
      />
      <div className={styles.header}>
        <div>
          <h1>{t('Invoice')}</h1>
          <Address>
            <AddressLine className={styles.businessProfileName}>
              {businessProfile.name}
            </AddressLine>
            <AddressLine>{businessProfile.support_address.line1}</AddressLine>
            <AddressCityStateZip
              city={businessProfile.support_address.city}
              state={businessProfile.support_address.state}
              zip={businessProfile.support_address.postal_code}
            />
            <AddressLine>{businessProfile.support_address.country}</AddressLine>
          </Address>
          <h2>{t('Bill to:')}</h2>
          <Address>
            <AddressLine>{invoice.customer_name}</AddressLine>
            <AddressLine>{invoice.customer_email}</AddressLine>
            <AddressLine>{invoice.customer_address?.line1}</AddressLine>
            <AddressLine>{invoice.customer_address?.line2}</AddressLine>
            <AddressCityStateZip
              city={invoice.customer_address?.city}
              state={invoice.customer_address?.state}
              zip={invoice.customer_address?.postal_code}
            />
            {hasPaymentsV2 && invoice.customer_address?.country && (
              <AddressLine>
                {countryCodeToName(invoice.customer_address.country)}
              </AddressLine>
            )}
            {hasTaxId &&
              invoice.customer_tax_ids?.map(taxId => {
                const taxIdType = taxIdTypes.find(id => id.type === taxId.type);

                if (taxIdType) {
                  return (
                    <AddressLine key={taxId.value}>
                      {taxIdType.description}: {taxId.value}
                    </AddressLine>
                  );
                }
                return null;
              })}
          </Address>
        </div>
        <div>
          <h2>{t('Invoice Information')}</h2>
          <DescriptionList>
            <DescriptionListItem>
              <DescriptionTerm>{t('Invoice Number:')}</DescriptionTerm>
              <DescriptionDetails>{invoice.number}</DescriptionDetails>
            </DescriptionListItem>
            <DescriptionListItem>
              <DescriptionTerm>{t('Date of Issue:')}</DescriptionTerm>
              <DescriptionDetails>
                {getDateStringFromTimestamp(invoice.created)}
              </DescriptionDetails>
            </DescriptionListItem>
            <DescriptionListItem>
              <DescriptionTerm>{t('Date Due:')}</DescriptionTerm>
              <DescriptionDetails>
                {getDateStringFromTimestamp(dueDate)}
              </DescriptionDetails>
            </DescriptionListItem>
          </DescriptionList>
        </div>
      </div>
      <h3>
        {getFormattedAmount(invoice.amount_due)} due{' '}
        {getDateStringFromTimestamp(dueDate)}
      </h3>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader scope="col">{t('Description')}</TableHeader>
            <TableHeader scope="col">{t('Qty')}</TableHeader>
            <TableHeader scope="col">{t('Unit price')}</TableHeader>
            {showTax && <TableHeader scope="col">{t('Tax')}</TableHeader>}
            <TableHeader scope="col">{t('Amount')}</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoice.lines.data.map(line => {
            const price = line.price as StripeTypes.Price;
            const product = price.product as StripeTypes.Product;
            const unitAmount = price.unit_amount as number;
            const taxRatesApplied =
              line.tax_amounts
                ?.filter(tax => tax.amount > 0)
                .map(
                  tax_amount =>
                    tax_amount.tax_rate as TaxRateWithEffectivePercent
                ) || [];
            const totalEffectivePercent = taxRatesApplied.reduce(
              (prev, curr) => {
                /**
                 * `effective_percentage` is a property that was added to the TaxRate object after records had already been created.
                 * As a result, we fallback to the `percentage` to support cases where an invoice was generated with taxes prior to `effective_percentage`
                 * existing (which will return null).
                 *
                 * @see {@link https://github.com/dequelabs/walnut/pull/6225#discussion_r1244198829 | Discussion on PR#6225}
                 */
                const effectivePercentage: number =
                  curr.effective_percentage || curr.percentage || 0;
                return prev + effectivePercentage;
              },
              0
            );

            return (
              <TableRow key={line.id}>
                <TableCell>
                  {getDateStringFromTimestamp(line.period.start)} -{' '}
                  {getDateStringFromTimestamp(line.period.end)}
                  <br />
                  {(product as StripeTypes.Product).name}
                  {(product as StripeTypes.Product).unit_label
                    ? ` per ${(product as StripeTypes.Product).unit_label}`
                    : ''}
                </TableCell>
                <TableCell>{line.quantity}</TableCell>
                <TableCell>{getFormattedAmount(unitAmount)}</TableCell>
                {showTax && (
                  <TableCell>{`${totalEffectivePercent}%`}</TableCell>
                )}
                <TableCell>{getFormattedAmount(line.amount)}</TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableHeader
              className={styles.summaryHeader}
              colSpan={taxColSpan}
              scope="row"
            >
              {t('Subtotal')}
            </TableHeader>
            <TableCell>{getFormattedAmount(invoice.subtotal)}</TableCell>
          </TableRow>

          {invoice.total_discount_amounts?.map(({ amount, discount }) => {
            if (
              !(discount as StripeTypes.Discount).coupon.percent_off &&
              !(discount as StripeTypes.Discount).coupon.amount_off
            ) {
              return null;
            }

            const couponAmount = (discount as StripeTypes.Discount).coupon
              .percent_off
              ? `${(discount as StripeTypes.Discount).coupon.percent_off}%`
              : getFormattedAmount(
                  (discount as StripeTypes.Discount).coupon.amount_off as number
                );

            return (
              <TableRow key={(discount as StripeTypes.Discount).id}>
                <TableHeader
                  className={styles.summaryHeader}
                  colSpan={taxColSpan}
                  scope="row"
                >
                  {t('{{couponName}} ({{couponAmount}} off)', {
                    couponName: (discount as StripeTypes.Discount).coupon.name,
                    couponAmount
                  })}
                </TableHeader>
                <TableCell>-{getFormattedAmount(amount)}</TableCell>
              </TableRow>
            );
          })}
          {showTax &&
            taxAmounts
              .filter(tax_amount => tax_amount.amount > 0)
              .map(({ amount, tax_rate }) => {
                if (!amount && !tax_rate) {
                  return null;
                }
                const taxDescription = formatTaxDescription({
                  ...(tax_rate as TaxRate),
                  percentage:
                    (tax_rate as TaxRate).effective_percentage ||
                    (tax_rate as TaxRate).percentage
                });

                return (
                  <TableRow key={(tax_rate as StripeTypes.TaxRate).id}>
                    <TableHeader
                      className={styles.summaryHeader}
                      colSpan={taxColSpan}
                      scope="row"
                    >
                      {taxDescription}
                    </TableHeader>
                    <TableCell>{getFormattedAmount(amount)}</TableCell>
                  </TableRow>
                );
              })}
          <TableRow>
            <TableHeader
              className={styles.summaryHeader}
              colSpan={taxColSpan}
              scope="row"
            >
              {t('Amount Due')}
            </TableHeader>
            <TableCell className={styles.summaryCell}>
              {getFormattedAmount(invoice.amount_due)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <p className={styles.supportInfo}>
        {t('Questions? Contact {{name}} at {{email}} or {{phone}}.', {
          name: businessProfile.name,
          email: businessProfile.support_email,
          phone: businessProfile.support_phone
        })}
      </p>
    </div>
  );
};

export default function InvoicePage(): JSX.Element {
  const { t } = useTranslation();
  const { id } = useParams<RouteParams>();
  const { user } = useAuthContext();
  const hasPaymentsV2 = useFeatureFlagState('payments_v2');
  const hasTaxIdV1 = useFeatureFlagState('tax_id_v1');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { loading, error, invoice } = useInvoice(user!.token, id);

  return (
    <Invoice
      loading={loading}
      error={error}
      invoice={invoice}
      hasPaymentsV2={hasPaymentsV2}
      hasTaxIdV1={hasTaxIdV1}
      t={t}
    />
  );
}
