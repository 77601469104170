import type { Paginated, Version3Models } from 'jira.js';

import { integrationsRequest } from '../client';
import { SupportedIntegrationProductSlugs } from '../../../constants';
import getIntegrationProductName from '../../get-integration-product-name-from-slug';

const integrationType = getIntegrationProductName(
  SupportedIntegrationProductSlugs.jiraIntegration
);

export type GetIntegrationProjectsResponse = Paginated<Version3Models.Project>;

/**
 * `GET /rest/api/3/project/search?expand=issueTypes` - retrieve integration projects data and their associated issue types.
 *
 * Used to retrieve fetch all of a user's project and the associated issue types for each project.
 *
 * {@see - https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-projects/#api-rest-api-3-project-search-get}
 * @returns {Promise<GetIntegrationProjectsResponse | void>}
 *
 */
const getProjects = async ({
  getToken,
  startAt = 0,
  results = []
}: {
  getToken: () => Promise<string | undefined>;
  startAt?: number;
  results?: Version3Models.Project[];
}): Promise<GetIntegrationProjectsResponse | void> => {
  const data = await integrationsRequest<GetIntegrationProjectsResponse>({
    integrationType,
    path: `/rest/api/3/project/search?expand=issueTypes&startAt=${startAt}`,
    getToken
  });

  if (!data) {
    return;
  }

  if (!data.isLast) {
    return getProjects({
      getToken,
      startAt: startAt + 50,
      results: results.concat(data.values)
    });
  }

  return {
    ...data,
    values: results.concat(data.values)
  };
};

export default getProjects;
