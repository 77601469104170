import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFormContext, useFormState } from 'react-hook-form';
import { Button, PanelContent } from '@deque/cauldron-react';
import { useParams } from 'react-router-dom';

import analyticsInstances, {
  getFallbackAnalyticsInstanceId
} from '../../../../../../common/analyticsInstances';
import Link from '../../../../../../common/components/Link';
import { useGlobalToast } from '../../../../../../common/contexts/globalToast';
import useIntegrationTemplates from '../../../../../hooks/useIntegrationTemplates';
import { SupportedIntegrationProductSlugs } from '../../../../../../common/constants';
import { useIntegrationTemplateWizardSteps } from '../../../../../contexts/IntegrationTemplateWizardSteps';
import styles from './FormFooter.css';
import { useIntegrationProjects } from '../../../../../contexts/IntegrationProjects';

export interface FormFooterProps {
  integrationProductSlug: SupportedIntegrationProductSlugs;
  handleSubmit: () => void;
  narrow?: boolean;
  isEditMode: boolean;
}

function FormFooter({
  integrationProductSlug,
  handleSubmit,
  narrow = false,
  isEditMode
}: FormFooterProps) {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const analytics =
    analyticsInstances[getFallbackAnalyticsInstanceId(integrationProductSlug)];

  const { trigger, reset, getValues, setValue, setError } = useFormContext();
  const { touchedFields } = useFormState();
  const { setContents } = useGlobalToast();
  const { projects } = useIntegrationProjects();
  const { isTemplateNameUnique, loading } = useIntegrationTemplates({
    integrationProductSlug
  });
  const { steps, currentStep, setCurrentStep, isStepDataLoading } =
    useIntegrationTemplateWizardSteps();

  const { stepFields } = steps[currentStep];
  const totalSteps = steps.length - 1;

  const isNotFirstStep = currentStep > 0;
  const isLastStep = currentStep === totalSteps;

  // Removes empty value pairs from the data
  const cleanData = (key: string) => {
    if (stepFields.includes(key) && getValues(key)) {
      const keyValues = getValues(key) as Record<string, string>[];
      const cleanedData = keyValues.reduce((acc, value) => {
        if (Object.values(value).some(Boolean)) {
          acc.push(value);
        }
        return acc;
      }, [] as Record<string, string>[]);

      setValue(key, cleanedData);
    }
  };

  const onNext = async () => {
    let isStepValid = await trigger(stepFields);
    if (stepFields.includes('templateName')) {
      const templateName = getValues('templateName');

      if (!isTemplateNameUnique(templateName, id)) {
        setError(
          'templateName',
          {
            type: 'manual',
            message: t(
              'A template with this name already exists. Please choose a different name.'
            )
          },
          { shouldFocus: true }
        );

        isStepValid = false;
      }
    }
    if (isStepValid) {
      setContents(null);
      setCurrentStep(prev => prev + 1);
      cleanData('fieldMapping');
      cleanData('customLabels');
    }
  };

  const isLoading = isStepDataLoading || loading;

  const isSaveEnable = id ? Object.keys(touchedFields).length : true;

  return (
    <PanelContent
      className={classNames(
        styles.footerContainer,
        narrow && styles.mobilePanelContent
      )}
    >
      <Link
        name={t('Cancel')}
        url={`/configuration/integrations/${integrationProductSlug}/templates`}
        onClick={() => {
          const projectName =
            projects.find(project => project.id === getValues('project'))
              ?.name || '';
          const issueTypeName =
            projects
              .find(project => project.id === getValues('project'))
              ?.issueTypes?.find(
                issueType => issueType.id === getValues('issueType')
              )?.name || '';
          const templateName = getValues('templateName') || '';
          reset();

          if (isEditMode) {
            analytics.integrationTemplateEditCancel({
              integration: integrationProductSlug,
              project: projectName,
              issueType: issueTypeName,
              template: templateName
            });
          } else {
            analytics.integrationTemplateAddCancel({
              integration: integrationProductSlug,
              project: projectName,
              issueType: issueTypeName,
              template: templateName
            });
          }
        }}
        className={styles.cancelLink}
      />
      <div>
        {isNotFirstStep && (
          <Button
            variant="secondary"
            onClick={() => setCurrentStep(prev => prev - 1)}
            className={styles.footerButton}
            disabled={isLoading}
          >
            {t('Back')}
          </Button>
        )}
        {isLastStep ? (
          <Button
            onClick={handleSubmit}
            className={styles.footerButton}
            disabled={!isSaveEnable || isLoading}
          >
            {t('Save')}
          </Button>
        ) : (
          <Button
            onClick={onNext}
            className={styles.footerButton}
            disabled={isLoading}
          >
            {t('Next')}
          </Button>
        )}
      </div>
    </PanelContent>
  );
}

export default FormFooter;
