import React, { ReactNode, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button, Icon, Loader } from '@deque/cauldron-react';
import { TFunction } from 'i18next';

import {
  ProductNames,
  SupportedIntegrationProductSlugs
} from '../../../../../../common/constants';
import Field from './Field/Field';
import styles from './FieldMapping.css';
import useIssueTypeFields from '../../../../../hooks/useIssueTypeFields';
import { useGlobalToast } from '../../../../../../common/contexts/globalToast';
import { type FieldMappingField } from '../../TemplateWizardForm/TemplateWizardForm';
import { useIntegrationTemplateWizardSteps } from '../../../../../contexts/IntegrationTemplateWizardSteps';
export interface SelectOption {
  key: string;
  label: string;
  value: string;
  required?: boolean;
  disabled?: boolean;
}

export interface FieldMappingProps {
  integrationProductSlug: SupportedIntegrationProductSlugs;
}

export const getAxeFieldsOptions = (t: TFunction): SelectOption[] => [
  { key: '', label: '', value: '' },
  { label: t('Rule'), key: 'rule', value: 'ruleId' },
  { label: t('Description'), key: 'description', value: 'description' },
  { label: t('Help'), key: 'help', value: 'help' },
  { label: t('Help URL'), key: 'helpUrl', value: 'helpUrl' },
  { label: t('Summary'), key: 'summary', value: 'summary' },
  { label: t('Selector'), key: 'selector', value: 'selector' },
  { label: t('Source'), key: 'source', value: 'source' },
  {
    label: t('Issue Screenshot URL'),
    key: 'screenshotURL',
    value: 'screenshotURL'
  },
  { label: t('Tags'), key: 'tags', value: 'tags' },
  { label: t('Test name'), key: 'testName', value: 'testName' },
  {
    label: t('Issue Share URL'),
    key: 'shareURL',
    value: 'shareURL'
  },
  { label: t('Created at'), key: 'createdAt', value: 'createdAt' },
  {
    label: t('Issue Test URL'),
    key: 'testUrl',
    value: 'testUrl'
  },
  {
    label: t('Found by'),
    key: 'foundBy',
    value: 'foundBy'
  },
  {
    label: t('Test page title'),
    key: 'testPageTitle',
    value: 'testPageTitle'
  },
  {
    label: t('Axe version'),
    key: 'axeVersion',
    value: 'axeVersion'
  }
];

/** Fields that are handled by server or integration*/
const ExcludedIntegrationFields = {
  [SupportedIntegrationProductSlugs.jiraIntegration]: [
    'issuetype',
    'project',
    'description',
    'reporter',
    'priority'
  ]
};

const FieldMapping = ({ integrationProductSlug }: FieldMappingProps) => {
  const { t } = useTranslation();
  const { setContents } = useGlobalToast();
  const { control, getValues } = useFormContext();
  const { setIsStepDataLoading } = useIntegrationTemplateWizardSteps();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'fieldMapping'
  });
  const issueTypeId = getValues('issueType');

  const {
    fields: issueMappingFields,
    error,
    loading
  } = useIssueTypeFields({
    issueTypeId
  });

  useEffect(() => setIsStepDataLoading(loading), [loading]);

  useEffect(() => {
    if (fields.length) {
      return;
    }

    const requiredFields = issueMappingFields.reduce((fieldsArray, field) => {
      return field.required &&
        !ExcludedIntegrationFields[integrationProductSlug].includes(field.id)
        ? [
            ...fieldsArray,
            {
              axeField: '',
              mappingField: field.id
            }
          ]
        : fieldsArray;
    }, [] as Array<FieldMappingField>);

    append(requiredFields);
  }, [issueMappingFields]);

  useEffect(() => {
    if (error) {
      setContents(error.message, 'error');
    }
  }, [error]);

  const mappingFields: SelectOption[] = useMemo(() => {
    const mappedFields = issueMappingFields.reduce<SelectOption[]>(
      (resultedFields, { id, name, required }) => {
        const isExcludedField =
          ExcludedIntegrationFields[integrationProductSlug].includes(id);

        if (!isExcludedField) {
          const field = {
            key: id,
            value: id,
            label: name,
            required
          };

          resultedFields.push(field);
        }

        return resultedFields;
      },
      []
    );

    return [{ key: '', label: '', value: '' }, ...mappedFields];
  }, [issueMappingFields]);

  const axeFields: SelectOption[] = useMemo(() => getAxeFieldsOptions(t), [t]);

  const isFieldRequired = (field: Record<string, string>) => {
    return !!mappingFields.find(
      mappingField => mappingField.value === field.mappingField
    )?.required;
  };

  const fieldsAreRemaining = fields.length < mappingFields.length - 1;

  if (loading) {
    return <Loader />;
  }

  const fieldsContent = fields
    .reduce<ReactNode[][]>(
      (result, field, i) => {
        const isRequired = isFieldRequired(field);

        const fieldComponent = (
          <Field
            key={field.id}
            required={isRequired}
            integrationProductSlug={integrationProductSlug}
            axeFields={axeFields}
            mappingFields={mappingFields}
            removeField={remove}
            index={i}
          />
        );

        if (isRequired) {
          result[0].push(fieldComponent);
        } else {
          result[1].push(fieldComponent);
        }

        return result;
      },
      [[], []]
    )
    .flat();

  return (
    <div className={styles.fieldMappingContainer}>
      <div
        className={
          // Add border bottom if there are fields remaining
          fieldsAreRemaining && fields.length !== 0
            ? styles.borderBottom
            : undefined
        }
      >
        {fieldsContent}
      </div>
      {/* Add button to add a new field if there are any fields remaining to map */}
      {fieldsAreRemaining && (
        <Button
          onClick={() => append({ axeField: '', mappingField: '' })}
          color="primary"
          className={styles.addAxeFieldButton}
          thin
        >
          <Icon type="plus" />
          {t(`Add {{ axe }} Field`, {
            axe: ProductNames.axe
          })}
        </Button>
      )}
    </div>
  );
};

export default FieldMapping;
