import type { Version3Models, Paginated } from 'jira.js';

import { integrationsRequest } from '../client';
import { SupportedIntegrationProductSlugs } from '../../../constants';
import getIntegrationProductName from '../../get-integration-product-name-from-slug';
import { AllowedValue } from '../../../../app/hooks/useIssueTypeFields';

const integrationType = getIntegrationProductName(
  SupportedIntegrationProductSlugs.jiraIntegration
);

export interface IssueTypeField {
  allowedValues?: AllowedValue[];
  autoCompleteUrl?: string;
  configuration: object;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any;
  fieldId: string;
  hasDefaultValue?: boolean;
  key: string;
  name: string;
  operations: string[];
  required: boolean;
  schema: Version3Models.JsonType;
}

export interface IssueTypeFieldsResponse
  extends Omit<Paginated<IssueTypeField>, 'values' | 'isLast'> {
  fields: IssueTypeField[];
  results: IssueTypeField[];
}

export interface GetIssueTypeFieldsArgs {
  projectIdOrKey: string;
  issueTypeId: string;
  getToken: () => Promise<string | undefined>;
}

/**
 * `GET /rest/api/3/issue/createmeta/{projectIdOrKey}/issuetypes/{issueTypeId}` - retrieve fields for a given issue type for a given project.
 *
 * Used to retrieve the fields and whether or not they are required for a given issue type in a given project.
 *
 * {@see - https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-issues/#api-rest-api-3-issue-createmeta-projectidorkey-issuetypes-issuetypeid-get}
 * @returns {Promise<IssueTypeFieldsResponse | void>}
 *
 */
const getIssueTypeFields = ({
  projectIdOrKey,
  issueTypeId,
  getToken
}: GetIssueTypeFieldsArgs): Promise<IssueTypeFieldsResponse | void> => {
  return integrationsRequest<IssueTypeFieldsResponse>({
    integrationType,
    path: `/rest/api/3/issue/createmeta/${projectIdOrKey}/issuetypes/${issueTypeId}`,
    getToken
  });
};

export default getIssueTypeFields;
