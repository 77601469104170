import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Control,
  Controller,
  FieldValues,
  useFormContext,
  useWatch
} from 'react-hook-form';
import { Loader, Select, SelectProps } from '@deque/cauldron-react';
import useIssueTypeFields from '../../../../../hooks/useIssueTypeFields';
import { useGlobalToast } from '../../../../../../common/contexts/globalToast';
import { SupportedIntegrationProductSlugs } from '../../../../../../common/constants';
import getIntegrationProductName from '../../../../../../common/utils/get-integration-product-name-from-slug';
import { useIntegrationTemplateWizardSteps } from '../../../../../contexts/IntegrationTemplateWizardSteps';

type ControlledSelectProps = {
  name: string;
  control: Control<FieldValues>;
} & Omit<SelectProps, 'ref'>;

const ControlledSelect: FC<ControlledSelectProps> = ({
  name,
  control,
  ...otherSelectProps
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <Select {...field} {...otherSelectProps} />}
    />
  );
};

const makeTitle = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

type ImpactMappingProps = {
  integrationProductSlug: SupportedIntegrationProductSlugs;
};

const ImpactMapping: FC<ImpactMappingProps> = ({ integrationProductSlug }) => {
  const { t } = useTranslation();
  const { setContents } = useGlobalToast();
  const { control, getValues } = useFormContext();
  const { setIsStepDataLoading } = useIntegrationTemplateWizardSteps();
  const impactMapping = useWatch({ control, name: 'impactMapping' });

  const issueTypeId = getValues('issueType');
  const { priorities, error, loading } = useIssueTypeFields({ issueTypeId });

  const prioritiesOptions = priorities
    ? [
        {
          key: '',
          label: '',
          value: ''
        },
        ...priorities.allowedValues.map(({ id, name }) => ({
          key: id,
          label: name,
          value: id
        }))
      ]
    : [
        {
          key: '',
          label: t('No priorities available'),
          value: ''
        }
      ];

  useEffect(() => {
    if (error) {
      setContents(error.message);
    }
  }, [error]);

  useEffect(() => setIsStepDataLoading(loading), [loading]);

  if (loading) {
    return <Loader label={t('Loading issue priorities...')} />;
  }

  return (
    <div>
      {impactMapping
        ? Object.keys(impactMapping).map(field => (
            <ControlledSelect
              key={field}
              name={`impactMapping.${field}`}
              control={control}
              label={t('{{ integration }} Priority for {{impact}} Impact', {
                integration: getIntegrationProductName(integrationProductSlug, {
                  capitalize: true
                }),
                impact: makeTitle(field)
              })}
              options={prioritiesOptions.map(option => ({
                ...option,
                disabled: Object.entries(impactMapping).some(
                  ([key, value]) =>
                    key !== field && option.value && value === option.value
                )
              }))}
              disabled={!priorities}
            />
          ))
        : null}
    </div>
  );
};

export default ImpactMapping;
