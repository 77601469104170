import type { AxePurchaseState, v2 } from '@deque/billing-service-client';
import { ProductSlugs } from '@deque/billing-utils';

export const PROD_EXTENSION_URL =
  'https://chrome.google.com/webstore/detail/axe-web-accessibility-tes/lhdoppojpmngadmnindnejefpokejbdd';
export const EXTENSION_HEALTH_CHECK = 'extension:healthcheck';
export const EXTENSION_HEALTH_CHECK_RESPONSE = 'extension:healthcheck:response';
export const INAPPLICABLE_SIGNUP_PASSWORD_REQUIREMENTS = [
  'forceExpiredPasswordChange',
  'passwordHistory',
  'passwordBlacklist',
  'regexPattern',
  'hashIterations',
  'hashAlgorithm'
];

// Taken from https://github.com/dequelabs/axe-extension/blob/a89b73a3f52c4f1aa701727a895595f265118c10/src/shared/user-roles.js
export const USER_JOB_ROLES = [
  'Developer',
  'Tester',
  'Designer',
  'Accessibility Specialist',
  'Manager',
  'Other'
] as const;

export const PRO_FEATURES = [
  'Automated testing',
  'Intelligent Guided Testing',
  'Test individual components',
  'Save & Export results',
  'Machine learning'
];

export const DOWNGRADABLE_STATES: Readonly<AxePurchaseState>[] = [
  'paid',
  'paid_payment_failed',
  'trial_payment_failed',
  'free_payment_failed',
  'trial_ended'
];

export const DEFAULT_LANGUAGE = 'en';

export const FREE_STATES: Readonly<AxePurchaseState>[] = [
  'free',
  'trial_ended',
  'trial_payment_failed',
  'free_payment_failed',
  'paid_payment_failed'
];

export const TRIAL_EXPIRED_STATES: Readonly<AxePurchaseState>[] = [
  'trial_ended'
];

export const ACTIVE_STATES: Readonly<AxePurchaseState>[] = ['paid', 'trialing'];

export const FAILED_PAYMENT_STATES: Readonly<AxePurchaseState>[] = [
  'free_payment_failed',
  'trial_payment_failed',
  'paid_payment_failed'
];

export const SUPPORTED_TRIAL_PRODUCT_SLUGS = [
  ProductSlugs.axeLinter,
  ProductSlugs.axeDevToolsExtension,
  ProductSlugs.axeDevToolsMobile,
  ProductSlugs.axeSpider
] as const;

/*
TODO - Integration
Delete SupportedIntegrationProductSlugs - use regular ProductSlugs
*/
export enum SupportedIntegrationProductSlugs {
  jiraIntegration = ProductSlugs.jiraIntegration
}

export const INTEGRATION_PRODUCT_SLUGS: SupportedIntegrationProductSlugs[] =
  Object.values(SupportedIntegrationProductSlugs);

export const PRODUCTS_WITHOUT_PLAN_NAME: string[] = [
  ProductSlugs.axeMonitor,
  ProductSlugs.jiraIntegration
];

export const hasPlanName = (product_slug?: string) =>
  !(product_slug && PRODUCTS_WITHOUT_PLAN_NAME.includes(product_slug));

export enum ProductNames {
  'jira' = 'Jira',
  'axe' = 'axe',
  'devtools' = 'DevTools'
}

export type SupportedTrialProductSlugs =
  (typeof SUPPORTED_TRIAL_PRODUCT_SLUGS)[number];

export const isSubscriptionExpired = (
  subscription: v2.EnterpriseSubscription | v2.UserSubscription
) => FREE_STATES.includes(subscription.purchase_state as AxePurchaseState);

interface AuditorProductUrls {
  tests?: string;
  issues?: string;
  reports?: string;
  admin?: string;
}
export interface ProductUrls extends AuditorProductUrls {
  docs?: string;
  docsAnalyzer?: string;
  docsSDK?: string;
  signup?: string;
  root?: string;
  plans?: string;
  trial?: string;
  welcome?: string;
  [key: string]: string | undefined;
}

export const PRODUCT_URLS = {
  [ProductSlugs.axeDevToolsExtension]: {
    root: '/axe-devtools',
    plans: '/axe-devtools/plans',
    docs: 'https://axe-devtools-html-docs.deque.com/extensions/devtools/home.html',
    trial: `/${ProductSlugs.axeDevToolsExtension}/trial`,
    welcome: '/axe-devtools',
    signup: `/signup?product=${ProductSlugs.axeDevToolsExtension}`
  },
  [ProductSlugs.axeDevToolsMobile]: {
    root: `/${ProductSlugs.axeDevToolsMobile}`,
    docsSDK: 'https://docs.deque.com/devtools-mobile',
    docsAnalyzer: 'https://docs.deque.com/devtools-mobile-analyzer',
    trial: `/${ProductSlugs.axeDevToolsMobile}/get-started`,
    dashboard: process.env.MOBILE_APP_URL || '',
    signup: `/signup?product=${ProductSlugs.axeDevToolsMobile}`,
    automated: `/${ProductSlugs.axeDevToolsMobile}/automated-testing-sdk`,
    analyzer: `/${ProductSlugs.axeDevToolsMobile}/running-mobile-app-analyzer`
  },
  [ProductSlugs.axeDevToolsWatcher]: {
    root: '/axe-watcher',
    docs: 'https://docs.deque.com/watcher/1.0.0/en/aw-getting-started',
    startup: '/axe-watcher/startup',
    trial: '/axe-watcher/startup',
    projects: '/axe-watcher/projects',
    signup: `/signup?product=${ProductSlugs.axeDevToolsWatcher}`
  },
  [ProductSlugs.axeLinter]: {
    plans: 'https://www.deque.com/axe/devtools/',
    docs: 'https://docs.deque.com/linter/1.0.0/en/linter-home',
    trial: `/${ProductSlugs.axeLinter}/trial`,
    signup: `/signup?product=${ProductSlugs.axeLinter}`
  },
  [ProductSlugs.axeAuditor]: {
    root: `/${ProductSlugs.axeAuditor}`,
    tests: `/${ProductSlugs.axeAuditor}`,
    issues: `/${ProductSlugs.axeAuditor}/issues`,
    reports: `/${ProductSlugs.axeAuditor}/reports`,
    signup: `/signup?product=${ProductSlugs.axeAuditor}`
  },
  [ProductSlugs.dequeUniversity]: {
    site: process.env.DEQUE_UNIVERSITY_URL || '',
    docs: (process.env.DEQUE_UNIVERSITY_URL || '') + '/support',
    plans: (process.env.DEQUE_UNIVERSITY_URL || '') + '/online-courses',
    signup: `/signup?product=${ProductSlugs.dequeUniversity}`
  },
  [ProductSlugs.axeSpider]: {
    root: `/${ProductSlugs.axeSpider}`,
    signup: `/signup?product=${ProductSlugs.axeSpider}`,
    trial: `/${ProductSlugs.axeSpider}/trial`,
    createProject: `/${ProductSlugs.axeSpider}/projects/new`,
    projects: `/${ProductSlugs.axeSpider}/projects`
  },
  [ProductSlugs.jiraIntegration]: {
    root: `/configuration/integrations`,
    templates: `/configuration/integrations/${ProductSlugs.jiraIntegration}/templates`,
    // TODO: update this URL when the Jira integration documentation is available
    docs: 'https://docs.deque.com/jira/1.0.0/en/jira-home'
  },
  [ProductSlugs.axeMonitor]: {
    docs: 'https://docs.deque.com/monitor'
  }
} as const satisfies Partial<Record<ProductSlugs, ProductUrls>>;

export enum HubSpotProperties {
  AXE_PAID_DATE = 'axe_paid_date',
  AXE_PAID_DATE_END = 'axe_paid_date_end',
  AXE_FIRST_TEST_SAVED_DATE = 'axe_first_test_saved_date',
  AXE_FIRST_EXPORT_DATE = 'axe_first_export_date',
  AXE_FIRST_SCOPED_TEST_DATE = 'axe_first_scoped_test_date',
  AXE_ENTERPRISE_DATE = 'axe_enterprise_date',
  AXE_DOWNGRADED_FROM_TRIAL_DATE = 'axe_downgraded_from_trial_date',
  AXE_DOWNGRADED_FROM_PAID_DATE = 'axe_downgraded_from_paid_date',
  AXE_TRIAL_STARTED_DATE = 'axe_trial_started_date',
  AXE_FIRST_IGT_COMPLETED_DATE = 'axe_first_igt_completed_date',
  AXE_SIGNUP = 'axe_signup',
  REFERRER_EMAIL = 'referrer_email',
  REFERRAL_PURCHASE_DATE = 'referral_purchase_date',
  AXE_FIRST_LOGIN_DATE = 'axe_first_login_date',
  AXE_KEYCLOAK_ID = 'axe_keycloak_id',
  HS_MARKETABLE_STATUS = 'hs_marketable_status',
  AXE_LAST_LOGIN_DATE = 'axe_last_login_date',
  AXE_START_WALKTHROUGH_DATE = 'axe_start_walkthrough_date',
  AXE_NEXT_WALKTHROUGH_DATE = 'axe_next_walkthrough_date',
  AXE_SKIP_WALKTHROUGH_DATE = 'axe_skip_walkthrough_date',
  AXE_COMPLETED_WALKTHROUGH_DATE = 'axe_completed_walkthrough_date'
}

export const HIDDEN_USER_MANAGEMENT_PRODUCTS: ProductSlugs[] = [
  ProductSlugs.axeAuditor,
  ProductSlugs.axeReports,
  ProductSlugs.dequeUniversity
];

export const PRODUCTS_WITH_ACCESS_LEVEL_MANAGEMENT: ProductSlugs[] = [
  ProductSlugs.axeMonitor
];

export const productHasAccessLevelManagement = (slug: ProductSlugs) =>
  PRODUCTS_WITH_ACCESS_LEVEL_MANAGEMENT.includes(slug);

type KeyOfProductUrls = keyof typeof PRODUCT_URLS;

export const getProductUrl = (
  slug: ProductSlugs,
  type: keyof ProductUrls
): string | undefined =>
  (PRODUCT_URLS[slug as KeyOfProductUrls] as ProductUrls)?.[type];

export const isKeyOfProductUrls = (
  value: unknown
): value is KeyOfProductUrls => {
  return (
    typeof value === 'string' &&
    Object.keys(PRODUCT_URLS).includes(value as KeyOfProductUrls)
  );
};

export const originalRedirectQueryParam = 'original_redirect_uri';

export { ProductSlugs };

export const WALKTHROUGH_EVENTS = {
  checkOpen: 'walkthrough:check:open',
  opened: 'walkthrough:opened',
  scanned: 'walkthrough:scanned',
  saved: 'walkthrough:saved',
  igtCompleted: 'walkthrough:igt:completed'
};

// cleanup: remove this cont when the feature flag is removed from server
export const SECURE_SCREENSHOTS_API_FEATURE_FLAG = 'secure_screenshots_api';

export const MAX_TEAM_NAME_LENGTH = 128;
