import type { TFunction } from 'react-i18next';
import React from 'react';

import type { StepMap } from '../types';
import Link from '../../common/components/Link';
import { SupportedIntegrationProductSlugs } from '../../common/constants';
import TemplateInformation from '../components/integrations/IntegrationTemplateWizard/Steps/TemplateInformation/TemplateInformation';
import FieldMapping from '../components/integrations/IntegrationTemplateWizard/Steps/FieldMapping/FieldMapping';
import ImpactMapping from '../components/integrations/IntegrationTemplateWizard/Steps/ImpactMapping/ImpactMapping';
import getIntegrationProductName from '../../common/utils/get-integration-product-name-from-slug';
import Review from '../components/integrations/IntegrationTemplateWizard/Steps/Review/Review';

export interface GetTemplateArgs {
  t: TFunction;
  integrationProductSlug: SupportedIntegrationProductSlugs;
  instanceUrl: string;
  isPriorityEnabled?: boolean;
}

const getTemplateSteps = ({
  t,
  integrationProductSlug,
  instanceUrl,
  isPriorityEnabled = false
}: GetTemplateArgs) => {
  const templateSteps: StepMap[] = [
    {
      header: t('Template Information'),
      description: (
        <>
          {t(`Select the project and issue type to send issues to when using this template.
        This template is created for`)}{' '}
          <Link url={instanceUrl} openInNewTab />
        </>
      ),
      content: <TemplateInformation />,
      stepFields: ['templateName', 'project', 'issueType']
    },
    {
      header: t('Field Mapping'),
      description: t(
        `Map axe fields to {{ productName }} fields. Some fields may be required by {{ productName }}.`,
        {
          productName: getIntegrationProductName(integrationProductSlug, {
            capitalize: true
          })
        }
      ),
      content: <FieldMapping integrationProductSlug={integrationProductSlug} />,
      stepFields: ['fieldMapping']
    },
    // TODO: Implement post-MVP
    // {
    //   header: t('Custom Labels'),
    //   description: t(
    //     `Add custom labels to associate with any new {{ axe }} issue.`,
    //     {
    //       axe: ProductNames.axe
    //     }
    //   ),
    //   content: <CreateIntegrationTemplate />,
    //   stepFields: ['customLabels']
    // },
    {
      header: t('Review'),
      content: <Review isPriorityEnabled={isPriorityEnabled} />,
      stepFields: [
        'templateName',
        'project',
        'issueType',
        'fieldMapping',
        'customLabels',
        'impactMapping'
      ]
    }
  ];

  if (isPriorityEnabled) {
    templateSteps.splice(2, 0, {
      header: t('Impact Mapping'),
      description: t(
        `Map Impact levels to {{ productName }} Priorities (e.g., Critical = P1, Serious = P2, etc.) or, leave blank to use {{ productName }}'s default priority.`,
        {
          productName: getIntegrationProductName(integrationProductSlug, {
            capitalize: true
          })
        }
      ),
      content: (
        <ImpactMapping integrationProductSlug={integrationProductSlug} />
      ),
      stepFields: ['impactMapping']
    });
  }

  return templateSteps;
};

export default getTemplateSteps;
