import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { Loader } from '@deque/cauldron-react';
import { useTranslation } from 'react-i18next';

import { useEnterprises } from '../../common/contexts/enterprises';
import { useIntegrations } from '../../common/contexts/Integrations';
import { useConfiguration } from '../../common/contexts/Configuration';
import useIntegrationTemplates from '../hooks/useIntegrationTemplates';
import { useIntegrationProjects } from '../contexts/IntegrationProjects';
import { SupportedIntegrationProductSlugs } from '../../common/constants';
import ListIntegrationTemplates from '../components/integrations/ListIntegrationTemplates';
import TemplateWizard from '../components/integrations/IntegrationTemplateWizard/TemplateWizard';

function IntegrationTemplates() {
  const { t } = useTranslation();
  const { productSlug: integrationProductSlug } = useParams<{
    productSlug: SupportedIntegrationProductSlugs;
  }>();

  const { loading: enterprisesLoading, error: enterprisesError } =
    useEnterprises();
  const { loading: settingsLoading, loadingError: settingsError } =
    useConfiguration();

  const {
    loading: integrationsLoading,
    error: integrationsError,
    canAccessIntegrations,
    updatingToken
  } = useIntegrations();

  const {
    loading: integrationProjectsLoading,
    error: integrationProjectsError
  } = useIntegrationProjects();

  const { loading: templatesLoading, error: templatesError } =
    useIntegrationTemplates({
      integrationProductSlug
    });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      !enterprisesLoading &&
      !integrationsLoading &&
      !updatingToken &&
      !settingsLoading &&
      !templatesLoading &&
      !integrationProjectsLoading
    ) {
      setLoading(false);
    }
  }, [
    enterprisesLoading,
    integrationsLoading,
    updatingToken,
    settingsLoading,
    integrationProjectsLoading,
    templatesLoading
  ]);

  const error = useMemo(() => {
    if (!loading && enterprisesError) {
      return typeof enterprisesError === 'string'
        ? new Error(enterprisesError)
        : enterprisesError;
    }

    if (!loading && !canAccessIntegrations) {
      return new Error(t('Cannot access integrations'));
    }

    return (
      settingsError ||
      integrationsError ||
      integrationProjectsError ||
      templatesError
    );
  }, [
    settingsError,
    canAccessIntegrations,
    integrationsError,
    integrationProjectsError,
    templatesError
  ]);

  const baseUrl = `/configuration/integrations/:productSlug/templates`;

  if (loading) {
    return <Loader label={t('Loading...')} tabIndex={-1} />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!canAccessIntegrations) {
    return <Redirect to="/configuration" />;
  }

  return (
    <Switch>
      <Route exact path={baseUrl}>
        <ListIntegrationTemplates
          integrationProductSlug={integrationProductSlug}
        />
      </Route>
      <Route exact path={`${baseUrl}/new`}>
        <TemplateWizard integrationProductSlug={integrationProductSlug} />
      </Route>
      <Route path={`${baseUrl}/:id`}>
        <TemplateWizard
          integrationProductSlug={integrationProductSlug}
          isEditMode={true}
        />
      </Route>
    </Switch>
  );
}

export default IntegrationTemplates;
