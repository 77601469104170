import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Footer.css';
import classNames from 'classnames';
import { PrivacyPolicyLink } from './AnalyticsEnabledLink';
import { Icon, Link } from '@deque/cauldron-react';

const Footer = (): ReactElement => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <div className={classNames('centeredContainer', styles.wrap)}>
        <section className={styles.left}>
          <div className={styles.address}>
            <span>13800 Coppermine Road Herndon, VA 20171 </span>
            <a href="tel:1-703-225-0380">1-703-225-0380</a>
          </div>
          <div className={styles.links}>
            <ul>
              <li>
                <a href="https://www.deque.com">{t('Deque Systems')}</a>
              </li>
              <li>
                <a href="https://www.deque.com/terms-of-use">
                  {t('Terms of Use')}
                </a>
              </li>
              <li>
                <PrivacyPolicyLink />
              </li>
              <li>
                <a href="https://www.deque.com/company/careers/">
                  {t('Careers')}
                </a>
              </li>
              <li>
                <a href="https://www.deque.com/sitemap">{t('Sitemap')}</a>
              </li>
              <li>
                <a href="https://www.deque.com/accessibility-statement/">
                  {t('Accessibility Statement')}
                </a>
              </li>
            </ul>
          </div>
        </section>
        <section className={styles.right}>
          <div className={styles.socialLinks}>
            <ul>
              <li>
                <Link
                  className={styles.socialMediaIcon}
                  href="https://linkedin.com/company/deque-systems-inc"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Icon
                    type="linkedin"
                    label={t('LinkedIn, opens in a new tab')}
                  />
                </Link>
              </li>

              <li>
                <Link
                  className={styles.socialMediaIcon}
                  href="https://twitter.com/dequesystems"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Icon
                    type="twitter"
                    label={t('Twitter, opens in a new tab')}
                  />
                </Link>
              </li>

              <li>
                <Link
                  className={styles.socialMediaIcon}
                  href="https://github.com/dequelabs"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Icon type="github" label={t('GitHub, opens in a new tab')} />
                </Link>
              </li>
            </ul>
          </div>
          <div className={styles.copyr}>
            &copy;{' '}
            {t('Copyright {{year}}, Deque Systems, Inc. All Rights Reserved', {
              year
            })}
          </div>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
