import type { v2 } from '@deque/billing-service-client';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  IconButton,
  DescriptionList,
  DescriptionListItem,
  DescriptionTerm,
  DescriptionDetails,
  Offscreen
} from '@deque/cauldron-react';

import { ProductSlugs } from '../../../common/constants';
import {
  AccessTypes,
  EnterpriseMembers,
  memberHasAccepted,
  memberIsPending,
  ProductAccess
} from '../../../common/utils/billing-client/client-v2';
import styles from './UserManagementLegacy.css';

export interface UserManagementTableLegacyProps {
  members: EnterpriseMembers.PendingOrAcceptedMember[];
  currentUserId: string;
  getProductBySlug: (slug: string) => v2.Product | undefined;
  onResendClick: (member: EnterpriseMembers.PendingMember) => void;
  onRemoveClick: (member: EnterpriseMembers.PendingOrAcceptedMember) => void;
  variant?: 'wide' | 'narrow' | 'medium';
  membersPerPage?: number;
  hiddenProductSlugs: ProductSlugs[];
  isMailerEnabled: boolean;
}

type SortBy = null | 'email' | 'access';
type SortDirection = 'none' | 'ascending' | 'descending';

const UserManagementTableLegacy = ({
  members,
  currentUserId,
  onResendClick,
  onRemoveClick,
  getProductBySlug,
  variant = 'medium',
  membersPerPage = 50,
  hiddenProductSlugs,
  isMailerEnabled
}: UserManagementTableLegacyProps): JSX.Element => {
  const { t } = useTranslation();
  const [sort, setSort] = useState<[SortBy, SortDirection]>([null, 'none']);
  const [sortBy, sortDirection] = sort;
  const [currentPage, setCurrentPage] = useState(1);
  const totalMembers = members.length;
  const memberStart = currentPage * membersPerPage - membersPerPage + 1;
  const memberEnd = Math.min(memberStart + membersPerPage - 1, totalMembers);

  const onNextPageClick = () => setCurrentPage(currentPage + 1);
  const onFirstPageClick = () => setCurrentPage(1);
  const onPreviousPageClick = () => setCurrentPage(currentPage - 1);
  const onLastPageClick = () =>
    setCurrentPage(Math.ceil(totalMembers / membersPerPage));

  // default sorting
  const sortByDefault = (
    data: EnterpriseMembers.PendingOrAcceptedMember[]
  ): EnterpriseMembers.PendingOrAcceptedMember[] =>
    data?.sort((a, b) => {
      if (memberHasAccepted(a) && memberIsPending(b)) {
        return 1;
      }
      if (memberIsPending(a) && memberHasAccepted(b)) {
        return -1;
      }
      return a.email.localeCompare(b.email);
    });

  const sortByEmail = (
    data: EnterpriseMembers.PendingOrAcceptedMember[]
  ): EnterpriseMembers.PendingOrAcceptedMember[] =>
    data.sort((a, b) =>
      (sortDirection === 'ascending' ? a.email : b.email).localeCompare(
        sortDirection === 'ascending' ? b.email : a.email
      )
    );

  const sortByAccess = (
    data: EnterpriseMembers.PendingOrAcceptedMember[]
  ): EnterpriseMembers.PendingOrAcceptedMember[] =>
    data.sort((a, b) => {
      if (memberHasAccepted(a) && memberIsPending(b)) {
        return sortDirection === 'ascending' ? 1 : -1;
      }
      if (memberIsPending(a) && memberHasAccepted(b)) {
        return sortDirection === 'ascending' ? -1 : 1;
      }
      return 0;
    });

  const handleSort = (
    data: EnterpriseMembers.PendingOrAcceptedMember[]
  ): EnterpriseMembers.PendingOrAcceptedMember[] =>
    !sortBy
      ? sortByDefault(data)
      : sortBy === 'email'
      ? sortByEmail(data)
      : sortByAccess(data);

  const sortedUsers = handleSort(members);
  const getCurrentSortDirection = (column: string) =>
    column === sortBy ? sortDirection : 'none';
  const getNextSortDirection = (column: string) =>
    column === sortBy && sortDirection === 'ascending'
      ? 'descending'
      : 'ascending';

  const paginatedUsers = sortedUsers.slice(memberStart - 1, memberEnd);

  const isAdmin = (is_admin: boolean) =>
    is_admin ? t('Admin') : t('General Access');

  const getProductAccess = (access: ProductAccess): string | undefined => {
    const product = getProductBySlug(access.product_slug);
    if (!product) {
      return;
    }
    const accessType = isAdmin(access.access_type === AccessTypes.ADMIN);
    return t('{{ productName }}: {{accessType}}', {
      productName: product.name,
      accessType
    });
  };

  const actionIcons = (member: EnterpriseMembers.PendingOrAcceptedMember) => {
    return (
      <div className={styles.actionIcons}>
        {memberIsPending(member) ? (
          <IconButton
            disabled
            icon="pencil"
            tooltipProps={{ variant: 'info' }}
            label={
              <>
                {t('Edit User')}
                <Offscreen> {member.email}</Offscreen>
              </>
            }
          />
        ) : (
          <IconButton
            icon="pencil"
            tooltipProps={{ variant: 'info' }}
            label={
              <>
                {t('Edit User')}
                <Offscreen> {member.email}</Offscreen>
              </>
            }
            as={Link}
            to={`/user-access/edit-user/${member.user_id}`}
          />
        )}
        {isMailerEnabled && (
          <IconButton
            disabled={memberHasAccepted(member)}
            icon="resend"
            tooltipProps={{ variant: 'info' }}
            label={
              <>
                {t('Resend Invitation')}
                <Offscreen>
                  {' '}
                  {t('to')} {member.email}
                </Offscreen>
              </>
            }
            onClick={() =>
              onResendClick(member as EnterpriseMembers.PendingMember)
            }
          />
        )}
        <IconButton
          disabled={
            memberHasAccepted(member) && member.user_id === currentUserId
          }
          icon="trash"
          tooltipProps={{ variant: 'info' }}
          label={
            <>
              {t('Remove User')}
              <Offscreen> {member.email}</Offscreen>
            </>
          }
          onClick={() => onRemoveClick(member)}
        />
      </div>
    );
  };

  return (
    <div className={styles.userManagementBody}>
      {variant === 'wide' && (
        <Table>
          <TableHead>
            <TableRow>
              <TableHeader
                scope="col"
                sortDirection={getCurrentSortDirection('email')}
                sortAscendingAnnouncement={t('sorted ascending')}
                sortDescendingAnnouncement={t('sorted descending')}
                onSort={() => {
                  setSort(['email', getNextSortDirection('email')]);
                }}
              >
                {t('User')}
              </TableHeader>
              <TableHeader scope="col">{t('Product Access: Role')}</TableHeader>
              <TableHeader
                scope="col"
                sortDirection={getCurrentSortDirection('access')}
                sortAscendingAnnouncement={t('sorted ascending')}
                sortDescendingAnnouncement={t('sorted descending')}
                onSort={() => {
                  setSort(['access', getNextSortDirection('access')]);
                }}
              >
                {t('Access Status')}
              </TableHeader>
              <TableHeader scope="col">{t('Actions')}</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedUsers.map(member => (
              <TableRow key={`${member.email}-tr`}>
                <TableCell>
                  <div className={styles.userCell}>
                    {memberHasAccepted(member) && (
                      <p>
                        {member.first_name} {member.last_name}
                      </p>
                    )}
                    <p>{member.email}</p>
                  </div>
                </TableCell>
                <TableCell>
                  <div>
                    {member.product_access.map(access => {
                      return (
                        !hiddenProductSlugs.includes(
                          access.product_slug as ProductSlugs
                        ) &&
                        !!getProductBySlug(access.product_slug) && (
                          <p key={access.product_slug}>
                            {getProductAccess(access)}
                          </p>
                        )
                      );
                    })}
                    <p>
                      {t('axe Account: {{ admin }}', {
                        admin: isAdmin(member.is_admin)
                      })}
                    </p>
                  </div>
                </TableCell>
                <TableCell>
                  {memberIsPending(member) ? t('Email Sent') : t('Active User')}
                </TableCell>
                <TableCell>{actionIcons(member)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {variant !== 'wide' && (
        <div>
          {paginatedUsers.map(member => (
            <DescriptionList
              key={`${member.email}-dl`}
              collapsed={variant === 'narrow'}
            >
              <DescriptionListItem>
                <DescriptionTerm>{t('User')}</DescriptionTerm>
                <DescriptionDetails>
                  <div>
                    {memberHasAccepted(member) && (
                      <p>
                        {member.first_name} {member.last_name}
                      </p>
                    )}
                    <p>{member.email}</p>
                  </div>
                </DescriptionDetails>
              </DescriptionListItem>
              <DescriptionListItem>
                <DescriptionTerm>{t('Product Access: Role')}</DescriptionTerm>
                <DescriptionDetails>
                  <div>
                    {member.product_access.map(
                      access =>
                        !hiddenProductSlugs.includes(
                          access.product_slug as ProductSlugs
                        ) &&
                        !!getProductBySlug(access.product_slug) && (
                          <p key={access.product_slug}>
                            {getProductAccess(access)}
                          </p>
                        )
                    )}
                    <p>
                      {t('axe Account: {{ admin }}', {
                        admin: isAdmin(member.is_admin)
                      })}
                    </p>
                  </div>
                </DescriptionDetails>
              </DescriptionListItem>
              <DescriptionListItem>
                <DescriptionTerm>{t('Access Status')}</DescriptionTerm>
                <DescriptionDetails>
                  {memberIsPending(member) ? t('Email Sent') : t('Active User')}
                </DescriptionDetails>
              </DescriptionListItem>
              <DescriptionListItem>
                <DescriptionTerm>{t('Actions')}</DescriptionTerm>
                <DescriptionDetails>{actionIcons(member)}</DescriptionDetails>
              </DescriptionListItem>
            </DescriptionList>
          ))}
        </div>
      )}
      <Pagination
        totalItems={totalMembers}
        itemsPerPage={membersPerPage}
        currentPage={currentPage}
        statusLabel={
          <span>
            {variant === 'wide' && t('Showing')} <strong>{memberStart}</strong>{' '}
            - <strong>{memberEnd}</strong> of <strong>{totalMembers}</strong>
          </span>
        }
        onNextPageClick={onNextPageClick}
        onFirstPageClick={onFirstPageClick}
        onPreviousPageClick={onPreviousPageClick}
        onLastPageClick={onLastPageClick}
      />
    </div>
  );
};

export default UserManagementTableLegacy;
