import React from 'react';
import Configuration from '../components/configuration/Configuration';
import { useProducts } from '../../common/contexts/products';
import useMediaQuery from '../../common/hooks/useMediaQuery';
import { useConfiguration } from '../../common/contexts/Configuration';
import { useConfigurationAccess } from '../hooks/useConfigurationAccess';
import { useAuthContext } from '../../common/contexts/auth';
import { useFeatureFlags } from '../../common/contexts/featureFlags';
import {
  INTEGRATION_PRODUCT_SLUGS,
  SupportedIntegrationProductSlugs,
  ProductSlugs
} from '../../common/constants';

const { PARAGON_PROJECT_ID } = process.env;

const ConfigurationPage: React.FC = () => {
  const narrow = useMediaQuery('(max-width: 31.25rem)');
  const { loading: authLoading, error: authError } = useAuthContext();
  const { loading: configurationLoading, loadingError: configurationError } =
    useConfiguration();
  const {
    loading: productsLoading,
    error: productsError,
    getProductBySlug
  } = useProducts();
  const {
    loading: areFeatureFlagsLoading,
    loadError: featureFlagsLoadError,
    featureFlags
  } = useFeatureFlags();

  const loading = !!(
    configurationLoading ||
    productsLoading ||
    areFeatureFlagsLoading ||
    authLoading
  );

  const error =
    configurationError || productsError || authError || featureFlagsLoadError
      ? new Error(authError)
      : null || null;

  const { checkUserAccessToProduct, userHasAccessToConfiguration } =
    useConfigurationAccess();

  const hasIntegrationsV1 = featureFlags.some(
    flag => flag.id === 'integrations_v1' && flag.state === true
  );
  const enabledIntegrationProductSlugs: SupportedIntegrationProductSlugs[] =
    INTEGRATION_PRODUCT_SLUGS.filter(slug =>
      checkUserAccessToProduct(slug as unknown as ProductSlugs)
    );
  const canAccessIntegrationConfiguration =
    enabledIntegrationProductSlugs.length > 0 &&
    hasIntegrationsV1 &&
    !!PARAGON_PROJECT_ID;

  return (
    <Configuration
      narrow={narrow}
      loading={loading}
      error={error}
      canAccessIntegrationConfiguration={canAccessIntegrationConfiguration}
      enabledIntegrationProductSlugs={enabledIntegrationProductSlugs}
      getProductBySlug={getProductBySlug}
      hasAccessToProduct={checkUserAccessToProduct} // This is how I can check for integration product access
      showNoActiveSubscriptionMessage={!userHasAccessToConfiguration}
    />
  );
};

export default ConfigurationPage;
