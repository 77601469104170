import React from 'react';
import {
  Alert,
  AlertActions,
  AlertContent,
  Button
} from '@deque/cauldron-react';
import { useTranslation } from 'react-i18next';

import analyticsInstances, {
  getFallbackAnalyticsInstanceId
} from '../../../../common/analyticsInstances';
import { useAuthContext } from '../../../../common/contexts/auth';
import { useGlobalToast } from '../../../../common/contexts/globalToast';
import { useEnterprises } from '../../../../common/contexts/enterprises';
import { deleteIntegrationTemplate } from '../../../../common/api-client';
import useIntegrationTemplates from '../../../hooks/useIntegrationTemplates';
import { SupportedIntegrationProductSlugs } from '../../../../common/constants';

export interface DeleteTemplateAlertProps {
  templateId: string;
  setShowConfirmDelete: (show: boolean) => void;
  integrationProductSlug: SupportedIntegrationProductSlugs;
  issueType: string;
  project: string;
  templateName: string;
}

const DeleteTemplateAlert = ({
  templateId,
  setShowConfirmDelete,
  integrationProductSlug,
  project,
  issueType,
  templateName
}: DeleteTemplateAlertProps) => {
  const { t } = useTranslation();
  const { setContents } = useGlobalToast();
  const analytics =
    analyticsInstances[getFallbackAnalyticsInstanceId(integrationProductSlug)];

  const { user } = useAuthContext();
  const { activeEnterprise } = useEnterprises();
  const { templates, mutateTemplates } = useIntegrationTemplates({
    integrationProductSlug
  });
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const token = user!.token;

  const handleDelete = async () => {
    analytics.integrationTemplateDeleteClick({
      integration: integrationProductSlug,
      project,
      issueType,
      template: templateName
    });

    if (!activeEnterprise) {
      analytics.integrationTemplateDeleteFail({
        integration: integrationProductSlug,
        project,
        issueType,
        template: templateName
      });
      setContents(t('No active enterprise found'), 'error');
      return;
    }

    try {
      const res = await deleteIntegrationTemplate(
        token,
        activeEnterprise.id,
        templateId
      );
      if (res.deleted) {
        analytics.integrationTemplateDeleteSuccess({
          integration: integrationProductSlug,
          project,
          issueType,
          template: templateName
        });
        mutateTemplates([
          ...templates.filter(template => template.id !== templateId)
        ]);
        setContents(t('Template deleted successfully'), 'confirmation');
        return;
      }
      analytics.integrationTemplateDeleteFail({
        integration: integrationProductSlug,
        project,
        issueType,
        template: templateName
      });
      setContents(t('Failed to delete template'), 'error');
    } catch (error) {
      analytics.integrationTemplateDeleteFail({
        integration: integrationProductSlug,
        project,
        issueType,
        template: templateName
      });
      const errorMessage: string = (error as Error).message;
      setContents(errorMessage, 'error');
    } finally {
      setShowConfirmDelete(false);
    }
  };

  return (
    <Alert
      heading={t('Delete Template')}
      show
      onClose={() => setShowConfirmDelete(false)}
      variant="warning"
    >
      <AlertContent>
        {t(
          'Are you sure you want to delete this template? This action cannot be undone.'
        )}
      </AlertContent>
      <AlertActions>
        <Button variant="error" onClick={handleDelete}>
          {t('Delete')}
        </Button>
        <Button variant="secondary" onClick={() => setShowConfirmDelete(false)}>
          {t('Cancel')}
        </Button>
      </AlertActions>
    </Alert>
  );
};

export default DeleteTemplateAlert;
