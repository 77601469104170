import type { v2 } from '@deque/billing-service-client';
import React from 'react';
import { Trans } from 'react-i18next';

import LicenseBox from './LicenseBox';

export interface ReviewStepProps {
  currentLicenseCount: number;
  licenseChangeCount: number;
  term: v2.SubscriptionPricing['interval'];
  price: number;
  totalLicensesValue: number;
  enterpriseName?: string;
}

const ReviewStep = ({
  currentLicenseCount,
  licenseChangeCount,
  term,
  price,
  totalLicensesValue,
  enterpriseName
}: ReviewStepProps) => (
  <>
    <Trans parent="p">
      Take a moment to review the changes and ensure everything looks correct
      with the changes in your licenses.
    </Trans>
    <LicenseBox
      currentLicenseCount={currentLicenseCount}
      licenseChangeCount={licenseChangeCount}
      term={term}
      price={price}
      totalLicensesValue={totalLicensesValue}
      enterpriseName={enterpriseName}
    />
  </>
);

export default ReviewStep;
