import React, { FC } from 'react';
import { Step, Stepper } from '@deque/cauldron-react';
import { Trans, useTranslation } from 'react-i18next';
import useMediaQuery from '../../../../../common/hooks/useMediaQuery';
import { useIntegrationTemplateWizardSteps } from '../../../../contexts/IntegrationTemplateWizardSteps';
import getStepStatus, {
  getCapitalizedStepStatus
} from '../../../../../common/utils/get-step-status';
import styles from './TemplateStepper.css';

const TemplateStepper: FC = () => {
  const { t } = useTranslation();
  const narrow = useMediaQuery('(max-width: 37.5rem)');
  const { currentStep, steps } = useIntegrationTemplateWizardSteps();

  const stepsContent = steps.map(({ header }, index) => {
    const stepStatus = getStepStatus(currentStep, index);
    const stepStatusCapitalized = getCapitalizedStepStatus(t, stepStatus);

    if (!narrow) {
      return (
        <Step status={stepStatus} key={header}>
          {header}
        </Step>
      );
    }

    const tooltip = (
      <Trans>
        <div>
          Step {{ i: index + 1 }}: {{ header }}
        </div>
        <div>Status: {{ stepStatusCapitalized }}</div>
      </Trans>
    );

    return (
      <Step
        status={stepStatus}
        key={header}
        tooltip={tooltip}
        tooltipText={`${header} ${stepStatus}`}
      />
    );
  });

  return (
    <Stepper className={narrow ? styles.narrowStepper : styles.stepper}>
      {stepsContent}
    </Stepper>
  );
};

export default TemplateStepper;
