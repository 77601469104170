import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import type { StepMap } from '../types';
import getTemplateSteps from '../utils/get-template-steps';
import { useLoadedConfiguration } from '../../common/contexts/Configuration';
import { SupportedIntegrationProductSlugs } from '../../common/constants';

type TemplateWizardStepsContext = {
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  steps: StepMap[];
  isStepDataLoading: boolean;
  setIsStepDataLoading: (state: boolean) => void;
};

const TemplateWizardStepsContext = createContext<TemplateWizardStepsContext>({
  currentStep: 0,
  setCurrentStep: () => {
    throw new Error('`IntegrationTemplateWizardSteps` not mounted');
  },
  steps: [],
  isStepDataLoading: false,
  setIsStepDataLoading: () => undefined
});

type IntegrationTemplateWizardStepsProps = {
  children: ReactNode;
  isEditMode?: boolean;
  integrationProductSlug: SupportedIntegrationProductSlugs;
};

export const IntegrationTemplateWizardSteps: FC<
  IntegrationTemplateWizardStepsProps
> = ({ children, isEditMode = false, integrationProductSlug }) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [isStepDataLoading, setIsStepDataLoading] = useState(false);
  const { settings } = useLoadedConfiguration();

  const steps = useMemo(
    () =>
      getTemplateSteps({
        t,
        isPriorityEnabled: true,
        integrationProductSlug,
        instanceUrl: settings.jiraIntegration?.instanceBaseUrl.value || ''
      }),
    [isEditMode, integrationProductSlug, settings.jiraIntegration]
  );

  useEffect(() => {
    if (isEditMode) {
      setCurrentStep(steps.length - 1);
    }
  }, [isEditMode, steps]);

  const contextValue = useMemo(
    () => ({
      currentStep,
      setCurrentStep,
      steps,
      isStepDataLoading,
      setIsStepDataLoading
    }),
    [
      currentStep,
      setCurrentStep,
      steps,
      isStepDataLoading,
      setIsStepDataLoading
    ]
  );

  return (
    <TemplateWizardStepsContext.Provider value={contextValue}>
      {children}
    </TemplateWizardStepsContext.Provider>
  );
};

export const useIntegrationTemplateWizardSteps =
  (): TemplateWizardStepsContext => useContext(TemplateWizardStepsContext);
