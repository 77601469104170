import getIntegrationProductName from '../../utils/get-integration-product-name-from-slug';
import { paragon as integrationClient } from '@useparagon/connect';
import { SupportedIntegrationProductSlugs } from '../../constants';
import * as jira from './jira';

export interface IntegrationsRequestArgs {
  integrationType: string;
  path: string;
  init?: RequestInit;
  getToken: () => Promise<string | undefined>;
}

export const config = {
  integrationType: getIntegrationProductName(
    SupportedIntegrationProductSlugs.jiraIntegration
  ),
  init: {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    body: undefined
  }
};

export const integrationsRequest = async <T>({
  integrationType,
  path,
  init = {},
  getToken
}: IntegrationsRequestArgs): Promise<T> => {
  await getToken();
  return integrationClient.request<T>(
    integrationType || config.integrationType,
    path,
    {
      ...config.init,
      ...init
    }
  ) as Promise<T>;
};

export default { jira };
