import React, {
  ReactElement,
  useEffect,
  useLayoutEffect,
  useMemo
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import Accordion, { AccordionItem } from 'react-wai-accordion';
import { Icon } from '@deque/cauldron-react';
import useFAQs from '../hooks/useFAQs';
import InstallExtension from '../containers/InstallExtension';
import ContentToast from '../../common/components/ContentToast';
import sendPlanChange from '../../common/utils/send-plan-change-to-extension';
import styles from './Home.css';
import PageTitle from '../../common/components/PageTitle';
import { PRODUCT_URLS, ProductSlugs } from '../../common/constants';
import { useFeatureFlagState } from '../../common/contexts/featureFlags';
import { useAxeDevtoolsProAnalytics } from '../../common/contexts/analytics';

const Home = (): ReactElement => {
  const { t } = useTranslation();
  const location = useLocation();
  const hasProWalkthrough = useFeatureFlagState('pro_walkthrough_v1');
  const {
    purchaseSuccess,
    invitationAccepted,
    alreadyAcceptedInvite,
    invitationRejected
  } = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return {
      purchaseSuccess: params.get('purchase-success') === 'true',
      invitationAccepted: params.get('invitation-accepted') === 'true',
      invitationRejected: params.get('invitation-rejected') === 'true',
      alreadyAcceptedInvite: params.get('already-accepted-invite') === 'true'
    };
  }, [location]);
  const faqs = useFAQs();
  const analytics = useAxeDevtoolsProAnalytics();

  useEffect(() => {
    analytics.viewWelcomePage(window.location.href);
  }, []);

  useLayoutEffect(() => {
    // Add the Welcome class to the body so we can "cancel" out the max-widths.
    // This makes it possible for the gradient background to stretch full-width
    document.body.classList.add('Welcome');
    return () => document.body.classList.remove('Welcome');
  }, []);

  useEffect(() => {
    if (purchaseSuccess) {
      // notify the extension that the user's plan has changed
      sendPlanChange();
    }
  }, []);

  return (
    <div className={styles.wrap}>
      <PageTitle title={t('Welcome')} />
      {purchaseSuccess && (
        <ContentToast show type="confirmation">
          {t('Your payment was successful!')}
        </ContentToast>
      )}
      {invitationAccepted && (
        <ContentToast show type="confirmation">
          {t('You have accepted your invitation!')}
        </ContentToast>
      )}
      {invitationRejected && (
        <ContentToast show type="caution">
          {t('You have declined your invitation!')}
        </ContentToast>
      )}
      {alreadyAcceptedInvite && (
        <ContentToast show type="caution">
          {t('You have already accepted your invitation!')}
        </ContentToast>
      )}
      <section className={styles.topSection}>
        <h1>{t('Welcome to axe DevTools Extension')}</h1>
        <Trans>
          <p>
            Join the{' '}
            <a href="https://accessibility.deque.com/axe-community">
              axe Slack Community
            </a>{' '}
            and get the most out of your axe products. Ask questions and provide
            feedback to the Deque team members. Connect with other axe users.
          </p>
        </Trans>
        <InstallExtension />
      </section>
      <section className={styles.videoFaq}>
        <div className={styles.video}>
          <h2>{t('Watch how axe DevTools Extension works for you')}</h2>
          <iframe
            title={t('Getting started with axe DevTools Extension')}
            src="https://www.youtube.com/embed/iRGB40c_YJc"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
        <div className={styles.faq}>
          {hasProWalkthrough && (
            <>
              <h2>{t('Ready to get started?')}</h2>
              <p>
                {t(
                  'Follow along with our step by step walkthrough of the extension. We will show you how the product works so you can start fixing accessibility issues.'
                )}
              </p>
              <Link
                to={`${
                  PRODUCT_URLS[ProductSlugs.axeDevToolsExtension].root
                }/walkthrough?utm_source=webapp_welcome&utm_medium=referral&utm_campaign=start_walkthrough`}
                className={`Button--primary ${styles.startWalkthrough}`}
              >
                {t('Start walkthrough')}
              </Link>
            </>
          )}
          <h2>{t('Frequently Asked Questions')}</h2>
          <div className={styles.hrDot} />
          <Accordion>
            {faqs.map(({ answer, question }, index) => (
              <AccordionItem
                key={index}
                title={
                  <>
                    <span>{question}</span>
                    <Icon type="chevron-right" />
                  </>
                }
              >
                {answer}
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </section>
    </div>
  );
};

export default Home;
