import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import TagManager from 'react-gtm-module';
import { Redirect, useHistory } from 'react-router-dom';
import removeTrailingSlash from 'remove-trailing-slash';
import { listProductAccess } from '@deque/billing-utils';

import {
  getRealmInfo,
  RealmInfo,
  signUp,
  SignedUpUser
} from '../../common/api-client';
import SignUpForm from '../components/SignUpForm';
import validate, { Errors } from '../../common/utils/validate-user-form';
import {
  isValidRedirectURI,
  isSupportRedirect
} from '../../common/utils/validate-redirect';
import { useAuthContext } from '../../common/contexts/auth';
import { useFeatureFlagState } from '../../common/contexts/featureFlags';
import useMediaQuery from '../../common/hooks/useMediaQuery';
import { useAnalyticsInstance } from '../../common/contexts/analytics';
import { getFallbackAnalyticsInstanceId } from '../../common/analyticsInstances';
import {
  ProductSlugs,
  getProductUrl,
  isKeyOfProductUrls,
  originalRedirectQueryParam
} from '../../common/constants';
import SignUpDetails from '../components/SignUpDetails';
import styles from './SignUp.css';
import { useEnterprises } from '../../common/contexts/enterprises';
import getProductFromUrl from '../utils/get-product-from-url';

export type IDP = 'google' | 'github';

const SignUp = () => {
  const { t } = useTranslation();
  const narrow = useMediaQuery('(max-width: 53.125rem)');
  const mobileViewPort = useMediaQuery('(max-width: 28.75rem)');
  const { billingUser, login } = useAuthContext();
  const { activeEnterprise, loading: enterprisesLoading } = useEnterprises();
  const history = useHistory();
  const { product: productParam, redirect_uri = '' } = queryString.parse(
    history.location.search,
    {
      // This is the default, but we're being explicit here.
      decode: true
    }
  );
  const isSupportRegistrationAllowed = useFeatureFlagState(
    'support_registration'
  );

  const productSlug =
    productParam ||
    getProductFromUrl(typeof redirect_uri === 'string' ? redirect_uri : '');
  const productAnalyticsId = getFallbackAnalyticsInstanceId(productSlug);
  const productUrlsKey = isKeyOfProductUrls(productSlug) && productSlug;
  const analytics = useAnalyticsInstance(productAnalyticsId);

  const [submitted, setSubmitted] = useState(false);
  const [newUser, setNewUser] = useState<SignedUpUser>();
  const [loading, setLoading] = useState(true);
  const [redirectLoading, setRedirectLoading] = useState(true);
  const [realmInfo, setRealmInfo] = useState<RealmInfo>();
  const [errors, setErrors] = useState<Errors>({});
  const [redirectURI, setRedirectURI] = useState<string>(
    window.location.origin
  );
  const focusTarget = useRef<HTMLDivElement>(null);
  const firstName = useRef<HTMLInputElement>(null);
  const lastName = useRef<HTMLInputElement>(null);
  const email = useRef<HTMLInputElement>(null);
  const company = useRef<HTMLInputElement>(null);
  const password = useRef<HTMLInputElement>(null);
  const confirmPassword = useRef<HTMLInputElement>(null);

  const { isMobileReadOnly, isMobileFreeTrial } = useMemo(() => {
    return {
      isMobileReadOnly: productSlug === ProductSlugs.axeDevToolsMobile,
      isMobileFreeTrial: redirectURI.includes(
        '/axe-devtools-mobile/get-started'
      )
    };
  }, [productSlug, redirectURI]);

  useEffect(() => {
    const getData = async () => {
      try {
        const info = await getRealmInfo();
        setRealmInfo(info);
      } catch {
        setErrors({
          alert: t('We were unable to fetch password requirements')
        });
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  useEffect(() => {
    analytics.viewSignUp({ href: window.location.href });
  }, []);

  useEffect(() => {
    if (!focusTarget.current || !submitted) {
      return;
    }

    // focus the post-form-submit loader / focus the post-loading success message
    focusTarget.current.focus();
  }, [submitted, loading]);

  useEffect(() => {
    if (enterprisesLoading) {
      return;
    }

    // redirect to welcome page if user has paid or trialing access, otherwise redirect to plans page
    // If redirect page is undefined, navigate to "/"
    if (billingUser && productUrlsKey) {
      const productAccess = listProductAccess(
        activeEnterprise
          ? activeEnterprise.subscriptions
          : billingUser.subscriptions
      );
      const hasPaidOrTrialingAccess = ['paid', 'trialing'].includes(
        productAccess[productUrlsKey]
      );
      const signedInUserRedirect = hasPaidOrTrialingAccess
        ? getProductUrl(productUrlsKey, 'welcome')
        : getProductUrl(productUrlsKey, 'plans');

      if (
        signedInUserRedirect &&
        (!activeEnterprise ||
          // TODO: Revisit when we support multiple enterprises
          billingUser.enterprises[0]?.subscriptions.some(
            subscription => subscription.product_slug === productUrlsKey
          ))
      ) {
        setRedirectURI(signedInUserRedirect);
      }
    } else if (redirect_uri && typeof redirect_uri === 'string') {
      if (isValidRedirectURI(redirect_uri)) {
        // redirect to `redirect_uri` if it is valid
        setRedirectURI(redirect_uri);
      }
      if (isSupportRedirect(redirect_uri) && isSupportRegistrationAllowed) {
        // override redirect uri if it is support domain with new param
        setRedirectURI(
          `${
            window.location.origin
          }?${originalRedirectQueryParam}=${encodeURIComponent(redirect_uri)}`
        );
      }
    }
    setRedirectLoading(false);
  }, [enterprisesLoading, billingUser, redirect_uri, productUrlsKey]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Ensure we have all of our input refs
    /* istanbul ignore if */
    if (
      !firstName.current ||
      !lastName.current ||
      !email.current ||
      !company.current ||
      !password.current ||
      !confirmPassword.current
    ) {
      setErrors({
        alert: t('Something went wrong. Please try again.')
      });
      return;
    }

    const newErrors = validate(
      {
        firstName: firstName.current,
        lastName: lastName.current,
        email: email.current,
        company: company.current,
        password: password.current,
        confirmPassword: confirmPassword.current
      },
      realmInfo?.passwordPolicy || {},
      t
    );

    setErrors(newErrors);
    if (Object.keys(newErrors).length) {
      // focus the first erroneous input
      return;
    }

    setLoading(true);
    setSubmitted(true);
    try {
      const signupResult = await signUp({
        isMobileReadOnly,
        isMobileFreeTrial,
        redirectURI,
        // typescript doesn't follow the codepath of setErrors so it
        // doesn't know that these refs are guaranteed to have a value
        firstName: firstName.current?.value,
        lastName: lastName.current?.value,
        company: company.current?.value,
        email: email.current?.value,
        password: password.current?.value,
        passwordConfirmation: confirmPassword.current?.value
      });
      setNewUser(signupResult);
      TagManager.dataLayer({
        dataLayer: {
          event: 'axeSignup'
        }
      });
      try {
        await analytics.identify({ user: signupResult });
        await analytics.signUp();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error); // we just log and ignore the error here and not intimate/block the user about analytics error
      }
    } catch (error) {
      const errorMessage: string = (error as Error).message;

      // Show specific errors for blacklisted/invalid email addresses (see #1573).
      if (errorMessage.includes('competitor')) {
        setErrors({ alert: t('Account could not be created.') });
      } else if (errorMessage.includes('disposable')) {
        setErrors({
          alert: t(
            'We do not allow signups from disposable email accounts, please use a real email account to sign up.'
          )
        });
      } else if (errorMessage.includes('Invalid email')) {
        setErrors({
          alert: t('The provided email “{{ email }}” is not valid.', {
            email: email.current?.value
          })
        });
      } else {
        setErrors({ alert: errorMessage });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = (idp?: IDP) => {
    const url = new URL(redirectURI);
    if (idp) {
      url.searchParams.set('socialProvider', idp);
    }
    login(removeTrailingSlash(url.toString()));
  };

  if (billingUser && !enterprisesLoading && !redirectLoading) {
    const { origin, pathname, search } = new URL(
      redirectURI,
      window.location.origin
    );
    if (origin !== window.location.origin) {
      window.location.href = redirectURI;
      return null;
    }
    return <Redirect to={`${pathname}${search}`} />;
  }

  return (
    <div className={styles.wrapper}>
      <SignUpDetails
        isNarrow={narrow}
        isMobileViewPort={mobileViewPort}
        productSlug={productAnalyticsId}
      />
      <SignUpForm
        loading={loading || (!!billingUser && enterprisesLoading)}
        onSubmit={onSubmit}
        errors={errors}
        realmInfo={realmInfo}
        firstName={firstName}
        lastName={lastName}
        email={email}
        company={company}
        password={password}
        confirmPassword={confirmPassword}
        newUser={newUser}
        focusTarget={focusTarget}
        handleLogin={handleLogin}
        product={productAnalyticsId}
      />
    </div>
  );
};

export default SignUp;
