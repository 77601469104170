import type { ConnectUser } from '@useparagon/connect';
import React, { ReactElement, useMemo } from 'react';
import type { v2 } from '@deque/billing-service-client';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import {
  hasPlanName,
  PRODUCT_URLS,
  ProductSlugs,
  SupportedIntegrationProductSlugs
} from '../../common/constants';
import { isIntegrationEnabled } from './configuration/IntegrationConfigurations';
import { useIntegrations } from '../../common/contexts/Integrations';
import { useServerInfo } from '../../common/contexts/serverInfo';
import { useProducts } from '../../common/contexts/products';
import ProductCard from './V2ProductCard';
import PlanName from './PlanName';
import Products from './Products';
import styles from './ActiveProducts.css';

export interface ActiveProductsProps {
  subscriptions: Array<v2.UserSubscription | v2.EnterpriseSubscription>;
  isAdmin?: boolean;
  enterpriseAdmins?: string[];
  showFooter?: boolean;
}

export const getProductResources = (
  t: TFunction,
  getProductBySlug: (slug: ProductSlugs) => v2.Product | undefined,
  isSelfProvisioningEnabled = false,
  integrationUser: ConnectUser | null,
  subscriptions: Array<v2.UserSubscription | v2.EnterpriseSubscription>
) => {
  const monitorSubscription = subscriptions.find(
    sub => sub.product_slug === ProductSlugs.axeMonitor
  ) as v2.EnterpriseSubscription | undefined;

  return {
    [ProductSlugs.axeDevToolsExtension]: [
      ...(isSelfProvisioningEnabled
        ? [
            {
              name: t(`{{productName}} Plans`, {
                productName: getProductBySlug(ProductSlugs.axeDevToolsExtension)
                  ?.name
              }),
              url: PRODUCT_URLS[ProductSlugs.axeDevToolsExtension].plans
            }
          ]
        : []),
      {
        name: t(`{{productName}} Home`, {
          productName: getProductBySlug(ProductSlugs.axeDevToolsExtension)?.name
        }),
        url: PRODUCT_URLS[ProductSlugs.axeDevToolsExtension].root
      },
      {
        name: t(`{{productName}} Docs`, {
          productName: getProductBySlug(ProductSlugs.axeDevToolsExtension)?.name
        }),
        url: PRODUCT_URLS[ProductSlugs.axeDevToolsExtension].docs,
        openInNewTab: true
      }
    ],
    [ProductSlugs.axeDevToolsMobile]: [
      {
        name: t(`Dashboard`),
        url: PRODUCT_URLS[ProductSlugs.axeDevToolsMobile].dashboard,
        openInNewTab: true
      },
      {
        name: t(`{{productName}} Analyzer Docs`, {
          productName: getProductBySlug(ProductSlugs.axeDevToolsMobile)?.name
        }),
        url: PRODUCT_URLS[ProductSlugs.axeDevToolsMobile].docsAnalyzer,
        openInNewTab: true
      },
      {
        name: t(`{{productName}} SDKs Docs`, {
          productName: getProductBySlug(ProductSlugs.axeDevToolsMobile)?.name
        }),
        url: PRODUCT_URLS[ProductSlugs.axeDevToolsMobile].docsSDK,
        openInNewTab: true
      },
      {
        name: t(`Sample Android App`),
        url: 'https://github.com/dequelabs/axe-devtools-android-sample-app',
        openInNewTab: true
      },
      {
        name: t(`Sample iOS App`),
        url: 'https://github.com/dequelabs/axe-devtools-ios-sample-app',
        openInNewTab: true
      }
    ],
    [ProductSlugs.axeDevToolsWatcher]: [
      {
        name: t(`{{productName}} Home`, {
          productName: getProductBySlug(ProductSlugs.axeDevToolsWatcher)?.name
        }),
        url: PRODUCT_URLS[ProductSlugs.axeDevToolsWatcher].projects
      },
      {
        name: t(`{{productName}} Quickstart Instructions`, {
          productName: getProductBySlug(ProductSlugs.axeDevToolsWatcher)?.name
        }),
        url: PRODUCT_URLS[ProductSlugs.axeDevToolsWatcher].startup
      },
      {
        name: t(`{{productName}} Full Documentation`, {
          productName: getProductBySlug(ProductSlugs.axeDevToolsWatcher)?.name
        }),
        url: PRODUCT_URLS[ProductSlugs.axeDevToolsWatcher].docs,
        openInNewTab: true
      }
    ],
    [ProductSlugs.axeDevToolsHTML]: [
      {
        name: t(`{{productName}} Docs`, {
          productName: getProductBySlug(ProductSlugs.axeDevToolsHTML)?.name
        }),
        url: 'https://docs.deque.com/devtools-html',
        openInNewTab: true
      },
      {
        name: t(`{{productName}} Home`, {
          productName: getProductBySlug(ProductSlugs.axeDevToolsHTML)?.name
        }),
        url: PRODUCT_URLS[ProductSlugs.axeDevToolsExtension].root
      },
      ...(!isSelfProvisioningEnabled
        ? [
            {
              name: t(`{{productName}} Plans`, {
                productName: getProductBySlug(ProductSlugs.axeDevToolsHTML)
                  ?.name
              }),
              url: PRODUCT_URLS[ProductSlugs.axeDevToolsExtension].plans
            }
          ]
        : []),
      {
        name: t(`{{agora}} Artifact Repository`, {
          agora: 'Agora'
        }),
        url: 'https://agora.dequecloud.com/artifactory/webapp',
        openInNewTab: true
      }
    ],
    [ProductSlugs.axeLinter]: [
      {
        name: t(`{{productName}} Plans`, {
          productName: getProductBySlug(ProductSlugs.axeLinter)?.name
        }),
        url: PRODUCT_URLS[ProductSlugs.axeLinter].plans,
        openInNewTab: true
      },
      {
        name: t(`{{productName}} Home`, {
          productName: getProductBySlug(ProductSlugs.axeLinter)?.name
        }),
        url: PRODUCT_URLS[ProductSlugs.axeLinter].docs,
        openInNewTab: true
      }
    ],
    [ProductSlugs.dequeUniversity]: [
      ...(isSelfProvisioningEnabled
        ? [
            {
              name: t(`{{productName}} Plans`, {
                productName: getProductBySlug(ProductSlugs.dequeUniversity)
                  ?.name
              }),
              url: PRODUCT_URLS[ProductSlugs.dequeUniversity].plans
            }
          ]
        : []),
      {
        name: t(`{{productName}} Home`, {
          productName: getProductBySlug(ProductSlugs.dequeUniversity)?.name
        }),
        url: PRODUCT_URLS[ProductSlugs.dequeUniversity].docs,
        openInNewTab: true
      }
    ],
    [ProductSlugs.axeSpider]: [
      {
        name: t(`{{productName}} Home`, {
          productName: getProductBySlug(ProductSlugs.axeSpider)?.name
        }),
        url: PRODUCT_URLS[ProductSlugs.axeSpider].root
      }
    ],
    [ProductSlugs.jiraIntegration]: [
      {
        name: t(`Manage Connection`),
        url: PRODUCT_URLS[ProductSlugs.jiraIntegration].root as string
      },
      ...(isIntegrationEnabled(
        SupportedIntegrationProductSlugs.jiraIntegration,
        integrationUser
      )
        ? [
            {
              name: t(`Manage Integration Templates`),
              url: PRODUCT_URLS[ProductSlugs.jiraIntegration]
                .templates as string,
              openInNewTab: false
            }
          ]
        : []),
      {
        name: t(`{{productName}} Docs`, {
          productName: getProductBySlug(ProductSlugs.jiraIntegration)?.name
        }),
        url: PRODUCT_URLS[ProductSlugs.jiraIntegration].docs as string,
        openInNewTab: true
      }
    ],
    [ProductSlugs.axeMonitor]: [
      {
        name: t(`{{productName}} Docs`, {
          productName: getProductBySlug(ProductSlugs.axeMonitor)?.name
        }),
        url: PRODUCT_URLS[ProductSlugs.axeMonitor].docs as string,
        openInNewTab: true
      },
      ...(monitorSubscription && monitorSubscription.instance_url
        ? [
            {
              name: t(`{{productName}} Home`, {
                productName: getProductBySlug(ProductSlugs.axeMonitor)?.name
              }),
              url: monitorSubscription.instance_url,
              openInNewTab: true
            }
          ]
        : [])
    ]
  };
};

const ActiveProducts = ({
  subscriptions,
  isAdmin = false,
  enterpriseAdmins = [],
  showFooter = false
}: ActiveProductsProps): ReactElement => {
  const { t } = useTranslation();
  const { getProductBySlug } = useProducts();
  const { serverInfo, loading: serverInfoLoading } = useServerInfo();
  const { integrationUser } = useIntegrations();

  const dequeUniversitySubscription = subscriptions.find(
    sub => sub.product_slug === ProductSlugs.dequeUniversity
  );
  const activeSubscriptions = subscriptions.filter(
    sub => sub.product_slug !== ProductSlugs.dequeUniversity
  );

  const resources = useMemo(
    () =>
      getProductResources(
        t,
        getProductBySlug,
        !serverInfoLoading && serverInfo?.isSelfProvisioningEnabled,
        integrationUser,
        subscriptions
      ),
    [
      t,
      getProductBySlug,
      serverInfo,
      serverInfoLoading,
      integrationUser,
      subscriptions
    ]
  );

  return (
    <Products
      heading={t('Active Product Subscriptions')}
      showFooter={showFooter}
    >
      {!!enterpriseAdmins.length && (
        <ProductCard title={'axe ' + t('Account')} admins={enterpriseAdmins} />
      )}
      {activeSubscriptions.map(subscription => (
        <ProductCard
          key={subscription.id}
          title={getProductBySlug(subscription.product_slug)?.name as string}
          productSlug={subscription.product_slug as ProductSlugs}
          planName={
            hasPlanName(subscription.product_slug) && (
              <PlanName subscription={subscription} />
            )
          }
          resources={
            resources[subscription.product_slug as keyof typeof resources]
          }
        />
      ))}
      <ProductCard
        title={getProductBySlug(ProductSlugs.dequeUniversity)?.name as string}
        planName={
          !!dequeUniversitySubscription && (
            <PlanName subscription={dequeUniversitySubscription} />
          )
        }
        resources={resources[ProductSlugs.dequeUniversity]}
      >
        {isAdmin && (
          <p className={styles.admins}>
            {t(
              'Contact training@deque.com to cancel or make changes to your plan.'
            )}
          </p>
        )}
      </ProductCard>
    </Products>
  );
};

export default ActiveProducts;
