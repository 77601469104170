import { Button, FieldWrap, TextField } from '@deque/cauldron-react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ContentToast from '../../common/components/ContentToast';
import ScrimmedLoader from '../../common/components/ScrimmedLoader';
import { AuthUser } from '../../common/contexts/auth';
import { Errors } from '../containers/InvoiceRequest';
import styles from './InvoiceRequestForm.css';
import PostSubmitSuccess from './PostSubmitSuccess';
import { useProducts } from '../../common/contexts/products';
import { ProductSlugs } from '../../common/constants';
import getPrices from '../../common/utils/get-prices';
import getPlans from '../../common/utils/get-plans';
import { useFeatureFlagState } from '../../common/contexts/featureFlags';
import { useServerInfo } from '../../common/contexts/serverInfo';

type InputRef = React.RefObject<HTMLInputElement>;

export interface Props {
  loading: boolean;
  errors: Errors;
  success: boolean;
  user?: AuthUser;
  productNameRef: InputRef;
  quantity: InputRef;
  firstName: InputRef;
  lastName: InputRef;
  email: InputRef;
  specialRequests: InputRef;
  orgName: InputRef;
  orgEmail: InputRef;
  address1: InputRef;
  address2: InputRef;
  city: InputRef;
  state: InputRef;
  zip: InputRef;
  country: InputRef;
  poNumber: InputRef;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

const InvoiceRequestForm = ({
  loading,
  errors,
  user,
  success,
  productNameRef,
  quantity,
  firstName,
  lastName,
  email,
  specialRequests,
  orgName,
  orgEmail,
  address1,
  address2,
  city,
  state,
  zip,
  country,
  poNumber,
  onSubmit
}: Props) => {
  const { t } = useTranslation();
  const { products } = useProducts();
  const { serverInfo } = useServerInfo();
  const hasDisplayInvoiceRequestProduct = useFeatureFlagState(
    'display_invoice_request_product'
  );

  const searchParams = new URLSearchParams(window.location.search);
  const productParam = searchParams.get('product');
  const planParam = searchParams.get('plan');
  const productSlug =
    productParam &&
    Object.values(ProductSlugs).includes(productParam as ProductSlugs)
      ? productParam
      : ProductSlugs.axeDevToolsExtension;
  const { product, planSlugs } = getPlans(products, productSlug);
  const planSlug =
    planParam && planSlugs && Object.values(planSlugs).includes(planParam)
      ? planParam
      : undefined;
  let productName = product?.name;
  const purchaseSearchParams = new URLSearchParams();
  if (productSlug) {
    purchaseSearchParams.set('product', productSlug);
    if (planSlug) {
      purchaseSearchParams.set('plan', planSlug);
      const { yearlyPrice, monthlyPrice } = getPrices(
        products,
        productSlug,
        planSlug
      );
      const planName = yearlyPrice?.nickname || monthlyPrice?.nickname || '';
      if (planName) {
        productName = `${productName} (${planName})`;
      }
    }
  }
  const purchaseLink = `/purchase?${purchaseSearchParams}`;

  return success ? (
    <PostSubmitSuccess heading={t('Invoice request successfully sent')}>
      <p>
        {t(
          'Your invoice request has been sent to a Deque associate. An invoice will be sent shortly.'
        )}
      </p>
    </PostSubmitSuccess>
  ) : (
    <form noValidate onSubmit={onSubmit} className={styles.invoiceRequestForm}>
      {errors.alert && (
        <ContentToast type="caution" show>
          {errors.alert}
        </ContentToast>
      )}
      {loading && <ScrimmedLoader role="alert" label={t('Loading')} />}
      <h1>{t('Purchase Order Invoice Request')}</h1>
      {serverInfo?.isSelfProvisioningEnabled && (
        <Trans>
          <p>
            Prefer to purchase online? Head over to the{' '}
            <Link to={purchaseLink} className="Link">
              purchase page
            </Link>{' '}
            where you can purchase 1 or more licenses.
          </p>
        </Trans>
      )}
      <FieldWrap>
        <div role="group" aria-labelledby="order-heading">
          <h2 className={styles.firstHeading} id="order-heading">
            {t('Your order')}
          </h2>
          {hasDisplayInvoiceRequestProduct && (
            <TextField
              required
              id="product_name"
              label={t('Product')}
              error={errors.productName}
              fieldRef={productNameRef}
              defaultValue={productName}
            />
          )}
          <TextField
            required
            id="quantity"
            label={t('Quantity')}
            error={errors.quantity}
            fieldRef={quantity}
            type="number"
            min={0}
          />
          <TextField
            id="special-requests"
            label={t('Special Requests')}
            fieldRef={specialRequests}
          />
        </div>
        <div role="group" aria-labelledby="about-you-heading">
          <h2 id="about-you-heading">{t('About you')}</h2>
          <TextField
            required
            id="first-name"
            label={t('First name')}
            error={errors.firstName}
            fieldRef={firstName}
            autoComplete="given-name"
            defaultValue={user?.firstName}
          />
          <TextField
            required
            id="last-name"
            label={t('Last name')}
            error={errors.lastName}
            fieldRef={lastName}
            autoComplete="family-name"
            defaultValue={user?.lastName}
          />
          <TextField
            required
            id="email"
            label={t('Email')}
            error={errors.email}
            fieldRef={email}
            autoComplete="email"
            defaultValue={user?.email}
          />
        </div>
        <div role="group" aria-labelledby="about-your-org">
          <h2 id="about-your-org">{t('About your organization')}</h2>
          <TextField
            required
            id="org-name"
            label={t('Legal name of your organization')}
            error={errors.orgName}
            fieldRef={orgName}
            defaultValue={user?.company}
            autoComplete="organization"
          />
          <TextField
            required
            id="org-email"
            label={t('Email invoice to')}
            error={errors.orgEmail}
            fieldRef={orgEmail}
          />
          <TextField
            required
            id="address-1"
            label={t('Address')}
            error={errors.address1}
            fieldRef={address1}
          />
          <TextField
            id="address-2"
            label={t('Address (line 2)')}
            fieldRef={address2}
          />
          <TextField
            required
            id="city"
            label={t('City')}
            error={errors.city}
            fieldRef={city}
          />
          <TextField
            required
            id="state"
            label={t('State')}
            error={errors.state}
            fieldRef={state}
          />
          <TextField
            required
            id="zip"
            label={t('Zip or Postal Code')}
            error={errors.zip}
            fieldRef={zip}
          />
          <TextField
            required
            id="country"
            label={t('Country')}
            error={errors.country}
            fieldRef={country}
          />
          <TextField
            id="po-number"
            label={t(
              'Purchase Order Number (if required by your organization)'
            )}
            fieldRef={poNumber}
          />
        </div>
        <Button type="submit">{t('Submit')}</Button>
        <Link className="Button--secondary" to="/plans">
          {t('Cancel')}
        </Link>
      </FieldWrap>
    </form>
  );
};

export default InvoiceRequestForm;
