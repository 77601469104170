import React, { ReactElement } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SignUp from '../containers/SignUp';
import Purchase from '../containers/Purchase';
import Billing from '../containers/Billing';
// TODO: The "home" toggle should be removed as soon as we have a plan for on-prem deployments.
import Home from '../../axe-devtools/pages/Home';
import NotFound from '../../common/pages/NotFound';
import Features from '../pages/internal/Features';
import FeaturesAdmin from '../pages/internal/FeaturesAdmin';
import Installed from '../pages/Installed';
import Uninstalled from '../pages/Uninstalled';
import ScreenReaders from '../pages/ScreenReaders';
import UserManagement from '../pages/UserManagement';
import AddUsersToProduct from '../pages/AddUsersToProduct';
import AddUsersToEnterprise from '../pages/AddUsersToEnterprise';
import AddAdminUser from '../pages/AddAdminUser';
import Issue from '../pages/Issue';
import AdvancedTestingCoverage from '../pages/AdvancedTestingCoverage';
import AcceptInvitation from '../pages/AcceptInvitation';
import AcceptInvitationV2 from '../pages/AcceptInvitationV2';
import Invoice from '../pages/Invoice';
import Settings from '../pages/Settings';
import Protected from '../../common/components/Protected';
import NoEnterprise from './NoEnterprise';
import FeatureRequired from '../../common/components/FeatureRequired';
import DequersOnly from './DequersOnly';
import NotOnPrem from '../../common/components/NotOnPrem';
import Products from '../pages/Products';
import { PRODUCT_URLS, ProductSlugs } from '../../common/constants';
import EditUser from '../pages/EditUser';
import { useOnPremValue } from '../../common/contexts/onPrem';
import SubscriptionRequired from '../../common/components/SubscriptionRequired';
import InvoiceRequest from '../pages/InvoiceRequest';
import Configuration from '../pages/Configuration';
import BillingRequired from '../../common/components/BillingRequired';
import { useServerInfo } from '../../common/contexts/serverInfo';
import IntegrationTemplates from '../pages/IntegrationTemplates';
import CreateTeam from '../pages/CreateTeam';
import EditTeam from '../pages/EditTeam';
import ViewTeams from '../pages/ViewTeams';

const DOCS_SITE_ISSUES_URI = process.env.DOCS_SITE_ISSUES_URI as string;

const AppRoutes = (): ReactElement => {
  const { t } = useTranslation();
  const isOnPrem = useOnPremValue();
  const { serverInfo } = useServerInfo();
  const { search } = useLocation();

  const shouldShowProductsHome =
    !isOnPrem || (isOnPrem && serverInfo?.billingServiceEnabled);

  return (
    <Switch>
      <Route exact path="/">
        <Protected>
          {shouldShowProductsHome ? <Products /> : <Home />}
        </Protected>
      </Route>
      <Route exact path="/trial">
        <Redirect
          to={{
            pathname: PRODUCT_URLS[ProductSlugs.axeDevToolsExtension].trial,
            search
          }}
        />
      </Route>
      <Route exact path="/issues/:id">
        <Issue />
      </Route>
      <Route exact path="/plans">
        <NotOnPrem>
          <FeatureRequired featureFlagId="billing_v1">
            <Redirect
              to={{
                pathname: PRODUCT_URLS[ProductSlugs.axeDevToolsExtension].plans,
                search
              }}
            />
          </FeatureRequired>
        </NotOnPrem>
      </Route>
      <Route exact path="/purchase">
        <NotOnPrem>
          <FeatureRequired featureFlagId="billing_v1">
            <Protected>
              <NoEnterprise>
                <Purchase title={t('Purchase')} />
              </NoEnterprise>
            </Protected>
          </FeatureRequired>
        </NotOnPrem>
      </Route>
      <Route exact path="/request-invoice">
        <NotOnPrem>
          <InvoiceRequest />
        </NotOnPrem>
      </Route>
      <Route exact path="/signup">
        <NotOnPrem>
          <SignUp />
        </NotOnPrem>
      </Route>
      <Route exact path="/uninstalled">
        <Uninstalled />
      </Route>
      <Route exact path="/screen-readers" component={ScreenReaders} />
      <Route exact path="/install-success">
        <Installed />
      </Route>
      <Route exact path="/billing">
        <NotOnPrem>
          <FeatureRequired featureFlagId="billing_v1">
            <Protected>
              <NoEnterprise>
                <Billing />
              </NoEnterprise>
            </Protected>
          </FeatureRequired>
        </NotOnPrem>
      </Route>
      <Route exact path="/billing/invoices/:id">
        <NotOnPrem>
          <FeatureRequired featureFlagId="billing_v1">
            <Protected>
              <NoEnterprise>
                <Invoice />
              </NoEnterprise>
            </Protected>
          </FeatureRequired>
        </NotOnPrem>
      </Route>
      <Route exact path="/addpayment">
        <NotOnPrem>
          <FeatureRequired featureFlagId="billing_v1">
            <Protected>
              <NoEnterprise>
                <Purchase title={t('Add Payment Method')} paymentOnly />
              </NoEnterprise>
            </Protected>
          </FeatureRequired>
        </NotOnPrem>
      </Route>
      <Route exact path="/user-access">
        <BillingRequired>
          <FeatureRequired featureFlagId="user_management_v1">
            <Protected isEnterpriseAdmin>
              <UserManagement />
            </Protected>
          </FeatureRequired>
        </BillingRequired>
      </Route>
      <Route exact path="/user-access/:product_slug/add-users">
        <BillingRequired>
          <FeatureRequired featureFlagId="user_management_v1">
            <FeatureRequired featureFlagId="billing_v1">
              <Protected isEnterpriseAdmin>
                <AddUsersToProduct />
              </Protected>
            </FeatureRequired>
          </FeatureRequired>
        </BillingRequired>
      </Route>
      <Route exact path="/user-access/edit-user/:id">
        <BillingRequired>
          <FeatureRequired featureFlagId="user_management_v1">
            <Protected isEnterpriseAdmin>
              <EditUser />
            </Protected>
          </FeatureRequired>
        </BillingRequired>
      </Route>
      <Route exact path="/user-access/axe-account/add-admin">
        <BillingRequired>
          <FeatureRequired featureFlagId="user_management_v1">
            <FeatureRequired featureFlagId="billing_v1">
              <Protected isEnterpriseAdmin>
                <AddAdminUser />
              </Protected>
            </FeatureRequired>
          </FeatureRequired>
        </BillingRequired>
      </Route>
      <Route exact path="/user-access/axe-account/add-member">
        <BillingRequired>
          <FeatureRequired featureFlagId="user_management_v1">
            <FeatureRequired featureFlagId="billing_v1">
              <Protected isEnterpriseAdmin>
                <AddUsersToEnterprise />
              </Protected>
            </FeatureRequired>
          </FeatureRequired>
        </BillingRequired>
      </Route>
      <Route
        path="/rule-help/:lang/:ruleId"
        render={({ match }) => {
          const { ruleId } = match.params as {
            lang: string;
            ruleId: string;
          };
          return (window.location.href = DOCS_SITE_ISSUES_URI + '/' + ruleId);
        }}
      />
      <Route path="/internal/features" exact>
        <Protected>
          <DequersOnly>
            <Features />
          </DequersOnly>
        </Protected>
      </Route>
      <Route path="/internal/features/admin" exact>
        <Protected>
          <FeaturesAdmin />
        </Protected>
      </Route>
      <Route path="/coverage-site">
        <Protected>
          <SubscriptionRequired
            productSlug={ProductSlugs.axeDevToolsExtension}
            redirectTo={PRODUCT_URLS[ProductSlugs.axeDevToolsExtension].plans}
            nonSelfProvisioningRedirectTo="/"
          >
            <AdvancedTestingCoverage category="site" />
          </SubscriptionRequired>
        </Protected>
      </Route>
      <Route path="/coverage-page">
        <Protected>
          <SubscriptionRequired
            productSlug={ProductSlugs.axeDevToolsExtension}
            redirectTo={PRODUCT_URLS[ProductSlugs.axeDevToolsExtension].plans}
            nonSelfProvisioningRedirectTo="/"
          >
            <AdvancedTestingCoverage category="page" />
          </SubscriptionRequired>
        </Protected>
      </Route>
      <Route path="/coverage-page-state">
        <Protected>
          <SubscriptionRequired
            productSlug={ProductSlugs.axeDevToolsExtension}
            redirectTo={PRODUCT_URLS[ProductSlugs.axeDevToolsExtension].plans}
            nonSelfProvisioningRedirectTo="/"
          >
            <AdvancedTestingCoverage category="page-state" />
          </SubscriptionRequired>
        </Protected>
      </Route>
      <Route exact path="/accept-invitation">
        <BillingRequired>
          <FeatureRequired featureFlagId="multi_buy_v1">
            <Protected>
              <FeatureRequired
                featureFlagId="user_management_v1"
                off={<AcceptInvitation />}
              >
                <AcceptInvitationV2 />
              </FeatureRequired>
            </Protected>
          </FeatureRequired>
        </BillingRequired>
      </Route>
      <Route exact path="/settings">
        <BillingRequired>
          <FeatureRequired featureFlagId="api_keys_v1">
            <Protected>
              <Settings />
            </Protected>
          </FeatureRequired>
        </BillingRequired>
      </Route>
      <Route path="/configuration/integrations/:productSlug/templates">
        <BillingRequired>
          <FeatureRequired featureFlagId="integrations_v1">
            <SubscriptionRequired
              productSlug={ProductSlugs.jiraIntegration}
              redirectTo="/configuration"
            >
              <Protected>
                <IntegrationTemplates />
              </Protected>
            </SubscriptionRequired>
          </FeatureRequired>
        </BillingRequired>
      </Route>

      <Route path="/configuration">
        {/* TODO: update when we decide how to handle on prem configuration */}
        <BillingRequired>
          <Protected>
            <Configuration />
          </Protected>
        </BillingRequired>
      </Route>

      <Route path="/teams/new">
        <BillingRequired>
          <FeatureRequired featureFlagId="teams_v1">
            <Protected isEnterpriseAdmin>
              <SubscriptionRequired
                productSlug={ProductSlugs.axeMonitor}
                redirectTo={'/'}
              >
                <CreateTeam />
              </SubscriptionRequired>
            </Protected>
          </FeatureRequired>
        </BillingRequired>
      </Route>
      <Route path="/teams/edit/:id">
        <BillingRequired>
          <FeatureRequired featureFlagId="teams_v1">
            <Protected isEnterpriseAdmin>
              <SubscriptionRequired
                productSlug={ProductSlugs.axeMonitor}
                redirectTo={'/'}
              >
                <EditTeam />
              </SubscriptionRequired>
            </Protected>
          </FeatureRequired>
        </BillingRequired>
      </Route>
      <Route path="/teams">
        <BillingRequired>
          <FeatureRequired featureFlagId="teams_v1">
            <Protected isEnterpriseAdmin>
              <SubscriptionRequired
                productSlug={ProductSlugs.axeMonitor}
                redirectTo={'/'}
              >
                <ViewTeams />
              </SubscriptionRequired>
            </Protected>
          </FeatureRequired>
        </BillingRequired>
      </Route>

      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default AppRoutes;
