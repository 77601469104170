import type { v2 } from '@deque/billing-service-client';
import React, { useState, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  Panel,
  Icon,
  Button,
  OptionsMenu,
  OptionsMenuItem,
  Alert,
  AlertContent,
  AlertActions,
  TextField
} from '@deque/cauldron-react';
import { ProductSlugs } from '@deque/billing-utils';

import type { AddUserFailedActionState } from './../AddUserProgress';
import ScrimmedLoader from '../../../common/components/ScrimmedLoader';
import ContentToast from '../../../common/components/ContentToast';
import { EnterpriseMembers } from '../../../common/utils/billing-client/client-v2';
import UserManagementTableLegacy from './UserManagementTableLegacy';
import styles from './UserManagementLegacy.css';

export interface AvailableLicense {
  product_name?: string;
  product_slug?: string;
  remaining_count: number | null;
}

export interface UserManagementLegacyProps {
  members: EnterpriseMembers.Member[];
  pendingOrAcceptedMembers: EnterpriseMembers.PendingOrAcceptedMember[];
  pendingUsers: EnterpriseMembers.PendingUser[];
  currentUserId: string;
  availableLicenses: AvailableLicense[];
  getProductBySlug: (slug: string) => v2.Product | undefined;
  onAddUser: (
    type: 'product' | 'admin' | 'member',
    productSlug?: string
  ) => void;
  onResend: (member: EnterpriseMembers.PendingMember) => void;
  onRemoveUser: (member: EnterpriseMembers.PendingOrAcceptedMember) => void;
  onDismiss: () => void;
  hasError: boolean;
  loadingAction: boolean;
  errMessage: string | null;
  confirmationMsg: string | null;
  variant?: 'wide' | 'narrow' | 'medium';
  hiddenProductSlugs: ProductSlugs[];
  isMailerEnabled: boolean;
}

const UserManagementLegacy = ({
  members,
  pendingOrAcceptedMembers,
  pendingUsers,
  currentUserId,
  availableLicenses,
  getProductBySlug,
  onAddUser,
  onResend,
  onRemoveUser,
  onDismiss,
  hasError,
  loadingAction,
  errMessage,
  confirmationMsg,
  variant = 'medium',
  hiddenProductSlugs,
  isMailerEnabled
}: UserManagementLegacyProps): JSX.Element => {
  const { t } = useTranslation();
  const loaderRef = useRef<HTMLDivElement>(null);
  const [showRemoveAlert, setShowRemoveAlert] = useState(false);
  const [showResendAlert, setShowResendAlert] = useState(false);
  const [user, setUser] =
    useState<EnterpriseMembers.PendingOrAcceptedMember | null>(null);
  const location = useLocation();
  const failedUserAction: AddUserFailedActionState = location.state;
  const [showActionFailedAlert, setShowActionFailedAlert] = useState(
    !!failedUserAction?.action && failedUserAction?.failedUsers?.length
  );
  const dismissActionFailedAlert = () => setShowActionFailedAlert(false);

  const onResendClick = (member: EnterpriseMembers.PendingMember) => {
    setShowResendAlert(true);
    setUser(member);
  };

  const onRemoveClick = (member: EnterpriseMembers.PendingOrAcceptedMember) => {
    setShowRemoveAlert(true);
    setUser(member);
  };

  useEffect(() => {
    /* istanbul ignore next */
    loaderRef.current?.focus();
  }, [loaderRef.current]);

  return (
    <div
      className={classNames(styles.wrap, {
        [styles.collapsed]: variant === 'narrow'
      })}
    >
      {loadingAction && (
        <ScrimmedLoader label={t('Loading...')} loaderRef={loaderRef} />
      )}
      <Alert heading={t('resend invitation')} show={showResendAlert}>
        <AlertContent>
          {t('You’re about to send another invitation to: {{ email }}.', {
            email: user?.email
          })}
        </AlertContent>
        <AlertActions>
          <Button
            onClick={() => {
              onResend(user as EnterpriseMembers.PendingMember);
              setShowResendAlert(false);
            }}
          >
            {t('Resend')}
          </Button>
          <Button variant="secondary" onClick={() => setShowResendAlert(false)}>
            {t('Cancel')}
          </Button>
        </AlertActions>
      </Alert>
      <Alert
        heading={t('Remove User')}
        show={showRemoveAlert}
        variant="warning"
      >
        <AlertContent>
          {t(
            'You’re about to revoke user access for: {{ email }}. They will no longer be part of your managed users.',
            { email: user?.email }
          )}
        </AlertContent>
        <AlertActions>
          <Button
            variant="error"
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              onRemoveUser(user!);
              setShowRemoveAlert(false);
            }}
          >
            {t('Remove')}
          </Button>
          <Button variant="secondary" onClick={() => setShowRemoveAlert(false)}>
            {t('Cancel')}
          </Button>
        </AlertActions>
      </Alert>
      {hasError && (
        <ContentToast type="caution" show>
          {t('Failed to load users')}
        </ContentToast>
      )}
      {errMessage && (
        <ContentToast type="caution" show>
          {errMessage}
        </ContentToast>
      )}
      {confirmationMsg && (
        <ContentToast type="confirmation" show onDismiss={onDismiss}>
          {confirmationMsg}
        </ContentToast>
      )}
      {!hasError && (
        <div>
          <h1>{t('Deque Products User Management')}</h1>
          <p className={styles.mainMessage}>
            {t(
              'Use the table below to manage your user’s access to a Deque Product(s).'
            )}
          </p>
          <Panel collapsed={variant === 'narrow'}>
            <div className={styles.userManagementHead}>
              <div>
                <h2>{t('Manage Users')}</h2>
                <Trans>
                  (Total Users: {{ total: members.length }}
                  {{
                    pendingMessage: pendingUsers.length
                      ? pendingUsers.length === 1
                        ? t(', including 1 user who has not logged in yet')
                        : t(
                            ', including {{ pending }} users who have not logged in yet',
                            { pending: pendingUsers.length }
                          )
                      : ''
                  }}
                  )
                </Trans>
              </div>
              <OptionsMenu
                align={variant === 'narrow' ? 'left' : 'right'}
                trigger={triggerProps => (
                  <Button type="button" {...triggerProps} thin>
                    <Icon type="plus" />
                    {t('Add Users...')}
                  </Button>
                )}
              >
                {availableLicenses.map(license => (
                  <OptionsMenuItem
                    key={`${license.product_slug}-omi`}
                    disabled={
                      license.remaining_count !== null &&
                      license.remaining_count <= 0
                    }
                    onSelect={() => onAddUser('product', license.product_slug)}
                  >
                    <p>
                      {t('...to {{ productName }}', {
                        productName: license.product_name
                      })}
                      {license.remaining_count !== null && (
                        <span>
                          {t(' ({{ count }} users available)', {
                            count: license.remaining_count
                          })}
                        </span>
                      )}
                    </p>
                  </OptionsMenuItem>
                ))}
                <OptionsMenuItem onSelect={() => onAddUser('member')}>
                  <p>{t('...to your axe Account')}</p>
                </OptionsMenuItem>
                <OptionsMenuItem onSelect={() => onAddUser('admin')}>
                  <p>{t('...as an axe Account Admin')}</p>
                </OptionsMenuItem>
              </OptionsMenu>
            </div>
            {!pendingOrAcceptedMembers.length ? (
              <p>{t('Please add your first user!')}</p>
            ) : (
              <UserManagementTableLegacy
                members={pendingOrAcceptedMembers}
                currentUserId={currentUserId}
                getProductBySlug={getProductBySlug}
                onResendClick={onResendClick}
                onRemoveClick={onRemoveClick}
                variant={variant}
                hiddenProductSlugs={hiddenProductSlugs}
                isMailerEnabled={isMailerEnabled}
              />
            )}
          </Panel>
        </div>
      )}
      {showActionFailedAlert && (
        <Alert
          heading={
            failedUserAction.action === 'add-users'
              ? t('Add User Complete')
              : t('Action Complete')
          }
          show
        >
          <AlertContent>
            {failedUserAction.failedReasons.length > 0 && (
              <ul>
                {failedUserAction.failedReasons.map(
                  (reason: string, index: number) => (
                    <li key={index}>{reason}</li>
                  )
                )}
              </ul>
            )}
            <p>
              {failedUserAction.failedUsers.length === 1
                ? t('We were unable to add (1) user:')
                : t('We were unable to add ({{ count }}) users:', {
                    count: failedUserAction.failedUsers.length
                  })}
            </p>
            <TextField
              label={t('Users not added')}
              value={failedUserAction.failedUsers.join(', ')}
              disabled
              multiline
            />
            <div className={styles.copyUsers}>
              <CopyToClipboard text={failedUserAction.failedUsers.join(', ')}>
                <Button variant="link">{t('copy list of users')}</Button>
              </CopyToClipboard>
            </div>
          </AlertContent>
          <AlertActions>
            <Button variant="secondary" onClick={dismissActionFailedAlert}>
              {t('Close')}
            </Button>
          </AlertActions>
        </Alert>
      )}
    </div>
  );
};
export default UserManagementLegacy;
