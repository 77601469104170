import type { ConnectUser } from '@useparagon/connect';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { ColumnHeader, Notice } from '@deque/cauldron-react';
import { useTranslation } from 'react-i18next';
import {
  SupportedIntegrationProductSlugs,
  ProductNames,
  ProductSlugs
} from '../../../common/constants';
import getIntegrationProductName, {
  getIntegrationProductInfoFromSlug,
  IntegrationProductInfo
} from '../../../common/utils/get-integration-product-name-from-slug';
import ScrimmedLoader from '../../../common/components/ScrimmedLoader';
import styles from './IntegrationConfigurations.css';
import { useEnterprises } from '../../../common/contexts/enterprises';
import { useAuthContext } from '../../../common/contexts/auth';
import ContentToast from '../../../common/components/ContentToast';
import { IntegrationLogo } from '../integrations/IntegrationLogo';
import { ConnectionButtons } from '../integrations/Connection/ConnectionButtonsLine';
import { IntegrationConnection } from '../../../common/utils/integrations-client/jira';
import { getIntegrationConnections } from '../../../common/utils/integrations-client/jira/connections';
import { ErrorOrNull } from '../../../../typings/utils';
import { useProducts } from '../../../common/contexts/products';

export const isIntegrationEnabled = (
  integrationProductSlug: SupportedIntegrationProductSlugs,
  integrationUser: ConnectUser | null
) => {
  if (!integrationUser) {
    return false;
  }

  const productName =
    ProductNames[
      getIntegrationProductName(
        integrationProductSlug
      ) as keyof typeof ProductNames
    ].toLowerCase();
  return !!(
    integrationUser?.authenticated &&
    integrationUser.integrations[productName]?.enabled &&
    integrationUser.integrations[productName]?.credentialStatus === 'VALID'
  );
};

type ProductSlugWithConnections = {
  productSlug: ProductSlugs;
  connections: IntegrationConnection[];
  integrationProductInfo: IntegrationProductInfo;
};

interface IntegrationsConfigurationProps {
  enabledIntegrationProductSlugs: SupportedIntegrationProductSlugs[];
}

const splitConnectionsByProductSlug = (
  enabledIntegrationProductSlugs: SupportedIntegrationProductSlugs[],
  connections: IntegrationConnection[] | null
): ProductSlugWithConnections[] => {
  const formattedConnections: ProductSlugWithConnections[] = [];

  if (connections !== null) {
    // Organize connections by slug
    const connectionsOrganizedByProductSlug = new Map<
      ProductSlugs,
      IntegrationConnection[]
    >();
    for (const connection of connections) {
      const connectionsList = connectionsOrganizedByProductSlug.get(
        connection.product_slug as unknown as ProductSlugs
      );
      if (connectionsList) {
        connectionsList.push(connection);
      } else {
        connectionsOrganizedByProductSlug.set(
          connection.product_slug as unknown as ProductSlugs,
          [connection]
        );
      }
    }

    // Reformat organized connections in a way that will be easier to render
    for (const enabledIntegrationProductSlug of enabledIntegrationProductSlugs) {
      const productSlug =
        enabledIntegrationProductSlug as unknown as ProductSlugs;
      const connectionsList =
        connectionsOrganizedByProductSlug.get(productSlug);

      formattedConnections.push({
        productSlug,
        connections: connectionsList || [],
        integrationProductInfo: getIntegrationProductInfoFromSlug(productSlug)
      });
    }
  }

  return formattedConnections;
};

function IntegrationsConfiguration({
  enabledIntegrationProductSlugs
}: IntegrationsConfigurationProps) {
  const { t } = useTranslation();

  const { getProductBySlug } = useProducts();

  // Get info required for making API calls
  const { user: authUser } = useAuthContext();
  const {
    activeEnterprise,
    isAdmin: isEnterpriseAdmin,
    loading: enterpriseLoading,
    error: enterpriseError
  } = useEnterprises();

  // Get all connections for this enterprise,
  // keep it null until it's loaded, so that transformation function can skip executing when it's null
  const [connections, setConnections] = useState<
    IntegrationConnection[] | null
  >(null);
  const [connectionsError, setConnectionsError] = useState<ErrorOrNull>(null);
  const [connectionsLoading, setConnectionsLoading] = useState(true);
  const [connectionsReloadCounter, setConnectionsReloadCounter] = useState(0);
  useEffect(() => {
    async function fetchConnections() {
      if (activeEnterprise && authUser) {
        try {
          setConnectionsLoading(true);
          const connectionsList = await getIntegrationConnections({
            enterpriseId: activeEnterprise.id,
            token: authUser.token
          });
          setConnections(connectionsList);
        } catch (error) {
          setConnectionsError(error as Error);
        } finally {
          setConnectionsLoading(false);
        }
      }
    }

    fetchConnections();
  }, [activeEnterprise, authUser, connectionsReloadCounter]);

  // A trick to force reload in useFeect above
  const reloadConnections = useCallback(() => {
    setConnectionsReloadCounter(value => value + 1);
  }, [setConnectionsReloadCounter]);

  // Organize connections by product slug
  const connectionsByProductSlug = useMemo<ProductSlugWithConnections[]>(() => {
    return splitConnectionsByProductSlug(
      enabledIntegrationProductSlugs,
      connections
    );
  }, [connections]);

  // Make sure that we loaded required data. User must always be loaded by now
  if (connectionsLoading || enterpriseLoading) {
    return <ScrimmedLoader />;
  }

  // Handle errors, inlcuding an unexpcted case of activeEnterprise or authUser being null by now
  if (connectionsError || enterpriseError || !activeEnterprise || !authUser) {
    return (
      <ContentToast show={true} type="caution">
        {t('Failed to load integration information.')}
      </ContentToast>
    );
  }

  return (
    <>
      <ColumnHeader>
        <h2>{t('Integrations')}</h2>
      </ColumnHeader>

      {!isEnterpriseAdmin && (
        <div className={styles.banner}>
          <Notice
            type="info"
            title={t(
              'Integration connection settings are managed by your admin.'
            )}
          />
        </div>
      )}

      <div>
        {connectionsByProductSlug.map(connectionByProductSlug => (
          <Fragment key={connectionByProductSlug.productSlug}>
            <div className={styles.productItemWrap}>
              <div className={styles.productLogo}>
                <IntegrationLogo
                  integrationProductSlug={connectionByProductSlug.productSlug}
                />
              </div>

              <div className={styles.connectionItemDescription}>
                <h2>
                  {connectionByProductSlug.integrationProductInfo.displayName}
                </h2>
                <p>
                  {t(
                    "Boost your team's accessibility efforts with seamless issue tracking directly from {{devtoolsProductName}} to {{integrationDisplayName}}",
                    {
                      devtoolsProductName: getProductBySlug(
                        ProductSlugs.axeDevToolsExtension
                      )?.name,
                      integrationDisplayName:
                        connectionByProductSlug.integrationProductInfo
                          .displayName
                    }
                  )}
                </p>
                {connectionByProductSlug.connections.map(connection => (
                  <div
                    className={styles.connectionItemWrap}
                    key={connection.id}
                  >
                    <h3>
                      {t('Connection to {{baseUrl}}', {
                        baseUrl: connection.base_url
                      })}
                    </h3>
                    <ConnectionButtons
                      key={connection.id}
                      connection={connection}
                      integrationProductInfo={
                        connectionByProductSlug.integrationProductInfo
                      }
                      enterprise={activeEnterprise}
                      canManageIntegrations={isEnterpriseAdmin}
                      authUser={authUser}
                      connectionsUpdated={reloadConnections}
                    />
                  </div>
                ))}
              </div>
            </div>
            <ConnectionButtons
              key={`new-${connectionByProductSlug.integrationProductInfo.paragonIntegrationName}-connection`}
              connection={null}
              integrationProductInfo={
                connectionByProductSlug.integrationProductInfo
              }
              enterprise={activeEnterprise}
              canManageIntegrations={isEnterpriseAdmin}
              authUser={authUser}
              connectionsUpdated={reloadConnections}
            />
          </Fragment>
        ))}
      </div>
    </>
  );
}

export default IntegrationsConfiguration;
