import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import CommonLink from '../../common/components/Link';
import { useTranslation, Trans } from 'react-i18next';
import {
  TextField,
  Button,
  RadioGroup,
  Checkbox,
  Icon,
  FieldWrap,
  Alert,
  AlertContent,
  AlertActions,
  Select,
  TooltipTabstop,
  Notice
} from '@deque/cauldron-react';
import ContentToast from '../../common/components/ContentToast';
import FormHeader from './FormHeader';
import InlineTag from './InlineTag';
import ScrimmedLoader from '../../common/components/ScrimmedLoader';
import styles from './Payment.css';
import classNames from 'classnames';
import { AddressElement, CardElement } from '@stripe/react-stripe-js';
import { useFeatureFlagState } from '../../common/contexts/featureFlags';
import { StripeTypes } from '@deque/billing-service-client';
import {
  StripeAddressElementChangeEvent,
  StripeAddressElementOptions
} from '@stripe/stripe-js';
import { taxIdTypes, countryCodeToName } from '@deque/billing-utils';
import type { PreviewInvoice } from '@deque/billing-utils';
import type { TaxIdWithValue } from '../containers/Purchase';
import { PRODUCT_URLS, ProductSlugs } from '../../common/constants';

type RadioItem = React.InputHTMLAttributes<HTMLInputElement>;

/** exported for testing purposes */
export const findTaxIdType = (country: string): string | null => {
  return (
    taxIdTypes.find(
      item => item.country === country || item.countryCode === country
    )?.type || null
  );
};

interface Errors {
  alert?: string;
  fullName?: string;
  company?: string;
  coupon?: string;
  agree?: string;
  billing?: string;
  promoCode?: string;
  licenseCount?: string;
  address?: string;
  previewInvoice?: string;
  taxId?: string;
}

type InputRef = React.RefObject<HTMLInputElement>;

interface PaymentProps {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onLicenseCountChange: (
    value: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onPriceChange: (radio: RadioItem, input: HTMLElement) => void;
  onWhoChange: (radio: RadioItem, input: HTMLElement) => void;
  onPromoChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCountryChange: (e: StripeAddressElementChangeEvent) => void;
  applyPromoClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  removePromoClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  defaultFullName?: string | null;
  defaultCompany?: string;
  amount: string;
  discountAmount?: string;
  promoAmount?: string;
  promoPercent?: string;
  promoOpen: boolean;
  subscribing: boolean;
  applying: boolean;
  calculating: boolean;
  staticPrice: boolean;
  billingPeriod: string;
  yearlySavings: number;
  couponName?: string;
  initialCouponCode?: string;
  previewInvoice?: PreviewInvoice;
  errors: Errors;
  fullName?: InputRef;
  company?: InputRef;
  promoCheckbox?: InputRef;
  promoCodeInput?: InputRef;
  loaderRef: React.RefObject<HTMLDivElement>;
  removePromoRef: React.RefObject<HTMLButtonElement>;
  submitRef: React.RefObject<HTMLButtonElement>;
  licenseCountRef: InputRef;
  currentLicenseCount: number;
  paymentOnly?: boolean;
  onCancel?: () => void;
  productName?: string;
  productSlug?: string;
  planSlug?: string;
  chosenWho: string;
  showConfirmRemoveMembers: boolean;
  onCancelRemoveMembers: () => void;
  onRemoveMembers: () => void;
  assignedLicenseCount: number | null;
  total?: string;
  defaultAddress?: StripeTypes.Address;
  onTaxIdCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onTaxIdTypeChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onTaxIdValueChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  taxIdValueRef?: InputRef;
  taxIdCheckboxRef?: InputRef;
  taxIdTypeRef?: React.RefObject<HTMLSelectElement>;
  taxIdOpen?: boolean;
  country?: string;
  selectedTaxId?: TaxIdWithValue;
  hasYearlyPrice?: boolean;
  hasMonthlyPrice?: boolean;
}

const PaymentForm = ({
  // callbacks
  onSubmit,
  onPriceChange,
  onWhoChange,
  onPromoChange,
  applyPromoClick,
  removePromoClick,
  onCancel,
  onLicenseCountChange,
  // text
  productName,
  productSlug = ProductSlugs.axeDevToolsExtension,
  planSlug,
  defaultFullName,
  defaultCompany,
  defaultAddress,
  amount,
  discountAmount,
  promoAmount,
  promoPercent,
  promoOpen,
  subscribing,
  applying,
  calculating,
  staticPrice,
  billingPeriod,
  couponName,
  initialCouponCode,
  yearlySavings,
  errors,
  chosenWho,
  // refs
  fullName,
  company,
  promoCheckbox,
  promoCodeInput,
  loaderRef,
  removePromoRef,
  submitRef,
  licenseCountRef,
  currentLicenseCount,
  // flags
  paymentOnly = false,
  showConfirmRemoveMembers = false,
  onCancelRemoveMembers,
  onRemoveMembers,
  assignedLicenseCount,
  onTaxIdTypeChange,
  taxIdValueRef,
  taxIdCheckboxRef,
  taxIdTypeRef,
  taxIdOpen = false,
  onTaxIdCheckboxChange,
  onTaxIdValueChange,
  selectedTaxId,
  onCountryChange,
  country = 'US',
  hasYearlyPrice = true,
  hasMonthlyPrice = true
}: PaymentProps): React.ReactElement => {
  const [isMounted, setIsMounted] = React.useState(false);
  const hasMultiBuyV1 = useFeatureFlagState('multi_buy_v1');
  const hasPaymentsV2 = useFeatureFlagState('payments_v2');
  const hasTaxIdV1 = useFeatureFlagState('tax_id_v1');

  const defaultValues: StripeAddressElementOptions['defaultValues'] = {
    name: defaultFullName,
    address: {
      line1: defaultAddress?.line1 || '',
      line2: defaultAddress?.line2 || '',
      city: defaultAddress?.city || '',
      state: defaultAddress?.state || '',
      postal_code: defaultAddress?.postal_code || '',
      country: defaultAddress?.country || 'US'
    }
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const { t } = useTranslation();

  let submitText = t('Review & Pay');
  if (subscribing) {
    submitText = t('Submitting...');
  } else if (paymentOnly) {
    submitText = t('Save');
  }

  let majorHeading = t('Upgrade Plan');
  if (paymentOnly) {
    majorHeading = t('Manage Billing');
  } else if (staticPrice) {
    majorHeading = t('Pay Now');
  }

  let minorHeading: string;
  const productUrls = PRODUCT_URLS[productSlug as keyof typeof PRODUCT_URLS];
  const plansSearchParams = new URLSearchParams({
    utm_campaign: 'webapp_plans'
  });

  let requestInvoiceLink = '/request-invoice';
  if (paymentOnly) {
    minorHeading = t('Credit card info');
  } else if (productName) {
    if (planSlug) {
      minorHeading = `${productName} (${planSlug})`;
      const params = new URLSearchParams({
        product: productSlug,
        plan: planSlug
      });
      requestInvoiceLink = `/request-invoice?${params}`;
    } else {
      minorHeading = productName;
      const params = new URLSearchParams({
        product: productSlug
      });
      requestInvoiceLink = `/request-invoice?${params}`;
    }
  } else {
    minorHeading = t('axe DevTools Extension');
  }

  let countText = '';

  if (hasMultiBuyV1) {
    countText =
      chosenWho !== 'multiple' || currentLicenseCount === 1
        ? t('(1 user)')
        : t('({{count}} users)', {
            count: currentLicenseCount
          });
  }

  const fallbackOption = [
    {
      key: 'none',
      value: 'none',
      label: t('Unavailable for selected country.')
    }
  ];

  const selectedCountryTaxIdTypes = taxIdTypes
    .filter(taxIdType => taxIdType.country === countryCodeToName(country))
    .map(({ type, description }) => ({
      key: type,
      value: type,
      label: description
    }));

  const taxIdFieldsDisabled = !(taxIdOpen && selectedCountryTaxIdTypes.length);

  const taxIdSelectOptions = selectedCountryTaxIdTypes.length
    ? selectedCountryTaxIdTypes
    : fallbackOption;

  const selectedTaxIdType =
    selectedTaxId?.type ||
    findTaxIdType(country as string) ||
    (defaultAddress?.country && findTaxIdType(defaultAddress.country));

  const returnLink = staticPrice
    ? '/billing'
    : ('plans' in productUrls ? productUrls.plans : '/plans') +
      `?${plansSearchParams}`;

  return (
    <div className={styles.container}>
      {errors.billing ||
      (hasPaymentsV2 &&
        (errors.address || errors.previewInvoice || errors.taxId)) ? (
        <ContentToast type="caution" show>
          {errors.billing}
          {errors.address}
          {errors.previewInvoice}
          {errors.taxId}
        </ContentToast>
      ) : null}
      <Alert
        show={showConfirmRemoveMembers && !!assignedLicenseCount}
        heading={<span>{t('Remove members')}</span>}
      >
        <AlertContent>
          <p>
            {t(
              'You are purchasing {{currentCount}} licenses but had previously assigned {{previousCount}} licenses, if you proceed, we will clear the users assigned to the subscription and you will have to add users back to your new subscription',
              {
                currentCount: currentLicenseCount,
                previousCount: assignedLicenseCount
              }
            )}
          </p>
        </AlertContent>
        <AlertActions>
          <Button onClick={onRemoveMembers}>{t('Proceed')}</Button>
          <Button variant="secondary" onClick={onCancelRemoveMembers}>
            {t('Cancel')}
          </Button>
        </AlertActions>
      </Alert>
      <div>
        <FormHeader majorHeading={majorHeading} minorHeading={minorHeading} />
        <p className={styles.mainMessage}>
          {!paymentOnly
            ? t(
                'Please fill out the form below with your billing and payment details.'
              )
            : t(
                'Use the form below to manage your credit card information. This will be the card we bill for your subscription and renewals.'
              )}
        </p>
        {!paymentOnly && (
          <Trans>
            <p>
              Don&apos;t want to use a credit card?{' '}
              <Link to={requestInvoiceLink}>Request an invoice</Link>.
            </p>
          </Trans>
        )}
        <form onSubmit={onSubmit} noValidate className={styles.form}>
          {hasMultiBuyV1 && !paymentOnly && !staticPrice && (
            <>
              <h3 className={styles.formSection} id="who-heading">
                {t('Who are you buying for?')}
              </h3>
              <FieldWrap>
                <RadioGroup
                  name="who"
                  aria-labelledby="who-heading"
                  onChange={onWhoChange}
                  defaultValue={chosenWho}
                  radios={[
                    {
                      value: 'single-me',
                      id: 'single-me',
                      label: t('I’m buying a single subscription, just for me'),
                      // we need to change this once we support users with both individual and enterprise subscriptions
                      disabled: !!assignedLicenseCount
                    },
                    {
                      value: 'single-other',
                      id: 'single-other',
                      label: t(
                        'I’m buying a single subscription, for someone else'
                      )
                    },
                    {
                      value: 'multiple',
                      id: 'multiple',
                      label: t('I’m buying a subscription for multiple users')
                    }
                  ]}
                />
                {chosenWho === 'multiple' && (
                  <div className={styles.licenses}>
                    <TextField
                      required
                      id="license-count"
                      type="number"
                      label={t('How many licenses are you buying?')}
                      error={errors.licenseCount}
                      defaultValue="1"
                      fieldRef={licenseCountRef}
                      onChange={onLicenseCountChange}
                      min={1}
                    />
                  </div>
                )}
              </FieldWrap>
            </>
          )}
          <h3 className={styles.formSection}>{t('Payment Information')}</h3>
          {(applying || subscribing || (hasPaymentsV2 && calculating)) && (
            <ScrimmedLoader label={t('Loading...')} loaderRef={loaderRef} />
          )}

          <FieldWrap>
            {!hasPaymentsV2 && (
              <TextField
                required
                id="full-name"
                type="text"
                label={t('Full name')}
                error={errors.fullName}
                fieldRef={fullName}
                defaultValue={defaultFullName || undefined}
                autoComplete="name"
              />
            )}
            <div className="Field">
              <div className="Field__label Field__label--is-required">
                <span>{t('Credit card information')}</span>
                <span className="Field__required-text">{t('Required')}</span>
              </div>
              <div className={styles.creditCard}>
                <CardElement
                  options={{
                    hidePostalCode: hasPaymentsV2,
                    iconStyle: 'solid',
                    style: {
                      base: {
                        iconColor: '#666666',
                        fontSize: '15px',
                        color: '#666666',
                        fontWeight: 'normal',
                        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                        '::placeholder': {
                          color: '#666666',
                          fontFamily: 'Arial'
                        }
                      },
                      invalid: {
                        iconColor: '#d93251',
                        color: '#d93251',
                        '::placeholder': {
                          color: '#d93251'
                        },
                        ':focus': {
                          color: '#d93251'
                        }
                      }
                    }
                  }}
                />
              </div>
              <div className={'Error'} role="alert">
                {errors.billing ? errors.billing : null}
              </div>
            </div>
            <TextField
              required
              id="company"
              type="text"
              label={t('Company')}
              error={errors.company}
              fieldRef={company}
              defaultValue={defaultCompany}
              autoComplete="organization"
            />

            <AddressElement
              options={{
                mode: 'billing',
                autocomplete: {
                  mode: 'automatic'
                },
                ...(!isMounted && { defaultValues })
              }}
              onChange={event => {
                if (event.value.address.country !== country) {
                  onCountryChange(event);
                }
              }}
              className="StripeElement addressElement"
            />
            {hasTaxIdV1 && (
              <div className={styles.taxIdWrap}>
                <div className={styles.taxIdSection}>
                  <Checkbox
                    id="taxIdCheckbox"
                    label={t(
                      'I want to include my tax identifier (e.g., VAT number) in invoices.'
                    )}
                    name="taxIdCheckbox"
                    onChange={onTaxIdCheckboxChange}
                    checked={taxIdOpen}
                    disabled={!selectedCountryTaxIdTypes.length}
                    ref={taxIdCheckboxRef}
                  />
                  <TooltipTabstop
                    placement="auto"
                    variant="info"
                    className={styles.taxIdTooltip}
                    aria-label={t('Tax Identification Help')}
                    tooltip={t(
                      'Select the Tax ID type from the dropdown and enter the associated identifier in the adjacent text field.'
                    )}
                  >
                    <Icon type="info-circle" />
                  </TooltipTabstop>
                </div>
                {taxIdOpen && (
                  <div className={styles.taxIdFields}>
                    <Select
                      className={styles.taxIdSelect}
                      label={t('Tax ID Type')}
                      onChange={e => onTaxIdTypeChange(e)}
                      value={selectedTaxIdType}
                      disabled={
                        taxIdFieldsDisabled || taxIdSelectOptions.length === 1
                      }
                      options={taxIdSelectOptions}
                      required={taxIdOpen}
                      ref={taxIdTypeRef}
                    />
                    <TextField
                      required={taxIdOpen}
                      type="text"
                      label={t('Identifier Value')}
                      error={errors.taxId}
                      disabled={taxIdFieldsDisabled}
                      fieldRef={taxIdValueRef}
                      value={selectedTaxId?.value || ''}
                      onChange={(_value, event) => onTaxIdValueChange(event)}
                    />
                  </div>
                )}
              </div>
            )}
          </FieldWrap>

          {!paymentOnly ? (
            <>
              <h3 className={styles.formSection}>
                {staticPrice ? t('Invoice Summary') : t('Order Summary')}
              </h3>
              <FieldWrap>
                <h4 id="priceGroupLabel">
                  {staticPrice
                    ? t('Invoice amount...')
                    : hasMonthlyPrice && hasYearlyPrice
                    ? t('I want to be billed...')
                    : hasYearlyPrice
                    ? t('Billed annually')
                    : t('Billed monthly')}
                </h4>
                {staticPrice || !hasMonthlyPrice || !hasYearlyPrice ? null : (
                  <RadioGroup
                    name="price"
                    aria-labelledby="priceGroupLabel"
                    onChange={onPriceChange}
                    defaultValue={billingPeriod}
                    radios={[
                      {
                        value: 'yearly',
                        id: 'yearly',
                        label: t('Annually')
                      },
                      {
                        value: 'monthly',
                        id: 'monthly',
                        label: t('Monthly')
                      }
                    ]}
                  />
                )}
                <dl className={styles.priceItems}>
                  <div className={styles.priceItem}>
                    <dt>
                      {productName} {countText}
                    </dt>
                    <dd>${amount}</dd>
                  </div>
                  {discountAmount && (
                    <div className={`${styles.priceItem} ${styles.promo_item}`}>
                      <dt>
                        {t('Promo ({{couponName}})', {
                          couponName: couponName || ''
                        })}
                      </dt>
                      <dd>
                        -{promoPercent}% (${discountAmount})
                      </dd>
                    </div>
                  )}
                </dl>
                {staticPrice ? null : (
                  <div className={styles.promoSection}>
                    <Checkbox
                      id="promoCheckbox"
                      label={t('I have a promo code')}
                      name="promoCheckbox"
                      checkboxRef={promoCheckbox}
                      onChange={onPromoChange}
                      disabled={!!subscribing || !!couponName}
                      checked={!!couponName || promoOpen}
                    />
                    <div
                      className={classNames(`${styles.promoControl}`, {
                        [styles.promoOpen]: promoOpen || couponName
                      })}
                    >
                      <div className={styles.promoFieldWithApply}>
                        <TextField
                          disabled={!!promoAmount}
                          id="promo-code"
                          type="text"
                          label={t('Promo code')}
                          error={errors.promoCode}
                          fieldRef={promoCodeInput}
                          defaultValue={initialCouponCode}
                        />
                        <Button
                          disabled={
                            !!promoAmount ||
                            subscribing ||
                            applying ||
                            calculating
                          }
                          type="button"
                          thin
                          variant="secondary"
                          onClick={applyPromoClick}
                        >
                          {!applying ? t('apply promo code') : t('applying...')}
                        </Button>
                      </div>
                      <div
                        className={classNames(`${styles.promoTag}`, {
                          [styles.promoTagVisible]: promoAmount
                        })}
                      >
                        <Button
                          disabled={!promoAmount || subscribing || calculating}
                          type="button"
                          variant="secondary"
                          onClick={removePromoClick}
                          buttonRef={removePromoRef}
                        >
                          <Icon type="tag" />
                          <span className={'Offscreen'}>
                            {t('remove promo code')}
                          </span>{' '}
                          <span className={styles.couponCode}>
                            {couponName}
                          </span>
                          <Icon type="close" className={styles.removeCoupon} />
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                <p className={styles.totalParagraph} role="alert">
                  <Trans>
                    Your total is...
                    <br />
                    <span className={styles.totalPrice}>
                      ${{ total: promoAmount || amount }}/
                      {{
                        billingPeriod:
                          billingPeriod === 'yearly' ? t('year') : t('month')
                      }}
                    </span>{' '}
                  </Trans>
                  {billingPeriod === 'yearly' && !!yearlySavings ? (
                    <InlineTag className={styles.discountTag}>
                      <Trans>
                        $ YOU&apos;RE SAVING{' '}
                        {{ savings: yearlySavings.toFixed(0) }}%
                      </Trans>
                    </InlineTag>
                  ) : null}
                </p>
              </FieldWrap>
            </>
          ) : null}
          {productSlug === ProductSlugs.dequeUniversity && !paymentOnly ? (
            <>
              <br />
              <Notice
                type="info"
                title={<h3>{t('Purchase Processing Time')}</h3>}
                role="note"
              >
                <Trans>
                  <p>
                    Once you have purchased a Deque University subscription, we
                    will process your request (typically on business days
                    between the hours of 9:00am and 5:00pm U.S. Eastern Standard
                    Time) and send an email notification once your courses are
                    ready for viewing.
                  </p>

                  <p>
                    If you are subscribing someone else or multiple people,
                    please send a list with the full name and email address of
                    each subscriber to{' '}
                    <a
                      rel="noopener noreferrer"
                      href="mailto:training@deque.com"
                    >
                      training@deque.com
                    </a>
                  </p>
                </Trans>
              </Notice>
            </>
          ) : null}
          <div className={styles.actionGroup}>
            <Button
              type="submit"
              disabled={subscribing || applying || calculating}
              buttonRef={submitRef}
            >
              {submitText}
            </Button>
            {!paymentOnly ? (
              <CommonLink
                name={t('Return to Plans section')}
                url={returnLink}
                openInNewTab={/^\w+:\/\//.test(returnLink)}
              >
                {t(`Return to the {{section}} section`, {
                  section: staticPrice ? t('Billing') : t('Plans')
                })}
              </CommonLink>
            ) : (
              <Button variant="secondary" onClick={onCancel}>
                {t('Cancel')}
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default PaymentForm;
export { Errors, RadioItem };
