import type {
  StripeTypes,
  UserWithKeycloakId,
  v2
} from '@deque/billing-service-client';
import React, { ReactElement, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Loader } from '@deque/cauldron-react';
import { Subscription } from '@deque/billing-utils';

import {
  HttpError,
  dquTransactionNotification,
  DquTransactionNotificationParams
} from '../../common/api-client';
import billingClient from '../../common/utils/billing-client/client-v1';
import billingClientV2 from '../../common/utils/billing-client/client-v2';
import BillingComponent from '../components/Billing';
import { useAuthenticatedContext } from '../../common/contexts/auth';
import { useEnterprises } from '../../common/contexts/enterprises';
import DowngradeDialog from '../../common/components/DowngradeDialog';
import useDowngrade from '../../common/hooks/useDowngrade';
import { useProducts } from '../../common/contexts/products';
import PageTitle from '../../common/components/PageTitle';
import getStatusMessage from '../utils/get-status-message';
import { useConfiguration } from '../../common/contexts/Configuration';
import { ProductSlugs } from '../../common/constants';
import usePaymentMethods from '../../common/hooks/usePaymentMethods';
import useV2ListLicenses from '../../common/hooks/useV2ListLicenses';
import { Enterprises as EnterprisesV2 } from '../../common/utils/billing-client/client-v2';
import { useGlobalToast } from '../../common/contexts/globalToast';

const Billing = (): ReactElement => {
  const { t } = useTranslation();
  const { setContents } = useGlobalToast();
  const downgradeState = useDowngrade();
  const {
    activeEnterprise,
    isAdmin,
    defaultPaymentMethod: enterpriseDefaultPaymentMethod,
    loading: loadingEnterprises,
    error: loadingEnterpriseError
  } = useEnterprises();
  const [loading, setLoading] = useState(true);
  const [confirming, setConfirming] = useState(false);
  const [billingUser, setBillingUser] = useState<UserWithKeycloakId>();
  const [expiryDateText, setExpiryDateText] = useState('');
  const [paymentMethod, setPaymentMethod] =
    useState<StripeTypes.PaymentMethod>();
  const [companyText, setCompanyText] = useState('');
  const [performingRequest, setPerformingRequest] = useState(false);
  const [subscription, setSubscription] = useState<Subscription>();
  const loaderRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState<string | null>(null);
  const [licenses, setLicenses] = useState<EnterprisesV2.License | null>(null);
  const [invoices, setInvoices] = useState<StripeTypes.Invoice[]>([]);
  const [downgradeDialogOpen, setDowngradeDialogOpen] = useState(false);
  const history = useHistory();
  const authContext = useAuthenticatedContext();
  const {
    products,
    loading: productsLoading,
    error: productsError,
    getProductBySlug
  } = useProducts();
  const {
    settings,
    loading: configLoading,
    loadingError: configLoadingError
  } = useConfiguration();
  const {
    loading: loadingUserPaymentMethods,
    error: loadingUserPaymentMethodsError,
    defaultPaymentMethod: userDefaultPaymentMethod
  } = usePaymentMethods();

  const user = authContext.user;
  const billingUserV2 = authContext.billingUser;

  const {
    licenses: enterpriseLicenses,
    loading: loadingLicenses,
    error: licenseError
  } = useV2ListLicenses(user.token, {
    enterpriseId: activeEnterprise?.id || null
  });

  const getData = async (force = false) => {
    /* istanbul ignore next*/
    if (billingUser && !force) {
      return;
    }
    // return if the payment methods are still loading
    if (loadingEnterprises || loadingUserPaymentMethods || loadingLicenses) {
      return;
    }

    try {
      // Break if there is any error in loading payment methods
      if (loadingEnterpriseError || loadingUserPaymentMethodsError) {
        throw loadingEnterpriseError || loadingUserPaymentMethodsError;
      }

      const [bUser, userInvoices] = await Promise.all([
        billingClient.me.get(user.token),
        billingClient.invoices.list(user.token)
      ]);

      if (
        activeEnterprise &&
        enterpriseLicenses &&
        enterpriseLicenses?.length
      ) {
        const productId = getProductBySlug(
          ProductSlugs.axeDevToolsExtension
        )?.id;

        const productLicense: EnterprisesV2.License | null =
          (productId &&
            enterpriseLicenses?.find(l => l.product_id === productId)) ||
          null;

        setLicenses(productLicense);
      }

      setBillingUser(bUser);
      setInvoices(userInvoices);

      let pm;
      if (activeEnterprise) {
        pm = enterpriseDefaultPaymentMethod;
      } else {
        pm = userDefaultPaymentMethod;
      }

      setPaymentMethod(pm);

      setCompanyText(billingUserV2?.enterprises[0]?.name || user.company || ''); //TODO: Change this when we support multiple enterprises.

      const subscriptions = activeEnterprise
        ? activeEnterprise.subscriptions
        : billingUserV2?.subscriptions || [];

      const axeSubscription = (subscriptions as Subscription[]).find(
        s => s.product_slug === ProductSlugs.axeDevToolsExtension
      );

      if (axeSubscription) {
        const { statusMessage } = getStatusMessage({
          subscription: axeSubscription,
          t
        });

        setExpiryDateText(statusMessage || '');
        setSubscription(axeSubscription);
      }
      /* istanbul ignore next */

      setLoading(false);
    } catch (err) {
      if ((err as HttpError).status === 401) {
        authContext.login(); // should redirect
      }
      setError(
        t('Could not load user information: {{ error }}', {
          error: (err as HttpError).message
        })
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [
    user,
    billingUserV2,
    loadingEnterprises,
    loadingUserPaymentMethods,
    loadingLicenses,
    userDefaultPaymentMethod,
    enterpriseDefaultPaymentMethod,
    activeEnterprise
  ]);

  const handleRemovePaymentMethodClick = () => {
    setConfirming(true);
  };

  const handleConfirmRemoveClick = async () => {
    /* istanbul ignore next */
    if (!paymentMethod) {
      return;
    }
    try {
      /* istanbul ignore next */
      if (loaderRef?.current) {
        loaderRef.current.focus();
      }
      setPerformingRequest(true);
      const pm = await billingClient.users.removePaymentMethod(
        user.token,
        paymentMethod.id
      );
      await authContext.updateBillingUser();
      /* istanbul ignore else */
      if (pm && pm.id === paymentMethod.id) {
        setPaymentMethod(undefined);
      }

      setConfirming(false);
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setPerformingRequest(false);
    }
  };

  const handleCancelRemoveClick = () => {
    setConfirming(false);
  };

  const onAssignLicenses = async (
    newLicenseCount: number | null,
    enterprise: v2.Enterprise
  ) => {
    // impossible but makes typescript happy
    /* istanbul ignore next */
    if (!subscription?.stripe_id) {
      return;
    }

    setLoading(true);

    try {
      if (!newLicenseCount) {
        throw new Error('Invalid license count');
      }

      await billingClient.subscriptions.update({
        token: user.token,
        enterpriseId: enterprise.id,
        subscriptionId: subscription.stripe_id,
        licenseCount: newLicenseCount
      });
      setContents(
        t(
          'Your number of subscriptions has been updated to {{ newLicenseCount }} successfully.',
          {
            newLicenseCount
          }
        ),
        'confirmation'
      );

      await authContext.updateBillingUser();
      getData(true);

      if (subscription.product_slug === ProductSlugs.dequeUniversity) {
        const body: DquTransactionNotificationParams = {
          newSubscription: false,
          priceId: subscription.pricing?.stripe_id,
          quantity: newLicenseCount
        };
        await dquTransactionNotification(user.token, body);
      }
    } catch (err) {
      setError(t('Failed to change user subscriptions. Please try again.'));
    } finally {
      setLoading(false);
    }
  };

  const onAssignEnterpriseName = async (name: string) => {
    if (!billingUserV2) {
      return null;
    }

    try {
      const enterprise = await billingClientV2.enterprises.create({
        token: user.token,
        name,
        admin_keycloak_id: billingUserV2.keycloak_id
      });
      return enterprise;
    } catch (err) {
      if ((err as HttpError).status === 409) {
        throw err;
      }
      setError(t('Failed to create enterprise. Please try again.'));
      return null;
    }
  };

  const onCompanyEditSubmit = async (name: string) => {
    if (!activeEnterprise) {
      return;
    }

    setPerformingRequest(true);

    try {
      await billingClient.enterprises.update({
        token: user.token,
        enterpriseId: activeEnterprise.id,
        name
      });

      setCompanyText(name);
      setContents(
        t('Company information was updated successfully'),
        'confirmation'
      );
    } catch (err) {
      setError(t('Failed to update company information. Please try again.'));
    } finally {
      setPerformingRequest(false);
    }
  };

  const onCancelSubscriptionClick = () => setDowngradeDialogOpen(true);
  const onDowngradeDialogClose = () => setDowngradeDialogOpen(false);
  const onDowngrade = async () => {
    /* istanbul ignore if */
    if (!user || !billingUserV2) {
      return;
    }

    // close the modal
    onDowngradeDialogClose();

    // downgrade the user
    if (subscription) {
      await downgradeState.downgrade({
        user,
        subscription
      });
    }

    // we've successfully downgraded...route to plans now
    history.push('/plans?utm_campaign=webapp_plans');
  };

  const showLoader =
    loading ||
    authContext.loading ||
    productsLoading ||
    downgradeState.loading ||
    loadingEnterprises ||
    (activeEnterprise && loadingLicenses) ||
    configLoading;
  const errorMessage =
    error ||
    productsError?.message ||
    configLoadingError?.message ||
    (activeEnterprise && licenseError?.message) ||
    downgradeState.error;

  let subscriptionCount = 0;
  if (activeEnterprise?.subscriptions) {
    const axeDevToolsPro = activeEnterprise.subscriptions.find(
      s => s.product_slug === ProductSlugs.axeDevToolsExtension
    );
    subscriptionCount = axeDevToolsPro?.license_count || 0;
  }

  return (
    <>
      <PageTitle title={t('Manage Billing')} />
      {showLoader ? (
        <Loader label={t('Loading')} ref={loaderRef} tabIndex={-1} />
      ) : (
        <>
          <BillingComponent
            removePaymentMethodClick={handleRemovePaymentMethodClick}
            confirmRemoveClick={handleConfirmRemoveClick}
            cancelRemoveClick={handleCancelRemoveClick}
            confirming={confirming}
            majorHeading={t('Manage Billing')}
            subscriptionEnd={expiryDateText}
            companyText={companyText}
            paymentMethod={paymentMethod}
            loaderRef={loaderRef}
            performingRequest={performingRequest}
            subscriptionCount={activeEnterprise ? subscriptionCount : 1}
            subscriptionsUsed={(licenses?.used || 0) + (licenses?.pending || 0)}
            onAssignLicenses={onAssignLicenses}
            onAssignEnterpriseName={onAssignEnterpriseName}
            error={errorMessage}
            subscriptions={
              activeEnterprise?.subscriptions
                ? activeEnterprise.subscriptions
                : billingUserV2?.subscriptions || []
            }
            billingUser={billingUser}
            products={products}
            invoices={invoices}
            onCompanyEditSubmit={onCompanyEditSubmit}
            isBillingAdmin={isAdmin}
            onCancelSubscriptionClick={onCancelSubscriptionClick}
            hideExpiredProducts={
              settings?.general.expiredProductVisibility.value === 'none'
            }
            activeEnterprise={activeEnterprise}
            setError={setError}
          />
          <DowngradeDialog
            show={downgradeDialogOpen}
            onClose={onDowngradeDialogClose}
            onConfirm={onDowngrade}
            isPaidPlan={subscription?.purchase_state === 'paid'}
          />
        </>
      )}
    </>
  );
};

export default Billing;
