import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '@deque/cauldron-react';
import {
  type Subscription,
  getProductSubscription
} from '@deque/billing-utils';

import { useAuthContext } from '../../common/contexts/auth';
import { useProducts } from '../../common/contexts/products';
import { useEnterprises } from '../../common/contexts/enterprises';
import useV2EnterpriseMembers from '../../common/hooks/useV2EnterpriseMembers';
import ContentToast from '../../common/components/ContentToast';
import ActiveProducts from '../components/ActiveProducts';
import AvailableProducts from '../components/AvailableProducts';
import { SUPPORTED_TRIAL_PRODUCT_SLUGS } from '../../common/constants';
import { useFeatureFlagState } from '../../common/contexts/featureFlags';
import { useConfiguration } from '../../common/contexts/Configuration';
import { isSubscriptionExpired, ProductSlugs } from '../../common/constants';
import sortProductsOrSubscriptions from '../utils/sort-products-or-subscriptions';
import { AXE_WATCHER_PAID_PLANS_V1 } from '../../axe-watcher/constants';
import { useOnPremValue } from '../../common/contexts/onPrem';
import { useServerInfo } from '../../common/contexts/serverInfo';
import { useIntegrations } from '../../common/contexts/Integrations';

const supportedUserProductSlugs: ProductSlugs[] = [
  ...SUPPORTED_TRIAL_PRODUCT_SLUGS
];
const supportedEnterpriseProductSlugs: ProductSlugs[] = [
  ...SUPPORTED_TRIAL_PRODUCT_SLUGS
];

const Products = () => {
  const { t } = useTranslation();
  const { billingUser, user } = useAuthContext();
  const {
    loading: productsLoading,
    error: productsError,
    products,
    getProductBySlug
  } = useProducts();
  const {
    loading: enterprisesLoading,
    error: enterprisesError,
    activeEnterprise,
    isAdmin
  } = useEnterprises();
  const { serverInfo } = useServerInfo();
  const { acceptedMembers, loading: loadingMembers } = useV2EnterpriseMembers({
    token: user?.token,
    enterpriseId: activeEnterprise?.id
  });
  const {
    settings,
    loading: configLoading,
    loadingError: configLoadingError
  } = useConfiguration();
  const { loading: integrationsLoading, error: integrationsError } =
    useIntegrations();
  const hasAxeWatcherV3SelfServe = useFeatureFlagState(
    'axe_devtools_watcher_v3_self_serve'
  );

  const hasDequeUniversity = useFeatureFlagState('deque_university');
  const hasAxeMonitorLite = useFeatureFlagState('axe_spider_v1');
  const hasAxeWatcherPaidPlans = useFeatureFlagState(AXE_WATCHER_PAID_PLANS_V1);
  const isOnPrem = useOnPremValue();

  if (
    productsError ||
    enterprisesError ||
    configLoadingError ||
    integrationsError
  ) {
    return (
      <ContentToast show={true} type="caution">
        {t('Failed to load products.')}
      </ContentToast>
    );
  }

  const loaderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    loaderRef.current?.focus();
  }, [loaderRef.current]);

  const loading = !!(
    productsLoading ||
    enterprisesLoading ||
    !billingUser ||
    !!(activeEnterprise && loadingMembers) ||
    configLoading ||
    integrationsLoading
  );

  if (loading) {
    return (
      <Loader label={t('Loading products...')} tabIndex={-1} ref={loaderRef} />
    );
  }

  const hideExpiredProducts =
    settings?.general.expiredProductVisibility.value === 'none';
  let enterpriseAdmins: string[] = [];
  if (acceptedMembers?.length) {
    enterpriseAdmins = acceptedMembers
      .filter(member => member.is_admin)
      .map(member => member.email);
  }

  const activeSubscriptions = (
    (activeEnterprise
      ? isAdmin
        ? activeEnterprise.subscriptions
        : billingUser.enterprises[0].subscriptions
      : billingUser.subscriptions) as Subscription[]
  ).filter(
    ({ purchase_state }: { purchase_state: string }) =>
      purchase_state !== 'none'
  );

  const supportedProductsSlugs: ProductSlugs[] = activeEnterprise
    ? [...supportedEnterpriseProductSlugs]
    : [...supportedUserProductSlugs];

  const unfilteredSubscriptions = activeEnterprise
    ? activeEnterprise.subscriptions
    : billingUser.subscriptions;

  if (hasAxeWatcherV3SelfServe && serverInfo?.isJazzbandEnabled) {
    supportedProductsSlugs.push(ProductSlugs.axeDevToolsWatcher);
  }

  if (hasDequeUniversity) {
    supportedProductsSlugs.push(ProductSlugs.dequeUniversity);
  }

  if (!hasAxeMonitorLite) {
    const productIndex = supportedProductsSlugs.indexOf(ProductSlugs.axeSpider);

    if (productIndex > -1) {
      supportedProductsSlugs.splice(productIndex, 1);
    }
  }

  const availableProducts = products.filter(product => {
    // Self-provisioned mobile enterprise trials are not currently supported
    if (product.slug === ProductSlugs.axeDevToolsMobile && activeEnterprise) {
      return false;
    }
    // Don't show Deque University as available until subscriptions get linked up
    if (product.slug === ProductSlugs.dequeUniversity) {
      return false;
    }
    const activeSub = getProductSubscription(
      unfilteredSubscriptions,
      product.slug as ProductSlugs
    );
    return (
      supportedProductsSlugs.includes(product.slug as ProductSlugs) &&
      (!activeSub || activeSub.purchase_state === 'none')
    );
  });

  const visibleActiveSubscriptions = hideExpiredProducts
    ? activeSubscriptions.filter(sub => {
        // The free plan for axe devhub is not considered expired when the feature flag is enabled
        if (
          sub.product_slug === ProductSlugs.axeDevToolsWatcher &&
          !hasAxeWatcherPaidPlans
        ) {
          return !isSubscriptionExpired(sub);
        }

        return (
          !isSubscriptionExpired(sub) ||
          getProductBySlug(sub.product_slug)?.has_free_tier
        );
      })
    : activeSubscriptions;

  const showFooter =
    !hideExpiredProducts &&
    !!activeSubscriptions.find(sub => {
      // The free plan for axe devhub is not considered expired when the feature flag is enabled
      if (
        sub.product_slug === ProductSlugs.axeDevToolsWatcher &&
        !hasAxeWatcherPaidPlans
      ) {
        return isSubscriptionExpired(sub);
      }

      return (
        isSubscriptionExpired(sub) &&
        !getProductBySlug(sub.product_slug)?.has_free_tier
      );
    });

  return (
    <>
      <ActiveProducts
        subscriptions={sortProductsOrSubscriptions(visibleActiveSubscriptions)}
        isAdmin={isAdmin}
        enterpriseAdmins={enterpriseAdmins}
        showFooter={showFooter}
      />
      {!isOnPrem && !!availableProducts.length && (
        <AvailableProducts
          enterpriseAdmins={enterpriseAdmins}
          availableProducts={sortProductsOrSubscriptions(availableProducts)}
          isEnterprise={!!activeEnterprise}
          isAdmin={isAdmin}
        />
      )}
    </>
  );
};

export default Products;
