import React from 'react';
import { Button, Icon, IconButton } from '@deque/cauldron-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  ProductNames,
  SupportedIntegrationProductSlugs
} from '../../../common/constants';
import analyticsInstances, {
  getFallbackAnalyticsInstanceId
} from '../../../common/analyticsInstances';
import { getRelativeTime } from '../../../common/utils/dates';
import { useAuthContext } from '../../../common/contexts/auth';
import TemplateCardComponent from './TemplateCard/TemplateCard';
import { useIntegrations } from '../../../common/contexts/Integrations';
import { useIntegrationProjects } from '../../contexts/IntegrationProjects';
import useIntegrationTemplates from '../../hooks/useIntegrationTemplates';
import { useLoadedConfiguration } from '../../../common/contexts/Configuration';
import getIntegrationProductName from '../../../common/utils/get-integration-product-name-from-slug';
import styles from './Integrations.css';

export interface ListIntegrationTemplatesProp {
  integrationProductSlug: SupportedIntegrationProductSlugs;
}

function ListIntegrationTemplates({
  integrationProductSlug
}: ListIntegrationTemplatesProp) {
  const { t } = useTranslation();
  const history = useHistory();
  const analytics =
    analyticsInstances[getFallbackAnalyticsInstanceId(integrationProductSlug)];

  const { billingUser } = useAuthContext();
  const { settings } = useLoadedConfiguration();
  const { canManageIntegrations } = useIntegrations();
  const { getProjectById, getIssueTypeById } = useIntegrationProjects();
  const { templates } = useIntegrationTemplates({
    integrationProductSlug
  });
  const capitalizedIntegrationName = getIntegrationProductName(
    integrationProductSlug,
    {
      capitalize: true
    }
  );

  const instanceUrl = settings?.jiraIntegration?.instanceBaseUrl.value;
  return (
    <div className={styles.listWrap}>
      <div className={styles.headerGroup}>
        <div className={styles.header}>
          <div>
            <IconButton
              label={t('Back')}
              icon="arrow-left"
              onClick={() => history.push('/configuration/integrations')}
            />
            <h2>
              {capitalizedIntegrationName} {t('Project Templates')}
            </h2>
          </div>
          <p>
            {t(
              'Create custom templates for each site, project, and issue type, ensuring seamless integration between {{axe}} and {{jira}}',
              { axe: ProductNames.axe, jira: ProductNames.jira }
            )}
          </p>
        </div>

        <Button
          onClick={() => {
            analytics.integrationTemplateAddClick({
              integration: integrationProductSlug
            });
            history.push(
              `/configuration/integrations/${integrationProductSlug}/templates/new`
            );
          }}
        >
          <Icon type="plus" />
          {t('Add Template')}
        </Button>
      </div>

      <div className={styles.templateCardContainer}>
        {templates.map((template, i) => (
          <TemplateCardComponent
            key={`${template.project_id}-${template.name}-${i}`}
            templateId={template.id}
            templateName={template.name}
            project={getProjectById(template.project_id)?.name || ''}
            issueType={getIssueTypeById(template.issue_type_id)?.name || ''}
            updatedBy={`${template.updated_by_first_name} ${template.updated_by_last_name}`}
            updatedAt={
              template.updated_at
                ? getRelativeTime(new Date(template.updated_at))
                : null
            }
            instanceUrl={instanceUrl || ''}
            integrationProductSlug={integrationProductSlug}
            canEdit={
              canManageIntegrations ||
              template.created_by === billingUser?.keycloak_id
            }
          />
        ))}
      </div>
    </div>
  );
}

export default ListIntegrationTemplates;
